<template>
    <el-table
            :data="user.addressesRl ? user.addressesRl.filter(r => !r.forDelete) : ''"
            style="width: 100%;font-size: 16px;flex-grow: 1"
            height="100%"
            :border="true"
            v-ls-saver:addressUserTable
    >
        <el-table-column label="Адрес" :resizable="true">
            <template slot-scope="scope">
                <my-el-autocomplete
                        v-if="scope.row.id === 0"
                        :showValueField="getAddressString"
                        v-model="scope.row.address"
                        :selectFunction="onAutocompleteAddressSearch"
                        style="width: 100%"
                        :edit="false"
                        :add="!scope.row.address && editClient"
                        clear
                        @change="changeAddress"
                        @add="onEditAddress(scope.row)"
                >
                    <template slot-scope="{ item }">
                        <div>{{ findClientName(item.clientId) }}</div>
                        <div style="width:900px">{{ getAddressString(item)}}</div>
                    </template>
                </my-el-autocomplete>

                <div v-else :class="!isEditNow ? 'my-link' : ''" @click="!isEditNow && onEditAddress(scope.row)">{{getAddressString(scope.row.address)}}</div>
            </template>
        </el-table-column>

        <el-table-column label="Рабочая группа" :resizable="true" width="300">
            <template slot-scope="scope">
                <my-el-select
                        style="width:100%"
                        v-if="!scope.row.address"
                        v-model="editClient"
                        value-key="name"
                        :value="editClient ? editClient.showName : ''"
                >
                    <el-option
                            v-for="rl in user.clientUserRls"
                            :key="rl.clientId"
                            :label="rl.client.showName"
                            :value="rl.client">
                    </el-option>
                </my-el-select>
                <my-link v-else
                         :disabled="isEditNow"
                         @click="!isEditNow && onEditClient(scope.row)"
                         :name="scope.row.address.client.showName"
                         :comment="scope.row.address.client.importantComment"></my-link>
            </template>
        </el-table-column>

        <el-table-column label="" width="55px" align="center">
            <template slot-scope="scope">
                <template v-if="scope.row.id !== 0">
                    <my-el-button delete key="btn2" :disabled="isEditNow" @click="onClickDeleteRow(scope.row)"/>
                </template>
                <template v-else>
                    <my-el-button cancel key="btn3" @click="onBtnCancelEditRow"/>
                    <my-el-button check key="btn4" :disabled="!scope.row.addressId" @click="onBtnApplyEditRow(scope.row)"/>
                </template>
            </template>
            <template v-slot:header>
                <my-el-button plus :disabled="isEditNow" @click="onBtnAddRow"></my-el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    import _ from 'lodash';
    import { confirm } from '@/components/common/dialogs/dialogUtils';
    import tableSaver from '@/utils/tableSaver';
    import clientEditor from "@/components/usersClientsAffiliation/ClientEditor";
    import addressEditor from '@/components/usersClientsAffiliation/AddressEditor';
    
    
    export default {
        name: "AddressUserTable",
        mixins: [ tableSaver ],
        props: [ 'user' ],
        data() {
            return { editClient: null }
        },
        computed: {
            isEditNow() {
                return this.user.addressesRl && this.user.addressesRl.some(r => r.id === 0);
            }
        },
        
        methods: {
            onBtnAddRow() {
                this.user.addressesRl.push({
                                               id: 0,
                                               forDelete: false,
                                               addressId: null,
                                               address: null,
                                               userId: this.user.id,
                                               user: this.user,
                                               client: null
                                           });
            },
            
            onAutocompleteAddressSearch(search, callback) {
                callback(
                    this.user.clientUserRls
                    .reduce((acc, clientRl) =>
                                acc.concat(clientRl.client.addresses.map(addr => Object.assign(addr, { client: clientRl.client })))
                        , [])
                    .filter(addr => !this.user.addressesRl.some(rl => rl.addressId === addr.id && rl.address.clientId === addr.clientId))
                );
            },
            
            getAddressString(address) {
                return addressEditor.methods.addressToString(address);
            },
            
            findClientName(clientId) {
                let rl = _.find(this.user.clientUserRls, c => c.clientId === clientId);
                return rl ? rl.client.showName : ''
            },
            
            onBtnCancelEditRow() {
                let idx = _.findIndex(this.user.addressesRl, c => c.id === 0);
                this.user.addressesRl.splice(idx, 1);
            },
            
            changeAddress(address) {
                let row = _.find(this.user.addressesRl, c => c.id === 0);
                row.addressId = address ? address.id : null;
                if (!address) {
                    this.editClient = null;
                }
            },
            
            async onEditAddress(row) {
                let address = await this.$showWindowAsync(addressEditor, { id: row.address ? row.address.id : 0, clientId: this.editClient ? this.editClient.id : null });
                if (address) {
                    let client = _.find(this.user.clientUserRls, rl => rl.clientId === address.clientId).client;
                    row.address = address;
                    row.address.client = client;
                    row.addressId = address.id;
                    
                    let fndIdx = _.findIndex(client.addresses, a => a.id === address.id);
                    if (fndIdx >= 0) {
                        client.addresses.splice(fndIdx, 1, address);
                    } else {
                        client.addresses.push(address);
                    }
                }
            },
            
            onBtnApplyEditRow(row) {
                let min = _.minBy(this.user.addressesRl, rl => rl.id);
                row.id = min.id >= 0 ? -1 : (min.id--);
            },
            
            async onClickDeleteRow(row) {
                if (!await confirm(`Удалить адрес из списка?`)) {
                    return;
                }
                
                if (row.id > 0) {
                    row.forDelete = true;
                } else {
                    let idx = _.findIndex(this.user.addressesRl, rl => rl.id === row.id);
                    this.user.addressesRl.splice(idx, 1);
                }
            },
            
            
            async onEditClient(row) {
                let client = await this.$showWindowAsync(clientEditor, { id: row.address.client.id });
                if (client) {
                    let clientRl = _.find(this.user.clientUserRls, rl => rl.clientId === client.id);
                    clientRl.client = client;
                    this.user.addressesRl.filter(rl => rl.address.client.id === client.id).forEach(rl => {
                        rl.address.client = client;
                    });
                }
            },
        },
    }
</script>

<style scoped>

</style>