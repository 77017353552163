<template>
  <div style="display:flex;height: 100%;width: 100%">
    <div style="display: flex;flex-direction: column;width:100%;height: 100%">
      <div style="display: flex;justify-content: flex-end;padding-bottom: 5px;align-items: center;flex-wrap: wrap">
        <div v-if="showAddButtons && metaorder" style="display: flex;align-items: center">
          Быстрое добавление счета
          <el-input v-model="newInvoiceNumber"
                    style="width:150px;margin-left: 5px"
                    placeholder="Номер нового счета"></el-input>
          <my-el-button plus :disabled="!newInvoiceNumber || !!newInvoiceErrors" @click="createNewInvoice"></my-el-button>
          <div style="color:red">{{ newInvoiceErrors }}</div>
        </div>
        <div></div>
        <slot></slot>
        <div style="display: flex;flex-grow: 2;align-items: center;justify-content: flex-end; padding-bottom: 3px;">

        </div>
        <my-el-button v-if="!withSelect" type="primary" @click="loadItemsXls">Экспорт</my-el-button>
        <el-pagination v-if="showFrom !== 'order'"
                       align="right"
                       @size-change="onPagerSizeChange"
                       @current-change="onCurrentPageChange"
                       :current-page.sync="filter.currentPage"
                       :page-sizes="[25, 50, 100, 200, 300, 400]"
                       :page-size="filter.pageSize"
                       layout="sizes, prev, pager, next"
                       :total="count">
        </el-pagination>
      </div>
      <el-table :data="invoices"
                style="font-size: 16px"
                height="100%"
                :border="true"
                ref="table"
                v-ls-saver:invoiceTbl
      >

        <el-table-column v-if="withSelect" width="35px">
          <template v-slot:header>
            <i v-if="selectedInvoiceId" class="el-icon-close" @click="selectedInvoiceId=null; $emit('select-invoice', null)"></i>
          </template>
          <el-radio
              slot-scope="scope"
              v-model="selectedInvoiceId"
              :label="scope.row.id"
              @change="$emit('select-invoice', scope.row)"
          >&nbsp;
          </el-radio>
        </el-table-column>
        <el-table-column prop="id" width="190">
          <div slot-scope="scope" :ref="`anchorColumn_${scope.row.id}`"
               style="display: flex; flex-wrap: nowrap;align-items: center;justify-content: space-between">
            <my-type-link :object="scope.row"
                          @click="showInvoiceWindow(scope.row.id, scope.row.hasChildren)"
            ></my-type-link>
            <div>
              <one-c-label
                  v-if="scope.row.oneCId"
                  :nomotech="scope.row.oneCBase === 'nomotech'"
                  :evrogen="scope.row.oneCBase === 'evrogen'"
              ></one-c-label>
              <el-tag v-if="scope.row.hasChildren" size="mini" style="margin-left: 10px">Сток</el-tag>
              <el-popover v-if="scope.row.isChild" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                  Часть сток счета
                </div>
                <el-tag slot="reference" size="mini" style="margin-left: 10px;font-weight: bold">↲</el-tag>
              </el-popover>
            </div>
          </div>
          <template v-slot:header>
            <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">Номер счета
              <my-el-button v-if="showAddButtons"
                         @click="showInvoiceWindow(0)"
                         plus />
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="showFrom !== 'client'" label="Рабочая группа">
          <div slot-scope="scope" style="display: flex;justify-content: space-between" :ref="`client_${scope.row.id}`">
            <div>
              <my-type-link :object="scope.row.client"
                            :max-icons-count="2"
                            @click="showClientWindow(scope.row)">
              <my-client-debt-warning :client="scope.row.client"></my-client-debt-warning>
              </my-type-link>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: center; align-items: flex-end">
              <i v-if="scope.row.client && scope.row.client.isCheck"
                 :class="scope.row.client.defaultPayType === 'fromAccount' ? 'el-icon-circle-check' : 'el-icon-check'"
                 style="font-weight: bold;color:green; margin-right: 10px;margin-left: 5px"></i>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="Плательщик">
          <div slot-scope="scope" :ref="`affiliation_${scope.row.id}`" style="display: flex;justify-content: space-between; flex-direction: column;">
            <div style="display: flex; justify-content: space-between">
              <my-type-link :object="scope.row.to" @click="showToWindow(scope.row)" ></my-type-link>
              <div style="margin-left: 15px" v-if="scope.row.to && (scope.row.to.oneCId || scope.row.to.oneCIdNomotech)">
                <one-c-label
                    :evrogen="!!scope.row.to.oneCId"
                    :nomotech="!!scope.row.to.oneCIdNomotech"
                />
              </div>
            </div>
            <div style="margin-left: 12px;margin-top: 3px"><contracts-view :affiliation="scope.row.to"></contracts-view></div>
          </div>
        </el-table-column>
        <el-table-column label="Заказ" v-if="showFrom !== 'order'" width="150">
          <div slot-scope="scope" :ref="`metaorder_${scope.row.id}`">
            <my-metaorder-number
                v-for="rl in scope.row.metaorderRls.filter(rl => !rl.forDelete)"
                :key="rl.id"
                :metaorder="rl.metaorder"
            />
          </div>
        </el-table-column>
        <el-table-column label="Создан" width="100">
          <template slot-scope="scope">
            <my-date :date="scope.row.createdAt"/>
          </template>
        </el-table-column>
        <el-table-column label="Платеж" width="100">
          <template slot-scope="scope">
            <my-date :date="getPayMaxDate(scope.row)"/>
          </template>
        </el-table-column>

        <el-table-column label="Сумма" width="80" prop="sum">
          <template slot-scope="scope">
            <span>{{ $roundFmt(scope.row.sum) }} {{ $getEnumValue('CurrencyEnum', scope.row.currency) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Оплачено" width="80">
          <div slot-scope="scope" style="display: flex; flex-wrap: nowrap; align-items: center">
            <div style="white-space: nowrap">{{ $roundFmt(scope.row.stockPayDate ? scope.row.sum : scope.row.payments.reduce((a, p) => a + p.sum, 0)) }}</div>
            <my-bank-commission v-if="!scope.row.stockPayDate" :commission="scope.row.payments.reduce((a,p) => a + p.bankCommission, 0)"/>
          </div>
        </el-table-column>

        <el-table-column width="110" v-if="!withSelect">
          <template slot-scope="scope">
            <my-el-button delete v-if="canShowDeleteButton(scope.row)" @click="deleteInvoice(scope.row)"></my-el-button>
            <my-el-button check v-if="canShowPayButton(scope.row)" @click="doPayInvoice(scope.row)"></my-el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <change-monitor name="delivery" :items="monitoringItems" @change="onChangesElements" ref="changeMonitor"></change-monitor>

  </div>
</template>

<script>
import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
import invoiceEditor from '@/components/invoices/InvoiceEditor';
import stockInvoiceEditor from '@/components/invoices/StockInvoiceEditor';

import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import affiliationEditor from '@/components/usersClientsAffiliation/AffiliationEditor';
import tableSaver from '@/utils/tableSaver';
import baseOrderTab from '@/components/order/BaseOrderTab';
import * as invoiceHelper from '@/utils/invoiceHelper';
import confirmInvoicePay from '@/components/invoices/ConfirmInvoicePay';
import MyTypeLink from "@/components/common/myTypeLink";
import ChangeMonitor from "@/components/common/changeMonitor";
import MyBankCommission from "@/components/common/MyBankCommission";
import MyMetaorderNumber from "@/components/common/myMetaorderNumber";
import OneCLabel from "@/components/common/oneCLabel";
import contractsView from "@/components/usersClientsAffiliation/contractsView";
import MyClientDebtWarning from "@/components/common/myClientDebtWarning";


export default {
  name: "InvoiceTable",
  components: { MyClientDebtWarning, OneCLabel, MyMetaorderNumber, MyBankCommission, ChangeMonitor, MyTypeLink, contractsView },
  props: ['metaorder', 'client', 'to', 'query', 'showFrom', 'isSber', 'canDeleteInvoice', 'delivery', 'orderBy', 'withSelect'],
  mixins: [tableSaver, baseOrderTab],
  data() {
    return {
      selectedInvoiceId: null,
      invoices: [],
      newInvoiceNumber: '',
      count: 0,
      lastInvoiceNumberPart: 0,
      filter: {
        currentPage: 1,
        pageSize: 25,
        fields: "",
        orderBy: 'id',
        orderIsDesc: true,
        query: [],
        queryTitle: 'Счета'
      },
      localStorageFields: ['filter.pageSize']
    }
  },

  async mounted() {
    if (this.query) {
      this.filter.query = this.query;
    }
    if (this.$route?.query?.query) {
      this.filter.queryTitle = JSON.parse(this.$route.query.query).title;
    }
  },

  watch: {
    async query(query) {
      if (this.orderBy) {
        this.filter.orderBy = this.orderBy;
        this.filter.orderIsDesc = false;
      } else {
        this.filter.orderBy = 'id';
        this.filter.orderIsDesc = true;
      }

      this.filter.query = query;
      this.filter.currentPage = 1;

      if (!this.$refs?.table) {
        return;
      }
      await this.loadInvoices();
      if (this.orderBy) {
        this.$refs?.table?.sort({ prop: 'sum', order: 'descending' });
      } else {
        this.$refs?.table?.sort({ prop: 'id', order: 'descending' });
      }
    }
  },

  methods: {
    async loadItemsXls() {
      await this.$myHttp.postWithDownload('/api/Invoices/export', this.filter, 'invoice.xls');
    },

    onChangesElements(data) {
      let monitoringFields = [
        { name: 'client', path: 'client' },
        { name: 'affiliation', path: 'to' }
      ];
      this.invoices = this.$refs.changeMonitor.prepareList(data, this.invoices, 'invoice', monitoringFields);

      let forAnimationIds = [];
      if (data.metaorder) {
        forAnimationIds = this.prepareChangedOrders(data.metaorder);
        let refs = Object.entries(this.$refs)
        .filter(entry => forAnimationIds.includes(entry[ 0 ]))
        .map(entry => entry[ 1 ]?.closest('td')).filter(el => el);
        this.$refs.changeMonitor.animateElements(refs);
      }
    },

    prepareChangedOrders(data) {
      let newOrders = data.update || [];
      if (data.insert?.length) {
        newOrders.push(...data.insert.filter(i => newOrders.every(ni => ni.id !== i.id)));
      }
      let idsForAnimation = [];
      let allRlsForDelete = [];
      if (newOrders.length) {
        newOrders.forEach(newOrder => {
          newOrder.invoiceRls.forEach(rl => {
            let fndInvoice = this.invoices.find(o => o.id === rl.invoiceId);
            if (!fndInvoice) {
              return;
            }
            idsForAnimation.push(fndInvoice.id);
            let fndOrderRl = fndInvoice.metaorderRls.find(rl => rl.metaorderId === newOrder.id);
            if (fndOrderRl) {
              fndOrderRl.metaorder = newOrder;
            } else {
              fndInvoice.metaorderRls.push(
                  {
                    id: rl.id,
                    metaorderId: newOrder.id,
                    metaorder: newOrder
                  }
              )
            }
          })
        })
        let allNewRlId = newOrders.reduce((acc, i) => acc.concat(i.invoiceRls.map(rl => rl.id)), [])
        allRlsForDelete = this.invoices.reduce((acc, o) =>
                                                   acc.concat(o.metaorderRls.filter(rl => newOrders.some(ni => ni.id === rl.metaorderId)
                                                       && !allNewRlId.includes(rl.id))
                                                              .map(rl => ({ invoiceId: o.id, rl }))
                                                   ), []);
      }
      if (data.delete?.length) {
        let rlForDelete = this.invoices.reduce((acc, o) =>
                                                   acc.concat(o.metaorderRls
                                                              .filter(rl => data.delete.some(di => di === rl.metaorderId))
                                                              .map(rl => ({ invoiceId: o.id, rl }))
                                                   ), []);
        allRlsForDelete.push(...rlForDelete);
      }

      allRlsForDelete.forEach(rlWithOrdId => {
        rlWithOrdId.rl.metaorder = null;
        rlWithOrdId.rl.metaorderId = null;
        idsForAnimation.push(rlWithOrdId.invoiceId);
      });
      return idsForAnimation.map(id => `metaorder_${id}`)
    },

    canShowPayButton(invoice) {
      if (invoice.isSber || invoice.isChild) {
        return false;
      }

      return invoice.hasChildren
          ? !invoice.stockPayDate
          : invoice.sum > invoice.payments.reduce((a, p) => a + p.sum + p.bankCommission, 0)
    },

    canShowDeleteButton(invoice) {
      if (invoice.isChild) return false;
      if (invoice.isSber) {
        return this.metaorder && !this.metaorder.sberId;
      }

      return invoice.hasChildren
          ? !invoice.stockPayDate
          : invoice.payments.length === 0
    },


    async createNewInvoice() {
      let saveInvoiceNumber = this.newInvoiceNumber.trim();
      this.$emit('save', async() => {
        let invoice = {
          id: 0,
          createdAt: new Date(),
          number: saveInvoiceNumber,
          metaorderRls: [{ invoiceId: 0, metaorderId: this.metaorder.id }],
          currency: this.metaorder.currency,
          fromId: this.$findOurAffiliationByInvoiceNumber(saveInvoiceNumber)?.id,
          clientId: this.metaorder.client.id,
          toId: this.metaorder.billingAffiliationId,
          sum: this.metaorder.ordersSum + this.metaorder.deliverySum,
          payDate: null,
          payments: [],
          isSber: this.isSber,
          elements: (await invoiceHelper.createInvoiceElements(this.metaorder, 0, this)),
          files: [],
          deliveryId: this.delivery?.id
        };
        try {
          await this.$store.dispatch('invoices/saveItem', invoice);
        } catch (ex) {
          alert(ex.message);
          return;
        }
        this.$emit('reload-order');
      })
    },

    async onPagerSizeChange(newPageSize) {
      this.filter.pageSize = newPageSize;
      this.filter.currentPage = 1;
      await this.loadInvoices();
    },

    async onCurrentPageChange(newCurrentPage) {
      this.filter.currentPage = newCurrentPage;
      await this.loadInvoices();
    },

    async loadInvoices() {
      try {
        let response = await this.$store.dispatch('invoices/loadItems', this.filter);
        this.invoices = response.items;
        this.count = response.count;
        this.$emit('load', response.count);
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        }
      });

      this.newInvoiceNumber = this.nextInvoiceNumber;
    },

    waitSendSaveEvent() {
      if (this.showFrom === 'order' || this.showFrom === 'client') {
        return new Promise(resolve => {
          this.$emit("save", () => resolve());
        });
      } else {
        return new Promise(resolve => {
          resolve();
        });
      }
    },

    async showInvoiceWindow(id, isStockInvoice) {
      await this.waitSendSaveEvent();

      let data =
          {
            id,
            number: this.nextInvoiceNumber,
            showFrom: this.showFrom,
            client: this.client,
            metaorder: this.metaorder,
            sum: this.metaorder ? (this.metaorder.ordersSum + this.metaorder.deliverySum - this.metaorder.paySum) : 0,
            to: this.to,
            isSber: this.isSber,
            delivery: this.delivery
          };

      this.$emit('start-edit', id);
      let result = await this.$showWindowAsync(isStockInvoice ? stockInvoiceEditor : invoiceEditor, data);
      this.$emit('end-edit');
      this.$emit('reload-order');
      if (!result) {
        return;
      }
      this.newInvoiceNumber = this.nextInvoiceNumber;
      if (result.isPay) {
        this.$emit('pay-invoice');
      }
    },

    async showClientWindow(row) {
      await this.$showWindowAsync(clientEditor, { id: row.clientId });
    },

    async showToWindow(row) {
      await this.$showWindowAsync(affiliationEditor, { id: row.toId });
    },

    async doPayInvoice(invoice) {
      let doPayRequest = async(unwatch) => {
        try {
          let action = invoice.hasChildren ? 'invoices/doPayStock' : 'invoices/doPay';

          let payment = await this.$store.dispatch(action, { invoice, ...dateAndSum });
          if (invoice.hasChildren) {
            await this.loadInvoices();
          } else {
            invoice.payments.push(payment);
          }
          this.$emit('pay-invoice');
        } catch (ex) {
          await alert("Во время записи оплаты счета возникла ошибка: " + ex.message);
        } finally {
          unwatch && unwatch();
        }
      };

      let dateAndSum = await this.$showWindowAsync(confirmInvoicePay, {
        id: 0,
        invoice
      });
      if (!dateAndSum) {
        return;
      }
      /* waitSendSaveEvent вызывает загрузку клиента (нужно так как при записи rowVersion поменялся).
         Перезагрузка клиента тянет перезагрузку this.invoices
         менять что нибудь в this.invoices можно только после того как они перезагрузятся.
         После перезагрузки параметр invoice показывает хрен знат куда. Поэтому его надо по новой найти.
         TODO: и ваще всю эту фигню переписать надо.
       */

      if (this.showFrom === 'invoices') {
        await doPayRequest(null);
      } else {
        let unwatch = this.$watch('invoices', async() => {
          await (doPayRequest(unwatch));
        });
        await this.waitSendSaveEvent();
      }
    },

    async deleteInvoice(invoice) {
      if (!await confirm(invoice.hasChildren ? 'Это сток счет. Вместе с ним будут удалены все подчиненные счета. Удалить счет?' : 'Удалить счет?', 'Удалить')) {
        return;
      }
      try {
        await this.$store.dispatch('invoices/deleteItem', invoice)
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      await this.loadInvoices();
      this.$emit('reload-order');
      this.newInvoiceNumber = this.nextInvoiceNumber;
    },
    getPayMaxDate(invoice) {
      if (invoice.hasChildren) {
        return invoice.stockPayDate;
      }

      return invoice.payments.length > 0
          ? new Date(Math.max.apply(null, invoice.payments.map(p => new Date(p.payDate))))
          : null;
    }
  },
  computed: {
    monitoringItems() {
      return {
        invoice: {
          actions: ['insert', 'update', 'delete'],
          ids: this.invoices.map(o => o.id),
          query: this.filter.query,
          insertExist: true
        },

        metaorder: {
          actions: ['insert', 'update', 'delete'],
          ids: [...(new Set(this.invoices.reduce((acc, o) => acc.concat(o.metaorderRls.map(i => i.metaorderId)), [])))],
          insertExist: true,
          noFilterDeleted: true
        },

        client: {
          actions: ['update'],
          ids: [...(new Set(this.invoices.map(o => o.clientId)))],
        },
        affiliation: {
          actions: ['update'],
          ids: [...(new Set(this.invoices.map(o => o.toId)))],
        },
      }
    },

    showAddButtons() {
      if (this.$route?.query?.query && !this.metaorder && !this.client) {
        return false;
      }

      if (this.metaorder?.isStock) {
        return false;
      }
      if (this.isSber && this.invoices.length > 0) {
        return false;
      }
      return true;
    },

    nextInvoiceNumber() {
      if (!this.metaorder) {
        return '';
      }
      let lastNumber = this.invoices.reduce((acc, inv) => {
        let ptr = inv.number.lastIndexOf('-');
        if (ptr > 0) {
          let lastPart = inv.number.substring(ptr + 1).trim();
          if (lastPart.length > 0 && Number.isInteger(lastPart * 1)) {
            return lastPart * 1 > acc ? lastPart * 1 : acc;
          }
        }
        return acc;
      }, 0);
      return this.metaorder.number ? `${this.metaorder.number}-${lastNumber + 1}` : '';
    },


    newInvoiceErrors() {
      if (!this.metaorder) {
        return '';
      }


      if (!this.metaorder.client) {
        return 'Для заказа не выбрана рабочая группа.';
      }
      if (!this.metaorder.billingAffiliation) {
        return 'Для заказа не выбран плательщик.';
      }
      return '';
    }
  }
}
</script>

<style scoped>

</style>
