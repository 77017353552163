import vue from 'vue';

export default function buildPriceStore(store){
    store.state.sequencePrices = {};
    store.state.primerPrices = {};
    store.state.modifierPrices = {};
    store.mutations.setSequencePrices = (state, prices) => state.sequencePrices = prices;
    store.mutations.setPrimerPrices = (state, prices) => state.primerPrices = prices;
    store.mutations.setModifierPrices = (state, prices) => state.modifierPrices = prices;


    store.actions.calcPrimerPrice = async (context, primerAndUserId) => await vue.prototype.$myHttp.post('/api/Prices/CalcPrimerPrice', primerAndUserId);
    
    store.actions.getSequencePrice = async (context) =>  context.commit('setSequencePrices', await vue.prototype.$myHttp.post('/api/Prices/GetSequencePrice'));
    store.actions.saveSequencePrice = async (context, price) => {
        let prices = await vue.prototype.$myHttp.post('/api/Prices/SaveSequencePrice', price);
        context.commit('setSequencePrices', prices);
        return prices;
    };
    
    store.actions.getPrimerPrices = async (context) => {
        let prices = await vue.prototype.$myHttp.post('/api/Prices/GetPrimerCharsPrices');
        context.commit("setPrimerPrices", prices);
        return prices;
    }
    store.actions.savePrimerPrice = async (context, price) => {
        let prices = await vue.prototype.$myHttp.post('/api/Prices/SavePrimerCharsPrices', price);
        context.commit('setPrimerPrices', prices);
        return prices;
    };

    store.actions.getModifiersPrices = async (context) => {
        let prices = await vue.prototype.$myHttp.post('/api/Prices/GetModifierPrices');
        context.commit("setModifierPrices", prices);
        return prices;
    }
    store.actions.saveModifierPrice = async (context, price) => {
        let prices = await vue.prototype.$myHttp.post('/api/Prices/SaveModifierPrices', price);
        return prices;
    };





    store.actions.saveCats = async (context, data) =>  await vue.prototype.$myHttp.post('/api/Prices/SaveCats', data);
    return store;
}