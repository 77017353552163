<template>
  <component-window
      title="Выбор заказов"
      @close="onBtnCancelClick"
  >
    <div style="display:flex;flex-direction:column;height: 100%">
      <el-input clearable style="margin-bottom: 5px" v-model="searchString" placeholder="Поиск по номеру или ФИО"></el-input>
      <el-table :data="viewOrders"
                style="font-size: 16px"
                height="100%"
                :border="true"
                :default-sort="{prop: 'id', order: 'descending'}"
                @selection-change="onSelectionChange"
                :row-key="getRowKeys"
                v-ls-saver:seqOrderSelector
      >
        <el-table-column reserve-selection  type="selection" width="35"></el-table-column>
        <el-table-column width="190" label="Заказ" prop="id" sortable>
          <order-number slot-scope="scope"
                        style="flex-shrink: 0"
                        :order="scope.row"
                        :metaorder="scope.row.metaorder"
          />
        </el-table-column>
        <el-table-column prop="metaorder.user.fio" label="Заказчик" sortable>
          <my-type-link
              slot-scope="scope"
              :object="scope.row.metaorder.user"
              @click="showUserWindow"
          ></my-type-link>
        </el-table-column>

      </el-table>

    </div>

    <template slot="footer">
      <div style="display: flex;justify-content: flex-end;width:100%">
        <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        <my-el-button :disabled="selectedOrders.length === 0" @click="onBtnSelectClick()" type="success">Выбрать</my-el-button>
      </div>
    </template>
  </component-window>
</template>

<script>
import { alert } from '@/components/common/dialogs/dialogUtils';
import tableSaver from '@/utils/tableSaver';
import OrderNumber from "@/components/common/orderNumber";
import MyTypeLink from "@/components/common/myTypeLink";
import userEditor from "@/components/usersClientsAffiliation/UserEditor";

export default {
  name: 'OrderSelector',
  components: { MyTypeLink, OrderNumber },
  props: ['ignoreOrderIds','onClose', 'forSequence', 'query'],
  mixins: [tableSaver],
  data() {
    return {
      searchString: '',
      allOrders: [],
      selectedOrders: [],
      windowSize: { minHeight: 300, minWidth: 400, height: '300px', width: '400px' },
      getRowKeys(row) {
        return row.id;
      },
    };
  },

  async mounted() {
    await this.loadOrders();
  },

  methods: {
    onSelectionChange(val) {
      this.selectedOrders = val;
    },
    async showUserWindow(id) {
      await this.$showWindowAsync(userEditor, { id });
    },
    async loadOrders() {
      let result;
      try {
        if (this.forSequence) {
          result = await this.$store.dispatch('orders/findSeqOrders', JSON.stringify(''));
        } else {
          let filter = {
            query: this.query
          }
          result = await this.$store.dispatch('orders/loadItems', filter)
        }
        this.allOrders = result.filter(o => this.ignoreOrderIds.every(id => id !== o.id ));
      } catch (ex) {
        await alert(`Не удалось получить список заказов: ${ex.message}`)
      }
    },

    async onBtnSelectClick() {
      this.onClose(this.selectedOrders);
      this.$emit('close');
    },

    onBtnCancelClick() {
      this.onClose([]);
      this.$emit('close');
    },

  },

  computed: {
    viewOrders() {
      let search = this.searchString.trim().toLowerCase(); 
      if (search) {
        return this.allOrders.filter(o => 
          o.number.toLowerCase().includes(search)
          || o.metaorder.user?.fio?.toLowerCase().includes(search)   
          || this.selectedOrders.some(selOrd => selOrd.id === o.id )                           
        )
      }
      return this.allOrders;
    }
  }
}
</script>

<style>
.el-tree-node__content {
  margin-top: 2px;
  margin-bottom: 2px;
}

</style>