import vue from 'vue';
import _ from 'lodash';

export default function buildAffiliationMerge(store){
    store.mutations.removeItemsFromStore = (state,idArray) => {
        idArray.forEach( id => {
            const idx = _.findIndex(state.items, r => r.id === id);
            idx >= 0 && state.items.splice(idx, 1);
        } );
    };
    store.actions.doMerge = async (context, payload) => await vue.prototype.$myHttp.post('/api/Affiliations/DoMergeAffiliations', payload);
    store.actions.findDoubles = async (context, payload) => await vue.prototype.$myHttp.post('/api/Affiliations/findDoubles', payload);
    return store;
}