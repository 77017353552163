<template functional>
    <p style="display: inline;  flex-wrap: wrap;  min-height: 25px; line-height: 22px;" 
         v-html="$options.html(props, _vm)"
         @copy="$options.onCopy"
    >
  </p>
</template>

<script>

export default {
  name: "PrimerViewer",
  props: { 
    primer: null
  },
  html(props, context) {
    return context.parent.$primerHelper.buildHtml(context.parent.$primerHelper.buildEditView(props.primer) );
  },
  onCopy() {
    let text = '';
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      text = document.selection.createRange().text;
    }
    navigator.clipboard.writeText(text.replace(/\s/g, ''));
    return false;
  }
  
}

</script>

<style>

p>div.element-div {
  display: inline;
}

.element-div {
  margin: 1px 0 1px 0;
  border-right: 1px solid transparent;
}

.element-div-error {
  color: red;
}

.element-div-current {
  border-right: solid 1px rgba(0, 255, 0, .75);
  animation: animated-cursor 1000ms steps(29, end) infinite;
}

.element-div-error.element-div-modifier div {
  background-color: #ffcfcf !important;
  color: red;
  border-color: red;
}

.element-div div {
  display: inline;
  font-family: Arial, fantasy;
  font-size: 12px;
  margin: 2px 0 2px 0;
  text-align: center;
}

.element-div-modifier div {
  padding-left: 2px;
  padding-right: 2px;
  user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  -webkit-user-select: all;
  margin: 1px 0 1px 0;
  background-color: rgb(236, 245, 255);

  border: solid 1px rgb(198, 226, 255);
  border-radius: 3px;
  color: rgb(64, 158, 255);
  font-family: Arial, fantasy;
  font-size: 12px;
  outline-color: rgb(64, 158, 255);
  text-align: center;
  white-space: nowrap;
}

.element-div-firstInTpl {
  padding-left: 7px;
}

.first-element {
  border-left: 1px solid transparent;
}

.first-element-current {
  border-left: solid 1px black;
  animation: animated-cursor-left 1000ms steps(29, end) infinite;
}

.content-div {
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  caret-color: transparent;
  outline: 0;
  border: solid 1px #DCDFE6;
  border-radius: 4px;
  line-height: 22px;
}

.content-div-focused {
  border: solid 1px #51A5FC;
}

.content-div-error {
  border: solid 1px red;
}


.cursor-active {
  border-right: solid 1px rgba(0, 255, 0, .75);
  animation: animated-cursor 1000ms steps(29, end) infinite;
}

@keyframes animated-cursor {
  from {
    border-right-color: black
  }
  to {
    border-right-color: transparent;
  }
}

@keyframes animated-cursor-left {
  from {
    border-left-color: black
  }
  to {
    border-left-color: transparent;
  }
}


</style>