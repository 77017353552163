<template>
  <div style="display:flex;flex-direction:column;height:100%;justify-content: space-between">
    <div style="display:flex;flex-shrink: 0;margin-bottom: 15px ">
      <el-form status-icon label-width="90px" style="width:50%;">
        <el-form-item label="Номер">
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
            <div v-if="editOrder.id > 0" style="display: flex">
              <b>{{ editOrder.number }}</b>
            </div>
            <div v-else>Новый заказ - еще нет номера</div>
            <div style="display: flex;justify-content: flex-end">
              <my-el-button
                  v-if="!['readyToSend', 'send', 'done'].some(s => s === editOrder.state)"
                  type="success"
                  size="mini"
                  :icon="letterIsSend('newOrder') ? 'el-icon-check':''"
                  @click="sendNewOrderMail(metaorder)"
              >
                <i class="el-icon-message"/>
              </my-el-button>
              <div v-if="editOrder.state === 'newOrder'">
                <my-el-button
                    type="success"
                    @click="setState('awaitApprove')"
                    size="mini">Согласовывается
                </my-el-button>
              </div>
              <div v-if="editOrder.state === 'awaitApprove'">
                <my-el-button
                    type="success"
                    @click="setState('inProgress')"
                    size="mini">В производство
                </my-el-button>
              </div>


              <my-el-button
                  v-else-if="editOrder.state === 'waitDelivery'"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('delivered-to-evrogen', {orderId: editOrder.id})"
                  type="success"
                  size="mini">Доставлен в офис
              </my-el-button>
              <my-el-button
                  v-else-if="editOrder.state === 'inProgress' ||editOrder.state === 'inCompile'"
                  :disabled="!lockManager.canEdit"
                  @click="onSetReadyOrDone"
                  type="success"
                  size="mini">
                {{ deliveryToClient ? 'Готов к отправке' : 'Завершен' }}
              </my-el-button>
              <my-el-button delete @click="$emit('delete-order', editOrder.id)"></my-el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Состояние">
          <div style="display: flex;justify-content: space-between">
            <b>{{ $getEnumValue('OrderStateEnum', editOrder.state) }}</b>
            <el-checkbox style="width: 140px"
                         :disabled="!['newOrder', 'waitDelivery', 'awaitApprove'].includes(editOrder.state)"
                         :value="isDeliveryExist('reverse')"
                         @change="onDeliveryToEvrogenChange">
              Доставка в Евроен
            </el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="Дедлайн">
          <div style="display:flex; justify-content: space-between">
            <el-date-picker
                v-model="editOrder.deadline"
                type="date"
                :picker-options="{firstDayOfWeek: 1}"
                placeholder="Select date and time">
            </el-date-picker>
            <el-checkbox style="width: 140px" v-model="deliveryToClient">Доставка заказчику</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="Комментарий" style="margin-bottom: 0">
          <el-input type="textarea"
                    style="padding-bottom: 10px"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    v-model="editOrder.comment"/>
        </el-form-item>
      </el-form>
      <div style="display: flex; flex-direction: column; width: 50%; border-left: 1px solid #DCDFE6;margin-left: 10px;align-items: flex-end">
        <el-form label-position="left" label-width="100px">

          <el-form-item label="Сумма" style="margin-bottom: 4px">
            <el-input-number class="left-number-input"
                             type="number" :min="0"
                             :controls="false"
                             v-model.number="editOrder.price" style="width: 100px;text-align: left"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Split style="height:100%;padding-top: 0;" direction="horizontal">
      <SplitArea :min-size="400" style="display:flex;flex-direction: column; padding:0 5px 0 0; height:100%">
        <el-input type="textarea"
                  style="padding-bottom: 0;height:99%;display: flex"
                  v-model="editOrder.description"/>
      </SplitArea>
      <SplitArea :min-size="400" style="display: flex;flex-direction: column">
        <Split style="height: 100%" direction="vertical">
          <SplitArea :min-size="100" style="display:flex;flex-direction: column;justify-content: stretch; padding:5px 0 5px 5px; height:100%">
            <div style="display: flex; flex:none">
              Файлы от заказчика
            </div>
            <div style="display: flex; flex: 1 1">
              <file-table :files="editOrder.orderFiles" :file-type="['subOrderUserFiles']"/>
            </div>
          </SplitArea>
          <SplitArea :min-size="100" style="display:flex;flex-direction: column; padding:5px 0 0 5px; height:100%">
            <div style="display: flex; flex:none">Файлы</div>
            <file-table :files="editOrder.orderFiles" :file-type="['subOrderManagerFiles']"/>
          </SplitArea>
        </Split>
      </SplitArea>
    </Split>
  </div>
</template>

<script>
import fileTable from '@/components/common/fileTable';
import { findOrCreateDelivery } from '@/utils/orders';
import baseOrderTab from '@/components/order/BaseOrderTab';
import tableSaver from '@/utils/tableSaver';
import moment from "moment-business-days";

export default {
  name: 'serviceTab',
  mixins: [baseOrderTab, tableSaver],
  components: {
    fileTable
  },
  props: ['metaorder', 'editOrder', 'lockManager', 'deliverys'],
  data() {
    return {
      editPrimer: null
    }
  },
  methods: {
    onSetReadyOrDone() {
      let newState;
      if (this.deliveryToClient) {
        let delivery = this.deliverys.find(d => d.type === 'simple' && d.ordersRl.some(rl => !rl.forDelete && rl.orderId === this.editOrder.id));
        delivery.scheduledSendDate = moment().businessAdd(1).format('YYYY-MM-DDT00:00:00');
        newState = 'readyToSend';
      } else {
        newState = 'done';
      }
      this.$emit('order-state-change', { orderId: this.editOrder.id, newState });
    },

    async setState(newState) {
      this.$emit('order-state-change', { orderId: this.editOrder.id, newState });
    },



    async turnDelivery(newValue, type) {
      if (!newValue) {
        let delivery = this.deliverys.find(d => d.type === type && d.ordersRl.some(rl => rl.orderId === this.editOrder.id));
        if (delivery) {
          let rl = delivery.ordersRl.find(rl => !rl.forDelete && rl.orderId === this.editOrder.id);
          if (rl.id) {
            rl.forDelete = true;
          } else {
            let idx = delivery.ordersRl.indexOf(rl);
            delivery.ordersRl.splice(idx, 1);
          }
          if (!delivery.ordersRl.some(rl => !rl.forDelete)) {
            if (delivery.id < 0) {
              let id = this.deliverys.findIndex(d => d.id === delivery.id);
              this.deliverys.splice(id, 1);
            } else {
              delivery.forDelete = true;
            }
          }
        }
        if (type === 'reverse') {
          this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'newOrder' });
        }
      } else {
        let delivery = findOrCreateDelivery(this.metaorder, this.deliverys, type);
        delivery.ordersRl.push({
                                 orderId: this.editOrder.id,
                                 deliveryId: delivery.id,
                                 order: this.editOrder,
                                 delivery,
                                 price: 0,
                                 forDelete: false
                               });
        if (type === 'reverse') {
          this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'waitDelivery' });
        }
      }
    },

    isDeliveryExist(type) {
      return this.deliverys.some(d => d.type === type && d.ordersRl.some(rl => !rl.forDelete && rl.orderId === this.editOrder.id));
    },

    async onDeliveryToEvrogenChange(newValue) {
      await this.turnDelivery(newValue, 'reverse');
    }
  },

  computed: {
    deliveryToClient: {
      get() {
        return this.isDeliveryExist('simple');
      },
      async set(newValue) {
        await this.turnDelivery(newValue, 'simple');
      }
    }
  }
}
</script>

<style scoped>

</style>
