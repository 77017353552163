import { render, staticRenderFns } from "./MyBankCommission.vue?vue&type=template&id=b9bc702e&scoped=true&functional=true&"
import script from "./MyBankCommission.vue?vue&type=script&lang=js&"
export * from "./MyBankCommission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "b9bc702e",
  null
  
)

export default component.exports