<template>
    <div style="display: flex;flex-direction: column;">
        <div v-if="title" style="padding:0 5px 10px 5px">{{title}}</div>
        <div style="padding:10px 5px 0 5px" v-html="message"></div>
        <div style="display: flex;justify-content: space-between; padding: 20px 5px 5px 5px">
            <el-button @click="$emit('close'); resolve(!isReverseButtons)"
                       size="mini"
                       :type="isReverseButtons ? 'danger':'success'" >{{ leftButtonText }}</el-button>
            <el-button @click="$emit('close'); resolve(!!isReverseButtons)"
                       size="mini"
                       :type="isReverseButtons ? 'success':'danger'" >{{rightButtonText}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'confirmDialogTemplate',
        props: ['title', 'message', 'resolve', 'okText', 'isReverseButtons' ],
        computed: {
            leftButtonText() {
                return this.isReverseButtons ? 'Нет' :  (this.okText || 'Ok');
            },
            rightButtonText() {
                return !this.isReverseButtons ? 'Нет' :  (this.okText || 'Ok');
            }
        }
    }
</script>

<style scoped>

</style>