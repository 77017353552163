<template>
  <div style="display:flex;flex-direction:column;height:100%">
    <div style="display:flex;margin-bottom: 20px ">
      <el-form status-icon label-width="90px" style="width:50%;">
        <el-form-item label="Номер">
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
            <div style="display: flex">
              <div v-if="editOrder.id > 0" style="width:150px">
                <b v-html="editOrder.number"></b>
              </div>
              <div v-else>Новый заказ - еще нет номера</div>
            </div>
            <el-form-item label="Номер проекта" label-width="110px" style="margin-bottom: 0">
              <el-input v-model="editOrder.projectNumber"></el-input>
            </el-form-item>

            <div style="display: flex;flex-wrap: nowrap">
              <my-el-button
                  v-if="editOrder.state === 'waitDelivery'"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('delivered-to-evrogen', {orderId: editOrder.id})"
                  type="success"
                  size="mini">Доставлен в офис
              </my-el-button>
              <my-el-button
                  v-if="editOrder.state === 'readyToSend' && !isDeliveryExist()"
                  :disabled="!lockManager.canEdit"
                  @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'send'})"
                  type="success"
                  size="mini">Отправлен
              </my-el-button>

              <my-el-button
                  v-if="editOrder.state === 'inProgress'"
                  :disabled="!!errorsForSetReadyToSendPopover"
                  :dis-popover="errorsForSetReadyToSendPopover"
                  type="success"
                  @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'readyToSend'})"
                  size="mini">Готов к отправке
              </my-el-button>
              <my-el-button
                  v-if="editOrder.state === 'send'"
                  :disabled="!!errorsForSetReadyToSendPopover"
                  :dis-popover="errorsForSetReadyToSendPopover"
                  type="success"
                  @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'waitSendResult'})"
                  size="mini">Результаты получены
              </my-el-button>
              <my-el-button
                  v-if="  ['waitSendResult', 'done'].some(s => s === editOrder.state)"
                  type="success"
                  size="mini"
                  :icon="letterIsSend('newOrder') ? 'el-icon-check':''"
                  @click="onClickSendResults"
              >
                <i class="el-icon-message"/> Результаты
              </my-el-button>
              <my-el-button delete
                            :disabled="!canDeleteOrder"
                            :dis-popover="!canDeleteOrder ? 'Сначала нужно отвязать все лоты.' : ''"
                            @click="$emit('delete-order', editOrder.id)"></my-el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="Состояние" >

          <div style="display: flex; justify-content: space-between">
            <b>{{ $getEnumValue('OrderStateEnum', editOrder.state) }}</b>
            <el-checkbox style="width: 140px"
                         :disabled="!['waitDelivery', 'inProgress','readyToSend'].includes(editOrder.state)"
                         :value="isDeliveryExist()"
                         @change="onDeliveryToEvrogenChange">
              Доставка DHL
            </el-checkbox>
          </div>
        </el-form-item>

        <el-form-item label="Дедлайн">
          <el-date-picker
              v-model="editOrder.deadline"
              type="date"
              default-time="00:00:00"
              :picker-options="{firstDayOfWeek: 1}"
              placeholder="">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Комментарий" style="margin-bottom: 0">
          <el-input type="textarea"
                    style="padding-bottom: 0"
                    :autosize="{ minRows: 1, maxRows: 4}"
                    v-model="editOrder.comment"/>
        </el-form-item>
      </el-form>
      <div v-if="notDeletedElements.length > 0" style="display: flex;  justify-content: flex-end; width: 50%;border-left: 1px solid #DCDFE6;margin-left: 10px;">
        <price-block :edit-order="editOrder" :show-hand-price="false"></price-block>
      </div>
    </div>
    <el-table
        :data="notDeletedElements"
        style="width: 100%;font-size: 16px"
        :border="true"
        height="100%"
        ref="prodTable"
        @selection-change="onRowsSelectionChange"
        v-ls-saver:ngsTbl
    >
      <el-table-column
          v-if="['newOrder','awaitApprove', 'inProgress', 'readyToSend'].includes(editOrder.state)"
          type="selection"
          width="40"
          :selectable="canSelectRow"
      >
      </el-table-column>
      <el-table-column type="index" width="65">
        <template slot-scope="scope">
          {{ scope.row.showNumber }}
        </template>
        <template v-slot:header="scope">
          <my-el-button
              v-if="['waitDelivery', 'awaitApprove', 'inProgress', 'readyToSend'].includes(editOrder.state)"
              :disabled="!!editNgs || selectedRows.length === 0 || selectedRows.length === editOrder.elements.filter(e =>!e.forDelete).length"
              @click="moveProductsToNewOrder"
              :fake="scope"
              popover="В отдельный заказ"
              type="success" icon="el-icon-sort"></my-el-button>
        </template>
      </el-table-column>
      <el-table-column label="КАТ" :width="100" :resizable="true" prop="product.cat">
        <template slot-scope="scope">
          <div v-if="$g('editNgs.id') === scope.row.id">
            <el-autocomplete
                popper-class="ac-product-popper"
                value-key="cat"
                v-model="catAutocompleteValue"
                :fetch-suggestions="onAutocompleteNgsSearch"
                :select-when-unmatched="false"
                style="width: 100%"
                @input="onAutocompleteNgsInput(scope.row)"
                @select="onAutocompleteProductSelect(scope.row, $event)">
              <template slot-scope="{ item }">
                <div>{{ item.cat }} {{ item.name }}</div>
              </template>
            </el-autocomplete>
          </div>
          <div v-else>
            {{ scope.row.ngsCatalog ? scope.row.ngsCatalog.cat : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Название" :resizable="true">
        <template slot-scope="scope">
          {{ scope.row.ngsCatalog ? scope.row.ngsCatalog.name : '' }}
        </template>
      </el-table-column>

      <el-table-column label="Кол-во, шт" :width="123" :resizable="true" prop="countAll">
        <template slot-scope="scope">
          <div v-if="$g('editNgs.id') === scope.row.id && scope.row.ngsCatalog">
            <el-input-number
                v-model="scope.row.count"
                @change="onChangeCount"
                controls-position="right"
                style="width:100px"
                :min="1"></el-input-number>
          </div>
          <div v-else>
            {{ scope.row.count }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Цена" width="120" :resizable="true">
        <template slot-scope="scope" v-if="scope.row.ngsCatalog">
          {{ $roundFmt(scope.row.price) }}
        </template>
      </el-table-column>
      <el-table-column label="Цена руками" :width="130" :resizable="true">
        <template slot-scope="scope">
          <div v-if="$g('editNgs.id') === scope.row.id && scope.row.ngsCatalog">
            <i v-if="scope.row.handPrice === null || scope.row.handPrice === undefined"
               style=" color: blue"
               class="el-icon-edit"
               @click="scope.row.handPrice = 0"></i>
            <div v-else style="display: flex;flex-wrap: nowrap; align-items: center">
              <my-price-input v-model="scope.row.handPrice" style="width: 90px"></my-price-input>
              <i style="margin-left: 5px;font-weight: bold; color: red" class="el-icon-close" @click="scope.row.handPrice = null"></i>
            </div>
          </div>
          <div v-else>
            {{ scope.row.handPrice || scope.row.handPrice === 0 ? $roundFmt(scope.row.handPrice) : '' }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Сумма" width="100" :resizable="true">
        <template slot-scope="scope">
          {{ $roundFmt(calcRowSum(scope.row)) }}
        </template>
      </el-table-column>

      <el-table-column width="110" :resizable="false">
        <template v-if="(scope.row.id || scope.row.id === 0) " slot-scope="scope">
          <template v-if="$g('editNgs.id') === scope.row.id">
            <my-el-button key="btn1" cancel @click="onBtnCancelEditProductClick(scope.row)"/>
            <my-el-button key="btn2" check :disabled="orderRowIsError(scope.row)" @click="onBtnApplyEditNgsClick"/>
          </template>

          <div v-else style="display: flex;flex-wrap: nowrap">

            <my-el-button key="btn3" edit
                          :disabled="!!errorsForEditDeleteNgsPopover()"
                          :dis-popover="errorsForEditDeleteNgsPopover()"
                          @click="setEditNgs(scope.row)"/>
            <my-el-button key="btn4" delete
                          :disabled="!!errorsForEditDeleteNgsPopover()"
                          :dis-popover="errorsForEditDeleteNgsPopover()"
                          @click="onBtnDeleteProductClick(scope.row)"/>
          </div>
        </template>
        <template v-slot:header>
          <my-el-button
              type="success"
              :disabled="!!errorsForAddNgsPopover"
              :dis-popover="errorsForAddNgsPopover"
              @click="onBtnAppendNgsClick">Добавить
          </my-el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from 'lodash';
import baseOrderTab from './BaseOrderTab.js';
import tableSaver from '@/utils/tableSaver';
import PriceBlock from "@/components/order/PriceBlock";
import { confirm } from "@/components/common/dialogs/dialogUtils";
import { createNewSubOrderForSplitPdtOrPrimer, findOrCreateDelivery } from "@/utils/orders";
import mailEditor from "@/components/mailsAndTemplates/MailEditor";

export default {
  name: 'ngsTab',
  components: { PriceBlock },
  props: ['editOrder', 'lockManager', 'ordersLots', 'timestamp', 'metaorder', 'deliverys'],
  mixins: [baseOrderTab, tableSaver],

  data() {
    return {
      selectedRows: [],
      editNgs: null,
      catAutocompleteValue: ''
    }
  },

  watch: {
    timestamp() {
      this.$nextTick(() =>
                         // eslint-disable-next-line vue/valid-next-tick
                         this.$nextTick(() => {
                           this.$refs.prodTable && this.$refs.prodTable.doLayout();
                         }));
    },
  },

  methods: {
    isDeliveryExist() {
      return this.deliverys.some(d => d.type === 'forNgs' && d.ordersRl.some(rl => !rl.forDelete && rl.orderId === this.editOrder.id));
    },

    async onDeliveryToEvrogenChange(newValue) {
      await this.turnDelivery(newValue);
    },

    async turnDelivery(deliveryIsOn) {
      if (!deliveryIsOn) {
        let delivery = this.deliverys.find(d => d.type === 'forNgs' && d.ordersRl.some(rl => rl.orderId === this.editOrder.id));
        if (delivery) {
          let rl = delivery.ordersRl.find(rl => !rl.forDelete && rl.orderId === this.editOrder.id);
          if (rl.id) {
            rl.forDelete = true;
          } else {
            let idx = delivery.ordersRl.indexOf(rl);
            delivery.ordersRl.splice(idx, 1);
          }
          if (!delivery.ordersRl.some(rl => !rl.forDelete)) {
            if (delivery.id < 0) {
              let id = this.deliverys.findIndex(d => d.id === delivery.id);
              this.deliverys.splice(id, 1);
            } else {
              delivery.forDelete = true;
            }
          }
        }
      } else {
        let delivery = findOrCreateDelivery(this.metaorder, this.deliverys, 'forNgs');
        delivery.ordersRl.push({
                                 orderId: this.editOrder.id,
                                 deliveryId: delivery.id,
                                 order: this.editOrder,
                                 delivery,
                                 price: 0,
                                 forDelete: false
                               });
      }
    },





    async onClickSendResults() {
      //let mail = await this.$mailUtils.buildNgsResultMail(this.metaorder, this.editOrder);
      let mail = await this.$mailUtils.buildNewOrderMail(this.metaorder, this.deliverys);
      let accountType = 'ngs-result';
      mail = await this.$showWindowAsync(mailEditor, { mail, mode: 'send', actionBeforeSend: null, accountType });
      if (!mail) {
        return;
      }
      this.lockerIsWatch = false;
      this.$nextTick(() => {
        this.metaorder.mails.push(mail);
        this.lockerIsWatch = true;
      });
      if (this.editOrder.state === 'waitSendResult') {
        this.$emit('order-state-change', { orderId: this.editOrder.id, newState: 'done' });
      }
    },


    calcRowSum(row) {
      const price = (row.handPrice === null || row.handPrice === undefined)
          ? row.price
          : row.handPrice;
      return price * row.count;
    },

    canSelectRow(row) {
      return row.mainCat === row.subCat;
    },

    onRowsSelectionChange(val) {
      this.selectedRows = val;
    },
    async moveProductsToNewOrder() {
      if (!await confirm("Вынести выбранные элементы в отдельный заказ?")) {
        return;
      }
      let withNewDelivery = await confirm("Сформировать новую доставку для создаваемого заказа?", 'Сформировать', null, true);
      let newOrder = createNewSubOrderForSplitPdtOrPrimer(this.metaorder, this.editOrder, this.deliverys, this.$settings.deadlines, withNewDelivery, this.editOrder.type);

      newOrder.elements = this.editOrder.elements.filter(el => this.selectedRows.some(r => r.id === el.id));
      this.editOrder.elements = this.editOrder.elements.filter(el => !newOrder.elements.some(n => n.id === el.id));
      newOrder.elements.forEach(el => el.orderId = 0);
      this.calcOrder(newOrder);
      this.calcOrder(this.editOrder);
    },

    orderRowIsError(row) {
      return !row.ngsCatalog
          || !row.count
    },

    setEditNgs(row) {
      if (row.handPrice === null) {
        row.handPrice = undefined;
      }
      this.editNgs = _.cloneDeep(row);
      this.catAutocompleteValue = row.ngsCatalog.cat;
    },

    onBtnCancelEditProductClick(row) {
      const idx = _.findIndex(this.editOrder.elements, item => item.id == row.id);
      if (row.id === 0) {
        this.editOrder.elements.splice(idx, 1);
      } else {
        this.editOrder.elements.splice(idx, 1, this.editNgs);
      }
      this.editNgs = null;
      this.catAutocompleteValue = '';
      this.calcOrder(this.editOrder);
    },

    onBtnApplyEditNgsClick() {
      if (this.editNgs.id === 0) {
        let minId = _.min(this.editOrder.elements.map(item => item.id));
        minId = (!minId || minId >= 0) ? -1 : (minId - 1);
        const row = _.find(this.editOrder.elements, p => p.id === 0);
        row.id = minId;
      }
      this.editNgs = null;
      this.catAutocompleteValue = '';
      this.calcOrder(this.editOrder);
      this.$refs[ 'prodTable' ].sort('product.cat');
      this.$nextTick(() => this.$emit("reload-product-lots"));
    },

    async onBtnDeleteProductClick(product) {
      if (!await confirm('Удалить продукт?', 'Удалить')) {
        return;
      }
      const idx = this.editOrder.elements.indexOf(product);
      if (this.editOrder.elements[ idx ].id > 0) {
        this.editOrder.elements[ idx ].forDelete = true;
      } else {
        this.editOrder.elements.splice(idx, 1);
      }
      this.calcOrder(this.editOrder);
    },

    onBtnAppendNgsClick: function() {
      let maxPrior = 1;
      if (this.editOrder.elements.length > 0) {
        maxPrior = _.max(this.editOrder.elements.map(el => el.prior)) + 1;
      }
      this.editOrder.elements.push(
          {
            id: 0,
            count: 1,
            product: null,
            orderId: this.editOrder.id,
            price: 0,
            forDelete: false,
            prior: maxPrior,
            handPrice: null
          });
      this.editNgs = { id: 0 };
    },

    async onAutocompleteNgsSearch(search, cb) {
      search = search.trim();
      if (search.length < 1) {
        cb([]);
        return;
      }
      const query = {
        currentPage: 1,
        pageSize: 100,
        orderBy: 'Cat',
        orderIsDesc: false,
        query: [
          { fieldName: 'cat', op: 'like', values: [search] },
          { fieldName: 'name', op: 'like', values: [search] },
        ]
      }
      const result = (await this.$store.dispatch('ngsCatalog/loadItems', query)).items;
      cb(result.filter(r => !this.notDeletedElements.some(p => p.id !== 0 && p.ngsCatalog != null && p.ngsCatalog.id === r.id)));
    },

    onAutocompleteProductSelect(row, ngsCatalog) {
      row.ngsCatalog = ngsCatalog;
      row.price = ngsCatalog.price;
      row.ngsCatalogId = ngsCatalog.id;
      row.count = 1;
    },

    onAutocompleteNgsInput(row) {
      row.ngsCatalog = null;
    },

    onChangeCount() {
      this.calcOrder(this.editOrder);
    },

    calcOrder(order) {
      order.price = order.elements.reduce((acc, row) => acc + this.calcRowSum(row), 0);
    },

    errorsForEditDeleteNgsPopover() {
      if (this.isAdminEditMode) {
        return '';
      }
      if (this.errorsForAddNgsPopover) {
        return this.errorsForAddNgsPopover;
      }
    },
  },

  computed: {
    isAdminEditMode() {
      return this.$store.state.shared.currentUser.roles.some(r => r === 'EndOrderProductEditor');
    },

    canDeleteOrder() {
      return !this.editOrder.elements.some(el => (el.lots?.length || 0) > 0);
    },

    error() {
      if (this.editNgs) {
        return 'Нужно закончить редактирование строки';
      }
      if (!this.editOrder.elements.some(e => !e.forDelete)) {
        return `Заказ должен содержать элементы чтоб сохраненным быть.`;
      }
      return '';

    },

    isDiscountPercentExist() {
      return this.productElementsView.some(e => e.row.discountPercent);
    },

    errorsForAddNgsPopover() {
      if (!this.lockManager.canEdit) {
        return "Заказ редактирует другой пользователь.";
      }
      if (!['waitDelivery', 'inProgress', 'awaitApprove'].includes(this.editOrder.state)) {
        return 'Заказ не в состоянии когда можно добавлять, изменять или удалять элементы заказа.';
      }
      return (this.editNgs ? 'Для добавления, удаления или изменения продукта завершите редактирование элемента заказа.<br/>' : '');
    },

    errorsForSetInCompilePopover() {
      if (!this.lockManager.canEdit) {
        return "Заказ редактирует другой пользователь.";
      }
      if (this.notDeletedElements.length === 0) {
        return "В заказе нет элементов.";
      }
      return (this.editNgs ? 'Для изменения состояния заказа завершите редактирование элементов заказа.<br/>' : '');
    },

    errorsForSetAwaitApprovePopover() {
      if (!this.lockManager.canEdit) {
        return "Заказ редактирует другой пользователь.";
      }
      if (this.notDeletedElements.length === 0) {
        return "В заказе нет элементов.";
      }
      return (this.editNgs ? 'Для изменения состояния заказа завершите редактирование элементов заказа.<br/>' : '');
    },

    errorsForSetReadyToSendPopover() {
      const allOrderDeliveries = this.deliverys.filter(dlv => dlv.type === 'forNgs' && !dlv.forDelete && dlv.ordersRl.some(rl => rl.orderId === this.editOrder.id));
      if (allOrderDeliveries.some(dlv => !dlv.scheduledSendDate)) {
        return "В разделе 'Доставка' должно быть заполнить поле 'Запланирован' для всех доставок в которые входит заказ."
      }
      return this.errorsForSetInCompilePopover;
    },

    notDeletedElements() {
      return this.editOrder.elements.filter(e => !e.forDelete);
    }
  }
}
</script>

<style>
.ac-product-popper {
  width: auto !important;
}

.main-set-row {
  background-color: #ecf5ff !important;
}

.child-set-row {
  background-color: #F5F7FA !important;
}
</style>
