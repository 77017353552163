<template>
  <div style="display:flex;flex-direction: column;">
    <div style="display: flex;flex-wrap: nowrap; position: sticky;top: 0;z-index: 100;background-color: white;border-bottom: 1px solid #f1f1f1;">
      <div style="display: flex;flex-direction: column;flex-shrink: 0;  padding-bottom:3px; position: sticky; top: 0px;z-index: 100; background-color: white">
        <div style="display: flex;margin-bottom: 5px;align-items: center">
          <div style="display: flex;align-items: center">
            C
            <el-date-picker
                style="width: 150px;margin-left: 5px;margin-right: 10px"
                v-model="filter.range[0]"
                type="date"
                :editable="true"
                default-time="00:00:00"
                :picker-options="{firstDayOfWeek: 1}"

            >
            </el-date-picker>
            По
            <el-date-picker
                style="width: 150px;margin-left: 5px;margin-right: 10px"
                v-model="filter.range[1]"
                type="date"
                :editable="true"
                default-time="23:59:59"
                :picker-options="{firstDayOfWeek: 1}"
            >
            </el-date-picker>
          </div>
          <my-el-select
              v-model="filter.clientType"
              collapse-tags
              style="width:200px;margin-left: 20px"
              placeholder="Тип клиента"
              :value="filter.clientType"
              clearable>
            <el-option label="Внешний" value="outer"/>
            <el-option label="Внутренний" value="inner"/>
          </my-el-select>
          <div v-if="!filter.clientType || filter.clientType === 'outer'" style="margin-left: 20px">
            <my-selector
                type="client"
                v-model="filter.client"
                with-find-text
                @enter-find-text="filter.clientText = $event"
            />
          </div>
          <div v-if="!filter.clientType || filter.clientType === 'outer'" style="margin-left: 20px">
            <my-selector
                type="user"
                v-model="filter.user"
                with-find-text
                @enter-find-text="filter.userText = $event"
            />
          </div>
          <div v-if="!filter.clientType || filter.clientType === 'outer'" style="margin-left: 20px">
            <my-selector
                type="affiliation"
                v-model="filter.affiliation"
                with-find-text
                @enter-find-text="filter.affiliationText = $event"
            />
          </div>
          <div v-if="filter.clientType === 'inner'" style="margin-left: 20px">
            <el-select v-model="filter.clients" multiple placeholder="Клиенты" value-key="id" clearable>
              <el-option
                  v-for="client in innerClients"
                  :key="client.id"
                  :label="client.name"
                  :value="client.id">
                <div>{{ client.name }}</div>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex;flex-direction: column">
      <div>
          <year-and-quarter-charts
              :filter="preparedFilter"
              product-type="order"
              :multi-currency="false"
              title="Продукты и сервисы, руб.">
          </year-and-quarter-charts>
          <quarter-groups-chart-sequence
              :outer-filter="preparedFilter"
              country="ru"
              group-by="groups"
              :show-top="10"
              product-type="order"
              add-sum-row
              title="Продукты и сервисы, руб.»"
          >
          </quarter-groups-chart-sequence>
      </div>
    </div>
  </div>
</template>

<script>
import YearAndQuarterCharts from "@/components/reports/YearAndQuarterCharts";
import QuarterGroupsChartSequence from "@/components/reports/QuarterGroupsChartSequence";
//import QuarterGroupsChart from "@/components/reports/QuarterGroupsChart";

export default {
  name: 'ProductReport',
  components: {
    QuarterGroupsChartSequence,
    YearAndQuarterCharts,
  },
  data() {
    return {
      filter: {
        range: [new Date((new Date()).getFullYear() - 3, 0, 1), new Date()],
        clientType: 'outer',
        client: null,
        clientText: '',
        affiliation: null,
        affiliationText: '',
        clients: [],
        user: null,
        userText: '',
        productIds: [],
        tagIds: [0],
        clientFndText: '',
      },
      innerClients: [],
    }
  },
  async mounted() {
    const query = [{ fieldName: 'IsSpecialClient', op: 'eq', values: [true] }];

    [this.innerClients, this.tags] = await Promise.all([
                                                         this.$store.dispatch('clients/loadItems', { query }),
                                                         this.$store.dispatch('metaorderTags/loadItems', {})]);
  },

  methods: {
    changeTags(value) {
      if (value.includes(0)) {
        this.filter.tagIds = [0];
      } else {
        this.filter.tagIds = this.filter.tagIds.filter(t => t !== 0);
      }
    },

    async onAutocompleteProductSearch(search, cb) {
      const queryArr = [
        { fieldName: 'cat', op: 'like', values: [search] },
        { fieldName: 'name', op: 'like', values: [search] },
      ];
      const result = await this.$store.dispatch('products/loadItems', { query: queryArr, pageSize: 150, currentPage: 1 });
      cb(result.items.filter(r => !this.filter.products.some(p => p.id === r.id)));
    },

    onAutocompleteProductSelect(product) {
      this.filter.products.push(product);
      this.catAutocompleteValue = '';
    },

    buttons(currencyFieldName) {

      let curButtons = this.$getEnum('CurrencyEnum').map(en => ({
        text: en.name,
        onclick: () => {
          this[ currencyFieldName ] = en.value;
        }
      }));
      return {
        buttons: {
          contextButton: {
            menuItems: [
              ...curButtons,
              { separator: true },
              {
                text: 'Печать',
                onclick: function() {
                  this.print();
                }
              },
              {
                text: 'Скачать',
                onclick: function() {
                  this.exportChart({ type: 'image/jpeg' });
                }
              }]
          }
        }
      }
    },
  },

  computed: {
    preparedFilter() {
      return {
        range: this.filter.range,
        clientType: this.filter.clientType,
        clientId: this.filter.client?.id,
        clientText: this.filter.clientText,
        affiliationId: this.filter.affiliation?.id,
        affiliationText: this.filter.affiliationText,
        clientIds: this.filter.clients,
        userId: this.filter.user?.id,
        userText: this.filter.userText,
        productIds: this.filter.productIds,
        tagIds: this.filter.tagIds
      }
    }
  }
}
</script>

<style scoped>

</style>
