<template>
  <el-select
      style="min-width: 150px"
      v-on="$listeners"
      v-model="value"
      value-key="name"
      placeholder="Служба доставки"
      clearable filterable>
    <el-option v-if="withPickup"
               label="Самовывоз"
               :value="null">
    </el-option>
    <el-option
        v-for="ds in activeDeliveryServices"
        :key="ds.id"
        :label="ds.name"
        :value="ds">
    </el-option>
    <el-option
        v-if="value && !activeDeliveryServices.some(s => s.id === value.id)"
        :label="deliveryServiceNameById(value.id)"
        :value="value"
    ></el-option>
  </el-select>

</template>

<script>
import _ from 'lodash';
export default {
  name: "DeliveryServiceSelector",
  props: {
    value: {default: null},
    withPickup: {
      type: Boolean,
      default: false}
  },

  methods: {
    deliveryServiceNameById(id) {
      return this.$getEnum('deliveryServices').find(d => d.id === id)?.name || '';
    }
  },

  computed: {
    activeDeliveryServices() {
      return  _.orderBy(this.$getEnum('deliveryServices').filter(d => !d.isDisabled), ['priority'], ['asc']);
    }
  }
}
</script>

<style scoped>

</style>
