<template>
    <div v-if="order[field] || (order.state !== 'done' && order.state !=='send')" style="display:flex;flex-wrap: nowrap;align-items: center">
        <el-popover trigger="hover" :disabled="!order[reasonField]">
            <div>
                <div v-if="field === 'isDeffered' && order.defferedUntil" style="border-bottom: 1px solid #409EFF">Отложен до {{moment(order.defferedUntil).format('YYYY-MM-DD') }}</div>
                <div>{{ order[reasonField] }}</div>
            </div>

            <div slot="reference" style="margin-right: 5px;display: flex;align-items: center">
                <i v-if="order[reasonField]" class="el-icon-info" style="color: #409EFF;margin-right: 5px"></i>
                {{field === 'isDeffered' ? 'Отложен':'Срочный'}}
            </div>
        </el-popover>
        <el-switch
                v-if = "$store.state.shared.loading === 0"
                :value="order[field]"
                @input="$emit('change', {isSet: $event, field})"
        ></el-switch>
        <i v-else class="el-icon-loading" ></i>
    </div>
</template>

<script>
    import moment from 'moment-business-days';
    
    export default {
        name: "DefferedUrgentSwitch",
        props: ['order', 'field'],
        computed: {
            reasonField() { return this.field === 'isDeffered' ? 'defferedReason' : 'urgentReason' },
            moment() {return moment},
        }
    }
</script>

<style scoped>

</style>