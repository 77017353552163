<template>
    <component-window
            title="Добавление платежа"
            ref="compWindow1"
            :maximize="false"
            @close="onBtnCancelClick">
        <div style="display: flex; flex-direction: column">
            <el-form label-width="110px" label-position="right" :rules="rules" :model="result" ref="form">
                <el-form-item label="Номер счета">
                    <div style="font-weight: bold">{{invoice.number}}</div>
                </el-form-item>
                <el-form-item label="Сумма счета">
                    <div style="display: flex">
                        <div style="width: 100px;font-weight: bold">{{$roundFmt(invoice.sum) + ' ' + $getEnumValue('CurrencyEnum', this.invoice.currency)}}</div>
                        <el-form-item label="Оплачено" label-width="130px" style="margin-bottom: 0">
                            <b>{{$roundFmt(invoice.payments.reduce((a, p) => a + p.sum + p.bankCommission, 0)) + ' ' + $getEnumValue('CurrencyEnum', this.invoice.currency)}}</b>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="Сумма платежа" prop="sum">
                    <div style="display: flex;flex-wrap: nowrap; align-items: center">
                        <my-price-input
                                :disabled="invoice.hasChildren"
                                class="left-number-input"
                                style="width:100px"
                                v-model="result.sum">
                        </my-price-input>

                        <el-form-item label="Комиссия банка" label-width="130px" style="margin-bottom: 0">
                            <div style="display: flex; align-items: center">
                                <my-price-input
                                        class="left-number-input"
                                        style="width:100px"
                                        v-model="result.bankCommission">
                                </my-price-input>
                                <el-popover style="display: flex" placement="top-start" trigger="hover">
                                    <div style="width:300px;word-break: break-word">
                                        Банковская комиссия не входит в сумму платежа, т.е <b>сумма платежа</b> + <b>БК</b> = <b>сумме счета</b>
                                        (на примере простой ситуации, когда один счет закрывается одним платежом)
                                    </div>
                                    <i class="el-icon-info" slot="reference" style="cursor: pointer;font-size: 18px;color:#409EFF;margin-left: 5px"></i>
                                </el-popover>
                            </div>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="Дата оплаты" prop="payDate">
                    <el-date-picker
                            style="width:150px"
                            v-model="result.payDate"
                            type="date"
                            :picker-options="{firstDayOfWeek: 1}"
                            placeholder="Выбрать">
                    </el-date-picker>
                    <el-tag style="margin-left: 5px"
                            size="small"
                            type="warning"
                            v-if="exceedsBy>0">Сумма платежа превышает требуюмую на {{$roundFmt(exceedsBy)}} {{$getEnumValue('CurrencyEnum', this.invoice.currency)}}
                    </el-tag>
                </el-form-item>
                <el-form-item label="Комментарий">
                    <el-input type="textarea"
                              :autosize="{ minRows: 0, maxRows: 4}"
                              v-model="result.comment">
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <template slot="footer">
            <div style="display:flex;justify-content: space-between;width: 100%">
                <div>
                    <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
                </div>
                <div>
                    <my-el-button @click="onBtnSet" type="success">Добавить</my-el-button>
                </div>
            </div>
        </template>
    </component-window>
</template>

<script>
    export default {
        name: "ConfirmInvoicePay",
        props: ['onClose', 'invoice'],
        data() {
            return {
                result: {
                    payDate: null,
                    sum: 0,
                    bankCommission: 0,
                    comment: ''
                },
                rules: {
                    sum: [this.$validateRuleNumber?.(true, false, true)],
                    payDate: [this.$validateRuleRequired]
                },
                windowSize: { minHeight: 325, minWidth: 600, height: '325px', width: '600px' }
            }
        },
        watch: {
            'result.bankCommission': function(value) {
                if (this.invoice.hasChildren) {
                    this.result.sum = this.invoice.sum - value;
                }
            }
        },
        
        mounted() {
            this.result.payDate = new Date();
            this.result.sum = this.invoice.sum - this.invoice.payments.reduce((a, p) => a + p.sum + p.bankCommission, 0);
        },
        methods: {
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit('close');
            },
            async onBtnSet() {
                try {
                    await this.$refs.form.validate()
                } catch (ex) {
                    return false;
                }
                this.onClose(this.result);
                this.$emit('close');
            }
        },
        computed: {
            exceedsBy() {
                return this.result.sum + this.result.bankCommission - this.invoice.sum - this.invoice.payments.reduce((a, p) => a + p.sum + p.bankCommission, 0)
            }
        }
    }
</script>

<style scoped>
</style>
