import _ from 'lodash';
import  vue  from 'vue';


function buildUrl(name, action, endS = true){
    let preparedName = name; 
    switch( name[name.length - 1]) {
        case 's': {
            preparedName += 'es';
            break;
        }
        case 'y': {
            preparedName = preparedName.slice(0, -1) + 'ies';
            break;
        }
        default: {
            preparedName += 's';
            break;
        }
    }
    return `/api/${preparedName}/${action}${endS ? preparedName : name}`;
}

export default function buildStore(name)
{
    return {
        namespaced: true,
        state :
            {
                allCount:0,
                items : [],
            },
        
        mutations: {
            setItems(state, { count, items })
            {
                state.allCount = count;
                state.items = items;
            },
            
            setItem(state, item)
            {
                const idx = _.findIndex(state.items, r => r.id === item.id);
                if (idx >= 0) {
                    state.items.splice(idx, 1, item);
                    return;
                }
                state.items.push(item);
            },
            
            deleteItem(state, id)
            {
                const idx = _.findIndex(state.items, r => r.id == id);
                idx >= 0 && state.items.splice(idx, 1);
            }
        },
        
        actions: {
            async loadAndSetItems(context, filter)
            {
                const result =  await context.dispatch('loadItems', filter );
                context.commit('setItems', result.items ? result : {count: 0, items: result});
                return result;
            },
            
            async loadItems(context, filter)
            {
                const withIndicator = !filter 
                    ? true
                    : ( ('withIndicator' in filter)  ? filter.withIndicator : true  ); 
                
                return  await vue.prototype.$myHttp.post(buildUrl(name,'Find'), filter, withIndicator);
            },
    
            async loadItem(context, payload)
            {
                return await vue.prototype.$myHttp.post(buildUrl(name,'Get', false), payload);
            },
    
            async saveItem(context, item)
            {
                return await vue.prototype.$myHttp.post( buildUrl(name,'Save', false), item);
            },
    
            async newItem()
            {
                return await vue.prototype.$myHttp.post( buildUrl(name,'New', false), null);
            },
            
            async deleteItem(context, item)
            {
                let result = await vue.prototype.$myHttp.post(buildUrl(name, 'Delete', false), item.id);
                context.commit('deleteItem', item.id);
                return result;
            },
        },
    }
}