<template>
    <el-autocomplete
            :disabled="disabled"
            v-model="showText"
            value-key="keyField"
            :fetch-suggestions="selectFunction"
            @select="onSelect"
            @input="onInput"
            @blur="onBlur"
            popper-class="popper"
            :placeholder="placeholder"
            :select-when-unmatched="false"
            @keydown.native.enter="$emit('enter',storeTextValue)"
    >
        <i v-if="edit && value"
           class="el-icon-edit el-input__icon"
           style="cursor: pointer"
           slot="suffix"
           title="Изменить"
           @click="$emit('edit')"
           @click.middle="$emit('new-window')" 
        >
        </i>
        <i v-if="add && !disabled"
           class="el-icon-plus el-input__icon"
           style="cursor: pointer"
           slot="suffix"
           title="Добавить"
           @click="$emit('add')">
        </i>
        <i v-if="clear && !disabled"
           class="el-icon-close el-input__icon"
           style="cursor: pointer"
           slot="suffix"
           title="Очистить"
           @click="$emit('input', null); $emit('change', null)">
        </i>

        <template slot-scope="{ item }">
            <slot :item="item"></slot>
        </template>
    </el-autocomplete>
</template>

<script>
    export default {
        name: "myElAutocomplete",
        props: {
            disabled: {default: false},
            value: {type:Object, default:null}, 
            keyField: {default: ''},
            showValueField: null,
            selectFunction:null, 
            add :  {type:Boolean, default: false},
            edit:  {type:Boolean, default: false},
            clear: {type:Boolean, default: false},
            placeholder: { default: ''}
        },
        data() {
            return {
                storeTextValue: ''
            }
        },
        watch: {
            storeTextValue(value) {
                this.$emit('change-find-text', value);
            }
        },
        methods:{
          onInput() {
             this.$emit('input', null);
             //this.$emit('change', null);
          },
            
          onSelect(value) {
              this.$emit('input', value);
              this.$emit('change', value);
          },
          
          onBlur() {
              this.storeTextValue = '';
          }  
        },        
            
        computed: {
            showText: {
                get: function() {
                    if (this.value) {
                        if (! this.showValueField)  {
                            return this.value[ this.keyField ];
                        }
                        if (typeof  this.showValueField === "function") {
                            let val = this.keyField ? this.value[ this.keyField ] : this.value; 
                            return this.showValueField(val);
                        }
                        return this.value[ this.showValueField ];
                    }
                    return this.storeTextValue;
                },
                set: function(value) {
                    this.storeTextValue = value;
                }
            }
        }
    }
</script>

<style scoped>

</style>