import _ from "lodash";
import { store } from '@/store';
import moment from "moment";
import { seqOrderSummary } from "@/utils/orders";

let selectInvoiceFileWindow = {
    name: 'confirmWindow1',
    template: `
      <component-window
          title="Добавление файлов"
          ref="compWindow1"
          :maximize="false"
          @close="onBtnCancelClick">
      <div style="display: flex; flex-direction: column">
        <el-form label-position="left" status-icon :model="result" ref="form">
          <el-form-item label="Счет" prop="invoice">
            <el-select
                style="width: 100%"
                v-model="result.invoice"
                valueKey="number"
                :multiple="false"
                placeholder=""
                @change="onChangeInvoice"
            >
              <el-option
                  v-for="invoice in invoices"
                  :value="invoice"
                  :key="invoice.id"
                  :label="invoice.number"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Файл" prop="file" :rules="[$validateRuleRequired]">
            <el-select
                style="width: 100%"
                v-model="result.file"
                :multiple="false"
                placeholder=""
                valueKey="name"
            >
              <el-option
                  v-for="file in files"
                  :value="file"
                  :key="file.id"
                  :label="file.showName"/>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <div style="display:flex;justify-content: space-between;width: 100%">
          <div>
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          </div>
          <div>
            <my-el-button @click="onBtnSelect" type="success">Выбрать</my-el-button>
          </div>
        </div>
      </template>
      </component-window>`,
    props: ['onClose', 'invoices'],
    data() {
        return {
            result: { invoice: null, file: null },
            windowSize: { minHeight: 240, minWidth: 400, height: '240', width: '400' }
        }
    },
    mounted() {
        if (this.invoices.length === 1) {
            this.result.invoice = this.invoices[ 0 ];
        }
    },
    methods: {

        async onChangeInvoice(invoice) {
            if (invoice.files) {
                return;
            }
            this.result.file = null;
            let loadInvoice = await this.$store.dispatch('invoices/loadItem', invoice.id);
            invoice.files = loadInvoice.files;
        },

        onBtnCancelClick() {
            this.onClose(null);
            this.$emit('close');
        },
        async onBtnSelect() {
            try {
                await this.$refs.form.validate()
            } catch (ex) {
                return false;
            }
            this.onClose(this.result.file);
            this.$emit('close');
        }
    },
    computed: {
        files() {
            return this.result.invoice?.files?.filter(f => f.type === 'invoice') || [];
        }
    }
};

export default {
    install(Vue) {
        Vue.prototype.$mailUtils = {
            renderTemplate(template, props, isText = false) {
                const vm = new Vue({
                                       comments: true,
                                       template: `
                                         <div>${template}</div>`,
                                       data: () => props
                                   });
                const app = vm.$mount();
                return isText
                    ? app.$el.textContent
                    : app.$el.innerHTML;
            },

// Новый заказ
            async buildNewOrderMail(metaorder, deliveries, payId = '') {
                let mail = this.createMailFromTemplate('newOrder', metaorder);
                    mail = this.prepareNewOrderBodyAndSubject(mail, metaorder, deliveries, payId);
                return mail;
            },

            prepareNewOrderBodyAndSubject(mail, metaorder, deliveries, payId) {
                let data = this.buildOrderData(metaorder, deliveries, payId);
                mail.body = this.renderTemplate(mail.body, data);
                mail.subject = this.renderTemplate(mail.subject, { number: metaorder.number }, true);
                return mail;
            },

            buildOrderData(metaorder, deliveries, payId) {
                let delivery = deliveries.find(d => d.type === 'simple');
                let fullSum, deliverySum;
                if (metaorder.tagRls?.some(rl => rl.tagId === 2 && !rl.forDelete)) {
                    fullSum = 0;
                    deliverySum = ''
                } else {
                    fullSum = Vue.prototype.$roundFmt(metaorder.ordersSum + metaorder.deliverySum);
                    deliverySum = metaorder.deliverySum ? Vue.prototype.$roundFmt(metaorder.deliverySum) : '';
                }
                let fio = metaorder.user?.fName || '';
                if (metaorder.user?.mName) {
                    if (fio) {
                        fio += ' ';
                    }
                    fio += metaorder.user?.mName;
                }
                return {
                    operator: {
                        fio: store.state.shared.currentUser.fio,
                        phones: this.managerPhones(),
                    },
                    metaorder: {
                        number: metaorder.number,
                        date: moment().format('YYYY-MM-DD'),
                        comment: metaorder.userComment,
                        fio,
                        fullSum,
                        deliverySum,
                        delivery: !delivery ? undefined : (delivery.woDelivery ? 'Самовывоз' : Vue.prototype.$addressToString(delivery.address)),
                        payUrl: payId ? `${Vue.prototype.$settings.sber.formUrl}${payId}` : '',
                        orders: metaorder.orders.map(o => this.buildOrderDetails(o)),
                        primerDiscount: metaorder.client?.simplePrimerDiscount,
                        productDiscount: metaorder.client?.productDiscount,
                        sequenceDiscount: metaorder.client?.sequenceDiscount,
                        payType: metaorder.payType,
                        client: metaorder.client?.name,
                        noShowSeqPrices: metaorder.billingAffiliation
                                         ? metaorder.billingAffiliation?.noShowSeqPricesInMails
                                         : false,
                        currency: Vue.prototype.$getEnumValue('CurrencyEnum', metaorder.currency)
                    }
                }
            },

            buildOrderDetails(order) {
                switch ( order.type ) {
                    case 'pdt':
                        return this.buildProductOrderDetail(order);
                    case 'ngs': return this.buildNgsOrderDetail(order);
                    case 'mst':
                    case 'sst':
                        return this.buildPrimerOrderDetail(order);
                    case 'seq':
                        return this.buildSeqOrderDetail(order);
                    default:
                        return { number: order.number };
                }
            },

            buildProductOrderDetail(order) {
                return {
                    number: order.number,
                    type: order.type,
                    typeName: 'Продукты',
                    useHandPrice: order.useHandPrice,
                    discountPercent: order.discountPercent,
                    elements: (order.elements || []).map((item, index) => {
                        let calculatePrice = item.discountPrice * item.countDiscount + item.price * (item.countAll - item.countDiscount);
                        let sum = Vue.prototype.$calcOrderProductSum(item, order.discountPercent);
                        let bulkName = '';
                        if (item.product.buffers && item.product.buffers.length > 0) {
                            bulkName = item.bulk
                                ? item.bulk.siteNameToDisplay
                                : 'Без буфера';
                        }

                        return {
                            number: index + 1,
                            cat: item.product.cat,
                            name: item.product.siteNameToDisplay,
                            bulkName,
                            count: item.countAll,
                            price: Vue.prototype.$roundFmt(item.useHandPrice ? item.handPrice : item.price),
                            discountPrice: item.countDiscount ? Vue.prototype.$roundFmt(item.discountPrice) : '',
                            discount: Vue.prototype.$roundFmt(calculatePrice - sum),
                            sum: Vue.prototype.$roundFmt(sum)
                        }
                    })
                };
            },

            buildNgsOrderDetail(order) {
                return {
                    number: order.number,
                    type: order.type,
                    typeName: 'NGS',
                    useHandPrice: order.useHandPrice,
                    discountPercent: order.discountPercent,
                    elements: (order.elements || []).map((item, index) => {
                        const price = item.handPrice === null || item.handPrice === undefined ? item.price : item.handPrice
                        return {
                            number: index + 1,
                            cat: item.ngsCatalog.cat,
                            name: item.ngsCatalog.name,
                            count: item.count,
                            price,
                            sum: Vue.prototype.$roundFmt(price * item.count)
                        }
                    })
                };
            },

            buildPrimerOrderDetail(order) {
                let sortedElements = _.orderBy(order.elements, ['numberInOrder'], ['asc'])
                return {
                    number: order.number,
                    type: order.type,
                    typeName: order.type === 'mst' ? 'Модифицированные праймеры' : 'Простые праймеры',
                    withClean: order.elements.some(p => p.optionsCleaning),
                    withKeen: order.elements.some(p => p.optionsKeen),
                    elements: sortedElements.map((item, idx) => ({
                        number: idx + 1,
                        name: item.name,
                        primer: Vue.prototype.$primerHelper.buildText(Vue.prototype.$primerHelper.buildEditView(item), true),
                        scale: item.scale,
                        clean: item.optionsCleaning,
                        keen: item.optionsKeen,
                        price: Vue.prototype.$roundFmt(item.price),
                        discountPrice: Vue.prototype.$roundFmt(item.discountPrice)
                    }))
                };
            },

            buildSeqOrderDetail(order) {
                let names = new Map(Vue.prototype.$getEnum('FragmentOptionsEnum').map(o => [o.value, o.name]));
                names.set('sequence', 'Секвенирование');
                names.set('fragmentAnalyze', 'Фрагментный анализ');
                names.set('dnaExtract', 'Выделение геномной ДНК');
                names.set('primerDesign', 'Дизайн праймеров');
                names.set('contigCompile', 'Сборка контига')
                names.set('pcr', 'ПЦР');

                let number = 1;
                return {
                    number: order.number,
                    type: order.type,
                    typeName: 'Секвенирование',
                    discountPercent: order.discountPercent,
                    discountPrice: order.discountPrice,
                    useHandPrice: order.useHandPrice,
                    withOptions: order.elements.some(f => f.option && f.option !== 'none'),
                    summary: seqOrderSummary(order).filter(el => el.count > 0 && el.sum > 0).map(el => Object.assign(el, { name: names.get(el.key) })),
                    elements: order.elements.reduce((acc, fragment) => {
                        acc.push(...fragment.primersRls.filter(rl => rl.state === 'done').map(rl => ({
                            number: number++,
                            fragmentName: fragment.name,
                            actionName: Vue.prototype.$getEnumValue('FragmentActionsEnum', fragment.action),
                            primerName: rl.primer?.name || '',
                            option: fragment.option && fragment.option !== 'none' ? Vue.prototype.$getEnumValue('FragmentOptionsEnum', fragment.option) : '',
                            stateName: Vue.prototype.$getEnumValue('SeqStateEnum', rl.state),
                        })));
                        return acc;
                    }, [])
                }
            },
//----------------------------------------------------
            async buildSeqResultMail(metaorder, deliveries, order) {
                let mail = this.createMailFromTemplate('seqLinq', metaorder);
                let emails = await store.dispatch('orders/getMailsForSeqResults', order.id);
                mail.to = emails.filter(r => !r.forCc).map(r => r.mail).join(', ');
                mail.cc = emails.filter(r => r.forCc).map(r => r.mail).join(', ');

                let data = this.buildOrderData(metaorder, [], null);
                data.metaorder.number = order.number;
                data.metaorder.orders = data.metaorder.orders.filter(o => o.number === order.number);

                if (!metaorder.tagRls?.some(rl => rl.tagId === 2 && !rl.forDelete)) {

                    let deliverySum = deliveries
                        .filter(d => !d.woDelivery)
                        .reduce((acc, dlv) => acc + dlv.ordersRl
                                                       .filter(rl => rl.order.number === order.number )
                                                       .reduce((acc, rl) => acc + rl.price , 0)
                            , 0)
                    let fullSum =( order.useHandPrice
                                    ? order.handPrice
                                    : (order.discountPercent ? order.discountPrice : order.price))
                               + deliverySum;
                    data.metaorder.fullSum = Vue.prototype.$roundFmt(fullSum);
                    data.metaorder.deliverySum = deliverySum ? Vue.prototype.$roundFmt(deliverySum) : '';
                }

                data.seqOrderLinks = this.buildSeqOrderLinks(metaorder, order.number);

                mail.body = this.renderTemplate(mail.body, data);
                mail.subject = this.renderTemplate(mail.subject, data, true);
                return mail;
            },

            buildSeqOrderLinks(metaorder, orderNumber) {
                return metaorder.orders
                .filter(o => orderNumber ? (o.number === orderNumber) : (o.type === 'seq'))
                .map(o => ({ url: `https://${Vue.prototype.$settings.siteDomain}/site/basket/GetSequenceZip?id=${o.seqZipLinqHash}`, name: o.number }));
            },
//----------------------------------------------------
            async buildNgsResultMail(metaorder) {
                    let mail = this.createMailFromTemplate('ngsResult', metaorder);
                    return mail;
            },

//----------------------------------------------------
            buildPasswordMail(email, url, password, subject, language) {
                let mail = this.createMailFromTemplate('sendPassword',
                    {user: {language: language, contacts: []}});
                mail.body = this.renderTemplate(mail.body, { url, login: email, password: password });
                mail.to = email;
                mail.subject = subject;
                return mail;
            },

//----------------------------------------------------

            async buildInvoiceMail(metaorder, orderId, deliveries) {
                let isPrevPay = metaorder.prepaymentPercent * 1 === 100;
                let mail = this.createMailFromTemplate(isPrevPay ? 'invoiceForPrevPay' : 'invoiceForPostPay', metaorder);
                mail.files = isPrevPay ? [] : await this.findInvoiceForMail(metaorder);

                let delivery = deliveries.find(dlv => dlv.ordersRl.some(rl => rl.orderId === orderId));
                let sendDate = '';
                let serviceName = '';
                if (delivery) {
                    let logRec = delivery.stateLog.find(l => l.newState === 'send');
                    sendDate = logRec?.createdAt ? moment(logRec.createdAt).locale('en').format('MMMM DD, YYYY') : '';
                    if (delivery.serviceId) {
                        let service = Vue.prototype.$deliveryServiceById(delivery.serviceId);
                        serviceName = service.name;
                    }
                }

                let data = {
                    name: metaorder.user?.nameForMail || metaorder.user.showName,
                    poNumber: metaorder.clientOrderNumber,
                    trackNumber: delivery?.trackNumber,
                    sendDate,
                    serviceName
                };
                mail.body = this.renderTemplate(mail.body, data);
                mail.subject = this.renderTemplate(mail.subject, data, true);
                return mail;
            },

//----------------------------------------------------

            buildDeliveryMail(delivery) {
                let templateName = delivery.type === 'forSequence'
                    ? 'delivery_sequence'
                    : (delivery.woDelivery ? 'delivery_wo' : 'delivery_send')
                let mail = this.createMailFromTemplate(templateName,  {user: {language: delivery.recipient?.language, contacts: []}});
                mail.deliveryId = delivery.id;
                mail.from = 'Allgene';
                mail.to = this.prepareMailToForDelivery(delivery);

                let metaordersInDelivery = delivery.ordersRl
                .map(rl => rl.order.metaorder.number)
                .filter((item, pos, arr) => arr.indexOf(item) === pos)
                let data = this.buildDeliveryData(delivery, metaordersInDelivery);
                mail.body = this.renderTemplate(mail.body, data);
                mail.subject = this.buildDeliveryMailSubject(delivery, metaordersInDelivery);
                return mail;
            },

            buildDeliveryData(delivery, metaorderNumbers) {
                return {
                    sendDate: delivery.state === 'send'
                        ? moment(_.orderBy(delivery.stateLog, ['id'], ['desc']).find(s => s.newState === 'send').createdAt).format('YYYY-MM-DD')
                        : '',
                    estimatedText: delivery.service?.estimatedDeliveryText
                        ? `Заказ должен быть доставлен ${delivery.service?.estimatedDeliveryText}.`
                        : '',
                    service: delivery.service ? delivery.service.name : '',
                    trackNumber: delivery.trackNumber?.trim(),
                    orderNumber: metaorderNumbers.length === 1 ? metaorderNumbers[ 0 ] : '',
                    metaorders: metaorderNumbers.map(moNumber => ({
                        number: moNumber,
                        orderNumbers: delivery.ordersRl.filter(rl => rl.order.metaorder.number === moNumber).map(rl => rl.order.number)
                    })),
                    invoiceNumbers: delivery.ordersRl
                    .reduce((acc, orl) => [...acc, ...orl.order.metaorder.invoiceRls.map(irl => irl.invoice).filter(i => i && !acc.some(a => a.id === i.id))], [])
                    .map(i => i.number),
                    isEvrogenService: delivery.service?.isEvrogenService
                };


            },

            buildDeliveryMailSubject(delivery, metaordersInDelivery) {
                let char = metaordersInDelivery.length > 1 ? 'ы' : '';
                let endStr = '';
                if (delivery.type === 'forSequence') {
                    endStr = `передан${char} в секвенирование`;
                } else {
                    endStr = delivery.woDelivery ? `готов${char} к выдаче` : `отправлен${char}`;
                }
                return `Заказ${char} ${metaordersInDelivery.join(', ')} ${endStr}`;
            },

            prepareMailToForDelivery(delivery) {
                if (!delivery.recipient) {
                    return '';
                }
                let result = delivery.recipient.login || '';
                return delivery.recipient
                .contacts?.filter(r => r.type === 'mail')
                .reduce((acc, item) => {
                            return result.toLowerCase().indexOf(item.contact.toLowerCase()) < 0
                                ? (acc ? (acc + ', ') : '') + item.contact
                                : acc
                        }
                    , result);
            },

//----------------------------------------------------

            calcAccountTypeForMetaorder(metaorder) {
                let accountType = "com";
                if (metaorder.currency === 'rub') {
                    let orderTypes = [...new Set(metaorder.orders.map(o => o.type === 'mst' ? 'sst' : o.type))];
                    accountType = orderTypes.length === 1 ? orderTypes[ 0 ] : null
                }
                return accountType;
            },

            async findInvoiceForMail(metaorder) {
                if (metaorder.isStock) {
                    return [];
                }
                let filter = {
                    query: [{ fieldName: '@metaorderRls.metaorderId', op: 'eq', values: [metaorder.id] }]
                };
                let invoices = await store.dispatch('invoices/loadItems', filter);
                let file = null;
                switch ( invoices.length ) {
                    case 0:
                        return [];
                    case 1: {
                        let invoice = await store.dispatch('invoices/loadItem', invoices[ 0 ].id);
                        let invoiceFiles = invoice.files.filter(f => f.type === 'invoice');
                        if (invoiceFiles.length > 1) {
                            file = await Vue.prototype.$showWindowAsync(selectInvoiceFileWindow, { invoices: [invoice] });
                        } else if (invoiceFiles.length === 1) {
                            file = invoiceFiles[ 0 ];
                        }
                        break;
                    }
                    default: {
                        file = await Vue.prototype.$showWindowAsync(selectInvoiceFileWindow, { invoices });
                        break;
                    }
                }
                if (file) {
                    file.type = 'outMailFiles';
                    file.needCopy = true;
                    return [file];
                }
                return [];
            },

            createMailFromTemplate(templateName, metaorder) {
                let template = null;
                if (metaorder?.user?.language !== 'ru') {
                   let nameWithLang = `${templateName}_${metaorder.user.language}`
                   template = Vue.prototype.$getEnum('mailTemplates').find(t => t.name === nameWithLang);
                }
                if (!template) {
                    template = Vue.prototype.$getEnum('mailTemplates').find(t => t.name === templateName);
                }

                let mail = _.cloneDeep(template);
                mail.id = 0;
                mail.metaorderId = metaorder?.id;
                mail.to = this.prepareMailTo(metaorder);
                mail.cc = this.prepareMailCc(metaorder);
                mail.fromAccount = null;
                mail.files = [];
                return mail;
            },

            managerPhones() {
                let phones = store.state.shared.currentUser.phone?.trim();
                let mPhone = store.state.shared.currentUser.mobilePhone?.trim();
                phones = phones ? `тел. ${phones}` : '';
                if (mPhone) {
                    if (phones) {
                        phones += '<br/>';
                    }
                    phones += `моб. ${mPhone}`
                }
                return phones;
            },

            prepareMailCc(metaorder) {
                if (!metaorder?.user) {
                    return '';
                }
                return metaorder.user.contacts
                .filter(r => r.type === 'mail' && r.forCopy)
                .reduce((acc, item) => (acc ? (acc + ', ') : '') + item.contact, '');
            },

            prepareMailTo(metaorder) {
                if (!metaorder?.user) {
                    return '';
                }
                let result = metaorder.user.login || '';
                return metaorder.user.contacts
                .filter(r => r.type === 'mail' && !r.forCopy)
                .reduce((acc, item) =>
                            result.toLowerCase().indexOf(item.contact.toLowerCase()) < 0
                                ? (acc ? (acc + ', ') : '') + item.contact
                                : acc
                    , result);
            },
        }
    }
}
