<template>
  <component-window
      :title="mode === 'template' ? 'Редактирование шаблона' : 'Письмо'"
      :lock-manager="null"
      @close="onBtnCancelClick()">
    <div style="display:flex;flex-direction: column; width:100%;height:100% "  @keyup.ctrl.enter.prevent="mode === 'batch' ? onBtnNextClick() : onBtnSendClick()" >
      <div style="flex-grow: 0;">
        <el-row type="flex">
          <el-col :span="12">
            <el-form ref="form" :model="mail" label-width="70px" :rules="rules">
              <el-form-item v-if="mode ==='template'" label="Название" prop="name">
                <el-input v-model="mail.name"></el-input>
              </el-form-item>

              <el-form-item label="От">
                <div style="display: flex;flex-wrap: nowrap">
                  <el-input v-model="mail.from"></el-input>
                  <el-form-item v-if="mode !== 'template'" label="Аккаунт" prop="fromAccount" style="margin-left:15px; margin-bottom: 0; width: 400px">
                    <el-select v-model="mail.fromAccount" placeholder="Select" style="margin-left: 15px;width:180px">
                      <el-option
                          v-for="item in accounts"
                          :key="item.account"
                          :label="item.account"
                          :value="item.account">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item v-if="mode !=='template'" label="Кому" prop="to">
                <el-input v-model="mail.to"></el-input>
              </el-form-item>
              <el-form-item v-if="mode !=='template'" label="Копия">
                <el-input v-model="mail.cc"></el-input>
              </el-form-item>
              <el-form-item label="Тема" prop='subject'>
                <el-input v-model="mail.subject"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12" style="display: flex; flex-direction: column; justify-content: stretch;padding:0 0 18px 5px">
            <file-table
                v-if="mode !== 'template'"
                :files="mail.files"
                :with-delete="mode === 'send'"
                :with-add="mode === 'send'"
                :file-type="['outMailFiles']"
                @add-files="onAddFiles"
            />
            <el-input v-else type="textarea" style="display: flex;height: 100%" v-model="mail.description"></el-input>
          </el-col>
        </el-row>
      </div>
      <div style="display:flex;flex-grow: 1;flex-shrink: 1">
        <trumbowyg-wrapper v-model="mail.body" :account-type="accountType"></trumbowyg-wrapper>
      </div>
    </div>
    <template slot="footer">
      <div v-if="mode === 'batch'" style="display:flex;justify-content: flex-end; width: 100%; align-items: center">
        <div style="display:flex;justify-content: space-between; flex-grow:1;width: 100%;margin-right: 25px">
            <el-checkbox-group v-if="batchData && batchData.length > 1" v-model="batchResult" style="margin-right: 50px">
              <el-checkbox v-for="order in batchData"
                           :key="order.id"
                           :label="order.id"
                           :value="order.id">{{ order.number }}
              </el-checkbox>
            </el-checkbox-group>
          <el-checkbox label="слать письмо" v-model="sendMail"></el-checkbox>
        </div>
        <div style="display:flex;justify-content: flex-end">
          <my-el-button @click="onBtnCancelClick('break')" type="danger">Прервать</my-el-button>
          <my-el-button @click="onBtnCancelClick('skip')" type="warning">Пропустить</my-el-button>
          <my-el-button @click="onBtnNextClick" type="success">Отправить в ERP</my-el-button>
        </div>
      </div>
      <template v-else-if="mode === 'view'">
        <my-el-button @click="onBtnCancelClick" type="success">Закрыть</my-el-button>
      </template>
      <template v-else>
        <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        <my-el-button :disabled="mode === 'view'" @click="onBtnSendClick" type="success">
          {{ mode === 'template' ? 'Сохранить' : 'Отправить' }}
        </my-el-button>
      </template>
    </template>
  </component-window>
</template>

<script>

import { alert } from '@/components/common/dialogs/dialogUtils';
import FileTable from '@/components/common/fileTable';
import _ from 'lodash';
import TrumbowygWrapper from '@/components/common/trumbowygWrapper';



export default {
  name: 'MailEditor',
  components: { TrumbowygWrapper, FileTable },
  props: ['mail', 'mode', 'onClose', 'actionBeforeSend', 'accountType', 'batchData'],
  data() {
    return {
      accounts: [],
      batchResult: [],
      sendMail: true,
      rules: {
        name: [this.$validateRuleRequired],
        to: [this.$validateRuleRequired],
        from: [this.$validateRuleRequired],
        subject: [this.$validateRuleRequired],
        fromAccount: [this.$validateRuleRequired]
      },

      windowSize: { minHeight: 400, minWidth: 400, height: '80%', width: '900' }
    };
  },
  async mounted() {
    let result = await this.$store.dispatch('mails/getAccounts');

    this.accounts = this.accountType === 'delivery'
        ? result.filter(a => a.forType.some(t => t.toLowerCase() === this.accountType.toLowerCase()))
        : result;
    if (['send', 'batch'].includes(this.mode)) {
      if (this.accountType) {
        this.mail.fromAccount = this.accounts.find(a => a.forType.some(t => t.toLowerCase() === this.accountType.toLowerCase()))?.account;
      }
      if (this.accountType !== 'delivery')
        this.mail.from = this.accountType === 'com'
            ? 'Allgene'
            : this.$store.state.shared.currentUser.fio;
    }
    if (this.accounts.length === 1) {
      this.mail.fromAccount = this.accounts[0].account
    }



    if (this.batchData) {
      this.batchResult = this.batchData.map(o => o.id);
    }
  },

  methods: {
    onBtnCancelClick(action) {
      this.onClose &&
      this.onClose(this.mode === 'batch' ? { action } : null);
      this.$emit("close");
    },
    onBtnNextClick() {
      this.onClose({ action: 'next', orders: this.batchResult, mail: this.sendMail ? this.mail : null });
      this.$emit("close");
    },

    async onBtnSendClick() {
      try {
        await this.$refs.form.validate()
      } catch (ex) {
        return;
      }

      let mail = _.cloneDeep(this.mail);
      if (this.mode === 'send') {
        try {
          if (this.actionBeforeSend) {
            await this.actionBeforeSend(mail);
          }
          mail.body = mail.body.replace(/<!--\s*hide/g, '').replace(/hide\s*-->/g, '');
          mail.body = this.decodeHtml(mail.body);

          mail = await this.$store.dispatch('mails/saveItem', mail);
          mail.managerName = this.$store.state.shared.currentUser.fio;
        } catch (ex) {
          await alert('Ошибка отправки: ' + ex.message);
          return;
        }
      }
      this.onClose && this.onClose(this.mode === 'view' ? null : mail);
      this.$emit("close", this.mode === 'view' ? null : mail);
    },
    onAddFiles(files) {
      files.forEach(file => {
        file.metaorderId = this.mail.metaorderId;
        file.deliveryId = this.mail.deliveryId;
      });
    },
    decodeHtml(html) {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
  }
}
</script>

<style>
.editr--toolbar {
  display: -webkit-box;
}

.dashboard {
  padding: 8px 0 8px 0 !important;
}

.editr {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
