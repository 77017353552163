import ElementUI from "element-ui";
export default {
    extends: ElementUI.InputNumber,
    props: {
        controls: {default: false},
        precision: {default: 2},
        align: {default: 'left'},
        canBeEmpty: {default: false, type: Boolean}
    },
    
    data() {
        return {
            type: "number",
            controls: false,
        };
    },
    
    mounted: function() {
        if (this.align === 'left') {
            this.$el.classList.add('left-number-input');
        }
        let el = this.$children[ 0 ].$el.querySelector('.el-input__inner');
    
        let ctx = this;
        el.addEventListener('input',function() {
            this.value = this.value.replace(/,/g, '.');
            if (!ctx.canBeEmpty && !this.value) {
                this.value = '0';  
            }
        });
    
        el.addEventListener('paste', (event) => {
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            paste = paste.replace(/,/g, '.');
            paste = paste.replace(/[^0-9.]/g, '');
            this.value = paste;
            event.preventDefault();
        })
    }
}
