<template>
    <el-container style="height:100%">
        <el-header class="window-header" style="margin:5px; height:auto; display: flex;justify-content: flex-start; border-bottom: solid 1px #409EFF; padding: 0 0 5px 0">
            <i v-if="!isConnected" title="Потеряна связь с сервером" class="el-icon-warning" style="margin-right: 5px; margin-left: 5px; color: red"></i>
            <div style="font-size:16px; font-weight: bold; color: #409EFF;"
                 :style="{color: isTest? 'red':'#409EFF'}"
                 @click="openLoadFile"
                 v-html="title"></div>
            <el-tag v-if="isTest" type="danger" style="margin-left: 5px">Это тестовый заказ</el-tag>
            <div style="flex:1 0;padding-left: 5px">
                <i class="el-icon-loading" v-if="loadingNow"></i>
            </div>
            <div style="display: flex;justify-content: flex-end">
                <div v-if="lockManager && lockManager.state !== 'LOCK_FREE' ">
                    <el-popover
                            placement="top-start"
                            trigger="hover">
                        <div v-if="lockManager.state === 'LOCK_BY_ME'">Объект заблокирован вами</div>
                        <div v-else-if="lockManager.state === 'CHANGED'">
                            Объект изменен был.<br/>
                            <my-el-button v-if="showRefreshButton" @click="onClickRefreshButton" type="success">Обновить</my-el-button>
                        </div>
                        <div v-else>
                            Обьект заблокирован пользователем {{lockManager.user ? lockManager.user.name : ''}}<br/>
                            <my-el-button @click="killAndLock" type="danger">KILL {{lockManager.user.name}}</my-el-button>
                        </div>
                        <i class="el-icon-warning"
                           slot="reference"
                           style="cursor: pointer"
                           :style="{color: lockManager.state === 'LOCK_BY_OTHER' ? 'red': ( lockManager.state === 'CHANGED' ? '#e6a23c' : 'blue')}">
                        </i>
                    </el-popover>
                </div>
                <i v-if="maximize" @click="turnWindowSize" style="margin-left: 10px;cursor: pointer" class="el-icon-rank"></i>
                <i v-if="close" @click="$emit('close')" style="margin-left: 10px;cursor: pointer" class="el-icon-close"></i>
            </div>
        </el-header>
        <el-main style="height: 50%; margin:0 5px 5px 5px; padding:0 5px 5px 5px;overflow-x: hidden">
            <slot :locker-state="lockState"></slot>
        </el-main>
        <el-footer style="display:flex; justify-content: flex-end; height:30px; margin-bottom: 10px;padding-right: 10px; padding-left: 10px">
            <input type="file" style="display: none" ref="fileUpload" @change="loadFile"/>
            <slot name="footer" :locker-state="lockState"></slot>
        </el-footer>
    </el-container>
</template>

<script>
    import LockManager from "../../../utils/lockManager";
    import { alert } from '@/components/common/dialogs/dialogUtils';
    import tableSaver from '@/utils/tableSaver';
    import _ from 'lodash';

    export default {
        name: "componentWindow",
      mixins: [ tableSaver ],
        props: {
            lockName: { type: String },
            watchObject: { type: Object },
            isWatch: { type: Boolean, default: true },
            showRefreshButton: { type: Boolean, default: false },
            title: { type: String },
            addStyle: { default: '' },
            maximize: { type: Boolean, default: true },
            close: {type: Boolean, default: true },
            isTest: {type:Boolean, default: false }
        },

        data() {
            return {
                isConnected: true,
                lockManager: null,
                watch: false,
                currentSize: { width: 0, height: 0 },
                windowIsMaximize: false,
                saveWindowSize: null,
                localStorageFields: [ 'currentSize', 'saveWindowSize', 'windowIsMaximize' ]
            }
        },

        watch: {
            isWatch(value) {
                //Почему то watch на изменение watchObject срабатывает рпньше чем isWatch = true. $nextTick вроде помогает
                if (value) {
                    this.$nextTick(() => this.watch = value);
                } else {
                    this.watch = value;
                }
            }
        },

        errorCaptured(err, vm, info) {

            let data = {
                user: Object.assign(this.$store.state.shared.currentUser, {menu: null}),
                type: this.lockName,
                data: this.$parent._data,
                error: {
                    stack: err.stack,
                    message: err.message
                },
                info
            }
            let url = `/logs/saveLog?type=${this.$parent.$options.name}`;
            let seen = [];
            let result = JSON.stringify(data, function(key, val) {
                if (val != null && typeof val == "object") {
                    if (seen.indexOf(val) >= 0) {
                        return;
                    }
                    seen.push(val);
                }
                return val;
            });
            navigator.sendBeacon(url, result);
        },

        mounted() {
          this.$wsHub.$on('changeConnectionState', async data => {
            this.isConnected = data });

          let resizer = _.find(this.$parent.$parent.$children, c => c.$options.name === 'VueJsModalResizer');
            if (resizer) {
                resizer.$on('resize', ({ size }) => {
                    this.windowIsMaximize = false;
                    size.heightType = size.widthType = "px";
                    this.currentSize = size;
                    this.$emit('resize', size)
                })
            }
        },

        created() {

            if (!this.lockName) {
                return;
            }

            this.lockManager = new LockManager();

            window.addEventListener('beforeunload', () => {
                this.lockManager.unlock(true);
            });
            this.$watch("watchObject.id", async() => {
                if (this.currentUser) {
                    await this.initLockManager();
                } else {
                    this.$watch("currentUser", async() => {
                        await this.initLockManager();
                    });
                }
            });
        },

        async beforeDestroy() {
            this.$wsHub.$off('kill', this.onKill);
            this.$wsHub.$off('changes', this.onChangeObject);
            if (this.lockManager && this.lockManager.state === LockManager.LOCK_BY_ME) {
                await this.lockManager.unlock();
            }
        },

        methods: {
            openLoadFile(e) {
                    if (!e.ctrlKey) {
                        return;
                    }
                    this.$refs.fileUpload.click();
                },

            async loadFile(e) {
                let file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    let obj = JSON.parse(event.target.result);


                    if (!obj.metaorder) {
                        obj = obj.data;
                    } else {
                        let delivery = obj.delivery;
                        delivery.ordersRl = obj.metaorder.orders.map(o => ({orderId: o.id, order: o, price:0 }))
                        obj = {editOrder: obj.metaorder, deliverys: [delivery]}
                    }
                    this.$parent._data = Object.assign(this.$parent._data, obj);
                };
                reader.readAsText(file);
            },

           onChangeObject(data) {
             let objectByKeyArr = Object.entries(data).find(o => o[ 0 ].toLowerCase() === this.lockManager.name.toLowerCase());
             let idFindId =
                 (objectByKeyArr?.[ 1 ].update?.some(id => id === this.lockManager.id)
                     || objectByKeyArr?.[ 1 ].delete?.some(id => id === this.lockManager.id)
                 ) &&
                 (data.userId !== this.lockManager.currentUser.id || data.windowUid !== this.$windowUid) ;
             if (idFindId) {
               this.lockManager.user = null;
               this.lockManager.state = LockManager.CHANGED;
               this.lockManager.changeCallback && this.changeCallback()
             }
           },

            onKill(user) {
                this.lockManager.user = user;
                this.lockManager.state = LockManager.LOCK_BY_OTHER;
                this.onKilled(user)
            },

            async initLockManager() {
                await this.lockManager.init(this.lockName, this.watchObject.id, this.currentUser);
                if (this.lockManager.state === LockManager.LOCK_FREE || LockManager.LOCK_BY_ME) {
                    this.$wsHub.$on('kill', this.onKill);
                    this.$wsHub.$on('changes', this.onChangeObject);
                }

                if (this.lockManager.state === LockManager.LOCK_FREE) {
                    this.$watch("watchObject",
                                () => {
                                    (this.watch && this.lockManager.state === LockManager.LOCK_FREE) && this.lockManager.lock()
                                }
                        , { deep: true })
                }
            },


            turnWindowSize() {
                this.windowIsMaximize = !this.windowIsMaximize;
                let wnd = this.$parent.$parent;
                if (this.windowIsMaximize) {
                    this.saveWindowSize = _.cloneDeep(wnd.modal);
                    wnd.modal = {
                        height: wnd.window?.height || window.innerHeight,
                        width: wnd.window?.width || window.innerWidth,
                        widthType: 'px',
                        heightType: 'px'
                    };
                    this.currentSize = wnd.modal;
                } else if (this.saveWindowSize) {
                    wnd.modal = _.cloneDeep(this.saveWindowSize);
                    this.currentSize = wnd.modal;
                }
            },

            onKilled(user) {
                alert(`Ползователь ${user.name} теперь объект редактирует этот. Вас вместо.`);
                this.$emit('killed', user)
            },
            async unlock() {
                await this.lockManager.unlock();
            },
            async lock() {
              await this.lockManager.lock();
            },
            async killAndLock() {
                await this.lockManager.kill();
                await this.lockManager.lock();
            },
            onClickRefreshButton() {
                this.lockManager.state = LockManager.LOCK_FREE;
                this.$emit('click-refresh-button');
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.shared.currentUser;
            },

            loadingNow() {
                return this.$store.state.shared.loading > 0;
            },

            lockState() {
                return this.lockManager
                    ? {
                        canEdit: this.lockManager.state !== LockManager.LOCK_BY_OTHER && this.lockManager.state !== LockManager.CHANGED,
                        isEditNow: this.lockManager.state === LockManager.LOCK_BY_ME,
                    }
                    : {};
            }
        }
    }
</script>

<style scoped>

</style>
