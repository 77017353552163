<template functional>
    <el-popover v-if="props.commission" placement="top-start" trigger="hover">
        <div style="display: flex;flex-direction: column">
            <div style="display: flex;flex-direction: column;">
                <div style="font-size:16px; font-weight: bold; color: #409EFF ">комиссия банка</div>
                <pre style="margin:0;border:0;outline: 0" >{{parent.$roundFmt(props.commission)}} {{props.currency ? parent.$getEnumValue('CurrencyEnum', props.currency) : ''}}</pre>
            </div>
        </div>
        <el-tag size="mini" slot="reference" style="color:white; background-color: dodgerblue;cursor: pointer;margin-left: 5px">БК</el-tag>
    </el-popover>
</template>

<script>
    export default {
        name: 'MyBankCommission',
        props: ['commission', 'currency']
    }
</script>

<style scoped>

</style>