<template>
    <component-window
            title="Генерация таможенных документов"
            @close="onBtnCancelClick"
    >
        <div style="display:flex;flex-direction: column;margin-bottom: 10px;width: 100% ">
            <div style="display:flex;justify-content: space-between; align-items: center; flex-wrap: nowrap;width: 100%">
                <el-form :rules="rules" inline="true" :model="formRules" ref="formRules" style="width: 100%">
                    <el-form-item label="Поставщик:" prop="selectedAffiliation" style="display:flex;margin-bottom: 0">
                        <el-select
                                v-model="formRules.selectedAffiliation"
                                value-key="name"
                                placeholder="Выбрать"
                                style="margin:0 20px 0 10px;width: 100%"
                        >
                            <el-option
                                    v-for="aff in affiliations"
                                    :key="aff.id"
                                    :label="aff.name"
                                    :value="aff"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div style="display: flex;align-items: center; margin-top: 20px">
                Компания:
                <el-input v-model="reportParams.companyName" style="margin:0 20px 0 10px"></el-input>
                Покупатель:
                <el-input v-model="reportParams.companyContact" style="margin:0 0 0 10px"></el-input>
            </div>
            <div style="display: flex;align-items: center; margin-top: 20px">
                Телефон:
                <el-input v-model="reportParams.companyPhone" style="margin:0 20px 0 10px"></el-input>
                Страна:
                <el-input v-model="reportParams.companyCountry" style="margin:0 20px 0 10px"></el-input>
                Город:
                <el-input v-model="reportParams.companyCity" style="margin:0 20px 0 10px"></el-input>
                Индекс:
                <el-input v-model="reportParams.companyPostalCode" style="margin:0 0 0 10px"></el-input>
            </div>
            <div style="display: flex;align-items: center; margin-top: 20px">
                Адрес:
                <el-input v-model="reportParams.companyAddress" style="margin:0 0 0 10px"></el-input>
            </div>
            <div style="display: flex;align-items: center; margin-top: 20px">
                Служба доставки:
                <div style="margin:0 20px 0 10px;flex-shrink: 0;">
                    <delivery-service-selector 
                        with-pickup
                        v-model="selectedDeliveryService"
                        @change="changeDeliveryService"
                        ></delivery-service-selector>
                </div>
                <el-input v-model="reportParams.delivery" style="margin:0 20px 0 10px"></el-input>
                Номер отправления:
                <el-input v-model="reportParams.trackNumber" style="margin:0 20px 0 10px"></el-input>
                Дата отправления:
                <div style="width:200px;margin:0 20px 0 10px">
                    <el-date-picker
                            v-model="reportParams.sendDate"
                            type="date"
                            :picker-options="{firstDayOfWeek: 1}"
                            placeholder="Выбрать">
                    </el-date-picker>
                </div>
            </div>
        </div>
        <el-table
                :data="customsReportData"
                style="width: 100%;font-size: 16px"
                :border="true"
                ref="dataTable"
                v-ls-saver:itemsTbl
        >
            <el-table-column label="Наименование">
                <template slot-scope="scope">
                    <template>
                        {{scope.row.name}}
                    </template>
                </template>
            </el-table-column>
            <el-table-column width="100" label="К-во" align="top">
                <template slot-scope="scope">
                    <template>
                       <el-input v-model="scope.row.count" @input="onChangeRowCount(scope.row)"></el-input>
                    </template>
                </template>
            </el-table-column>
            <el-table-column width="100" label="Цена" align="top">
                <template slot-scope="scope">
                    <template>
                        <el-input v-model="scope.row.price" @input="onChangeRowCount(scope.row)"></el-input>
                    </template>
                </template>
            </el-table-column>

            <el-table-column width="100" label="Сумма" align="top">
                <template slot-scope="scope">
                    <template>
                        {{scope.row.sum}}
                    </template>
                </template>
            </el-table-column>

        </el-table>
        
        <template slot="footer">
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
            <my-el-button @click="onBtnCreateClick" type="success">Создать</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import _ from 'lodash';
    import tableSaver from '@/utils/tableSaver';
    import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
    import petrovich from 'petrovich';
    import DeliveryServiceSelector from "@/components/deliveries/DeliveryServiceSelector";
    
    export default {
        name: 'CustomsPrint',
      components: { DeliveryServiceSelector },
      props: [ 'deliveryId', 'onClose' ],
        mixins: [ tableSaver ],
        data() {
            return {
                windowSize: { minHeight: 400, minWidth: 400, height: '95%', width: '95%' },
                delivery: null,
                customsInfo: [],
                reportParams: {
                    currency: 'RUB',
                    delivery: '',
                    trackNumber: '',
                    companyName: '',
                    companyContact: '',
                    companyPhone: '',
                    companyCity: '',
                    companyPostalCode: '',
                    companyAddress: '',
                    companyCountry: '',
                    sendDate: null
                },
                reportData: [],
                formRules: {
                    selectedAffiliation: null,
                },
                affiliations: [],
                selectedDeliveryService: null,
                rules: {
                    selectedAffiliation: [ this.$validateRuleRequired ]
                },
                
                customsCatMap: [
                    { cat: 'AB', code: 1103 },
                    { cat: 'EA', code: 1114 },
                    { cat: 'ER', code: 1114 },
                    { cat: 'NK', code: 1114 },
                    { cat: 'FP', code: 1114 },
                    { cat: 'FOC', code: 1114 },
                    { cat: 'PK', code: 1114 },
                    { cat: 'PT', code: 1114 },
                    { cat: 'SK', code: 1114 }
                ]
            }
        },
        
        async mounted() {
            let filter = {
                orderBy: 'Name',
                orderIsDesc: false,
                query: [ { fieldName: 'forCustomsDoc', op: 'eq', values: [ true ] } ]
            };
            [ this.affiliations, this.delivery ] = await Promise.all([
                                  this.$store.dispatch('affiliations/loadItems', filter),
                                  this.$store.dispatch('deliveries/getDeliveryWithProductOrders', this.deliveryId)
                                ]);
            if (this.affiliations.length === 1) {
                this.formRules.selectedAffiliation = this.affiliations[0];
            }
            /* Таможенные документы можно сделать только для продуктовых заказов
              TODO: Наверно нужно проверять что если заказаы входят в разные метазазаказы то 
                    у этих метазаказов должны совпадать клиент и.т.д.   
             */
            if (this.delivery.ordersRl.some(rl => rl.order.type !== 'pdt')){
              alert('Доставка содержит заказы отличающиеся от продуктовых - не буду PDF создавать.');
              this.onBtnCancelClick();
              return;
            }
            let currencies = this.delivery.ordersRl.reduce((acc, rl) => acc.includes(rl.order.metaorder.currency) ? acc : [...acc, rl.order.metaorder.currency] , []);
            if (currencies.length !== 1 || ( currencies.length === 1 && !currencies[0] )) {
                alert('Доставка содержит заказы c разной валютой (или валюта не указана). Тут разбираться надо. Так быть ваще не должно. PDF создавать не хочу.');
                this.onBtnCancelClick();
                return;
            }
            this.reportParams.currency = currencies[0].toUpperCase();
            if (this.reportParams.currency === 'EUR') {
                this.reportParams.currency = 'EURO'     
            }
            
            let payers = this.delivery.ordersRl.reduce((acc, rl) =>               
                acc.some(a => a.id === rl.order.metaorder.billingAffiliation.id) ? acc : [...acc, rl.order.metaorder.billingAffiliation]
                , []);
            if (payers.length !== 1 || (payers.length === 1 && payers[0] === null) ) {
                alert('Доставка содержит заказы c разными плательщиками (или ваще без них). Тут разбираться надо. Так быть совсем не должно. PDF создавать не хочу.');
                this.onBtnCancelClick();
                return;
            }
            this.reportParams.companyName = payers[0].name || '';
            
            if (!this.delivery.woDelivery) {
                if (this.delivery.serviceId) {
                    this.selectedDeliveryService =  this.$deliveryServiceById(this.delivery.serviceId);
                    this.reportParams.delivery = `${this.selectedDeliveryService.name}`;
                    this.reportParams.trackNumber = this.delivery.trackNumber;
                    this.reportParams.sendDate = this.delivery.stateLog.find(l => l.newState === 'send')?.createdAt;
                }
                if (this.delivery.address) {
                    this.reportParams.companyAddress = this.$addressToString(this.delivery.address) + ', ' + this.delivery.address.country?.name;
                    let shortAddress = _.cloneDeep(this.delivery.address);
                    shortAddress.city = '';
                    shortAddress.postalCode = '';
                    this.reportParams.companyAddressShort = this.$addressToString(shortAddress);
                    this.reportParams.companyPostalCode = this.delivery.address.postalCode;
                    this.reportParams.companyCity = this.delivery.address.city;
                    this.reportParams.companyCountry = this.delivery.address.country?.name;
                } else {
                    this.reportParams.companyAddress = this.delivery.oldAddress?.replace(/\n/g, ' ')
                }
            }
            if (!this.delivery.client ) {
                alert('Для доставка не выбрат клиент. Так быть совсем не должно.  PDF создавать не хочу.');
                this.onBtnCancelClick();
                return;
            }
            
            this.reportParams.companyContact = this.delivery.recipient?.showNameEn || '';
            this.reportParams.companyPhone = this.delivery.recipient?.contacts.filter(c => c.type === 'phone').map(c => c.contact).join(', ');
            
            this.customsInfo = await this.$store.dispatch('products/getCustomsInfo', this.productsUniqueCats);
        },
        
        methods: {
            
            onBtnCancelClick() {
                this.onClose && this.onClose(null);
                this.$emit('close');
            },
            
            changeDeliveryService(deliveryService) {
                if (!deliveryService) {
                    this.reportParams.delivery = '';
                    this.reportParams.trackNumber = '';
                    this.reportParams.sendDate = null;
                    return;
                }
                this.reportParams.delivery = `via ${deliveryService.name}`;
            },
            
            async onBtnCreateClick() {
                let isValid = false;
                await this.$refs[ 'formRules' ].validate((valid) => isValid = valid);
                if (!isValid) {
                    return;
                }
                
                
                if (this.customsNotDescriptionCats.length > 0) {
                    let msg = `Есть продукт(ы) с каталожным номером не входящим в спиcок (${this.customsCatMap.map(m => m.cat).join(', ')}):<br/><br/>
                               <b>${this.customsNotDescriptionCats.join(', ')}</b>.<br/><br/>Продолжить?`;
                    if (!await confirm(msg)) {
                        return;
                    }
                }
                
                let cats = this.productsUniqueCats.filter(cat => !this.customsInfo.some(ci => ci.cat === cat));
                if (cats.length > 0) {
                    let msg = `Есть продукт(ы) с каталожным номером не входящим в спиcок определенный для таможни:<br/><br/>
                               <b>${cats.join(', ')}</b>.<br/><br/>Эти каталожные номера не попадут в таможенные документы. Продолжить?`;
                    if (!await confirm(msg)) {
                        return;
                    }
                }
                this.reportParams.sumForCustoms = this.customsReportData.reduce((acc, row) => acc + row.sum, 0);
                this.reportParams.pages = this.customsPages;
                
                let data = {
                    documents: ['customs'],
                    deliveryId: this.delivery.id,
                    reportParams: this.setEvrogenParams(this.reportParams, this.formRules.selectedAffiliation),
                    customsReportData: this.customsReportData
                };
                
                let result;
                try {
                    result = await this.$store.dispatch('buildDocs', data);
                } catch (ex) {
                    alert(ex.message);
                    return;
                }
                this.onClose && this.onClose(result);
                this.$emit('close');
            },
            
            setEvrogenParams(prm, aff) {
                prm.evrogenName = aff.name;
                prm.evrogenNameEn = aff.nameEn;
                prm.evrogenNameShort = aff.shortName;
                prm.evrogenAddress = aff.address;
                prm.evrogenAddressEn = aff.addressEn;
                
                prm.evrogenPhone = aff.phone;
                prm.evrogenFax = aff.fax;
                prm.evrogenSite = aff.site;
                prm.evrogenInn = aff.inn;
                prm.evrogenKpp = aff.kpp;
                prm.evrogenOkpo = aff.okpo;
                prm.evrogenOgrn = aff.ogrn;
                
               // prm.evrogenBankAccount = aff.bankAccount;
                prm.evrogenBankName = aff.bankName;
                prm.evrogenBankAddress = aff.bankAddress;
                prm.evrogenBankNameEn = aff.bankNameEn;
                prm.evrogenBankAddressEn = aff.bankAddressEn;
                prm.evrogenStampRu = aff.stampFileRu.length === 1 ? aff.stampFileRu[ 0 ].dataInBase64 : null;
                prm.evrogenStampEn = aff.stampFileEn.length === 1 ? aff.stampFileEn[ 0 ].dataInBase64 : null;
                prm.evrogenContact = aff.contact;
                prm.evrogenContactEn = aff.contactEn;
                prm.evrogenLegalAddress = aff.legalAddress;
                prm.evrogenLegalAddressEn = aff.legalAddressEn;
                prm.evrogenPostalCode = aff.postalCode;
                prm.evrogenMail = '';
                
                prm.evrogenDirector =
                    [
                        aff.directorLName || '',
                        aff.directorFName || '',
                        aff.directorMName || ''
                    ].filter(s => s.trim()).join(' ');
                let person = {
                    gender: 'male',
                    first: aff.directorFName,
                    last: aff.directorLName,
                    middle: aff.directorMName
                };
                person = petrovich(person, 'accusative');
                prm.evrogenDirectorAcc = [
                    person.last || '',
                    person.first || '',
                    person.middle || ''
                ].filter(s => s.trim()).join(' ');
                prm.evrogenDirectorEn = aff.directorEn;
                
                
                prm.evrogenAccountant = aff.accountant;
                prm.evrogenAccountantEn = aff.accountantEn;
                
                return prm;
            },
            onChangeRowCount(row) {
                if (!(typeof (row.count * 1) === 'number' && isFinite(row.count * 1))) {
                    return;
                }
                row.sum = row.count * row.price;
            }
        },
        
        computed: {
            customsNotDescriptionCats() {
                return this.productsUniqueCats
                .filter(cat => !this.customsCatMap.find(m => cat.startsWith(m.cat)));
            },
            
            customsPages() {
                let dscrPages = this.productsUniqueCats
                .map(cat => this.customsCatMap.find(m => cat.startsWith(m.cat))?.code)
                .filter((el, idx, src) => el && src.indexOf(el) === idx)
                .map(el => `description_${el}:3`)
                .join(', ');
                let msdsPages = [ ...new Set(this.customsInfo.map(ci => `msds_${ci.customsTypeName}:1`)) ].join(', ');
                
                return `Invoice:2, ${msdsPages}${msdsPages ? ', ' : ''}tsca:1${dscrPages ? ', ' : ''}${dscrPages}${this.delivery.onIce ? ', ice:1' : ''}`;
            },
            
            allProducts() {
                return (this.delivery?.ordersRl || [])
                .map(rl => rl.order)
                .filter(o => o.type === 'pdt')
                .reduce((acc, order) => [ ...acc, ...order.elements.filter(el => !el.forDelete) ], []) || [];
            },
            
            customsReportData() {
                return this.allProducts.reduce((acc, row) => {
                    let infos = this.customsInfo.filter(ci => ci.cat === row.product.cat);
                    return infos.reduce((acc, info) => {
                        
                        let existRow = acc.find(a => a.number === info.customsTypeName);
                        if (existRow) {
                            existRow.count += info.tubesCount * row.countAll;
                            existRow.sum += info.tubesCount * row.countAll * info.price;
                            return acc;
                        }

                        acc.push({
                                     number: info.customsTypeName,
                                     name: info.name,
                                     ruName: info.nameRu,
                                     enName: info.nameEn,
                                     price: info.price,
                                     count: info.tubesCount * row.countAll,
                                     sum: info.tubesCount * row.countAll * info.price
                                 });
                        return acc;
                    }, acc);
                }, [])
            },
            
            productsUniqueCats() {
                return [ ...new Set(this.allProducts.map(r => r.product.cat)) ];
            },
        }
    }
</script>

<style>

</style>