import { render, staticRenderFns } from "./AddressUserTable.vue?vue&type=template&id=4afb5d60&scoped=true&"
import script from "./AddressUserTable.vue?vue&type=script&lang=js&"
export * from "./AddressUserTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4afb5d60",
  null
  
)

export default component.exports