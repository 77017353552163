import { render, staticRenderFns } from "./oneCLabel.vue?vue&type=template&id=a2e28be8&scoped=true&functional=true&"
import script from "./oneCLabel.vue?vue&type=script&lang=js&"
export * from "./oneCLabel.vue?vue&type=script&lang=js&"
import style0 from "./oneCLabel.vue?vue&type=style&index=0&id=a2e28be8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "a2e28be8",
  null
  
)

export default component.exports