<template>
    <component-window
            :maximize="false"
            title="Паспорт лота"
            @close="onBtnClose"
    >
        <file-table :file-type="['lotPassport']" :files="files" 
                    with-date
                   @add-files="onAddFiles"  
                    @delete-file="onDeleteFile"
        ></file-table>
        <template slot="footer">
            <my-el-button @click="onBtnClose()" type="success">Закрыть</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import fileTable from "@/components/common/fileTable";
    export default {
        name: 'ProductPassportWindow',
        props: [ 'lotId', 'metaorderId', 'orderId', 'onClose'],
        components: {fileTable},
        data() {
            return {
               windowSize: {minHeight:150, minWidth:300, height:'300',  width: '500'},
               files:[] 
            };
        },
        async mounted() {
            await this.loadFiles();
        },
    
        methods: {
            async loadFiles(){
                this.files = await  this.$store.dispatch('files/loadItems',{ query: [{ fieldName: 'lotId', op: 'eq', values: [ this.lotId ] }]});    
            },
            
            async onBtnClose() {
                this.onClose && this.onClose(this.files);
                this.$emit('close', this.files);
            },
            
            async onAddFiles(files) {
                files.forEach(f => {
                    f.lotId = this.lotId;
                    f.metaorderId = this.metaorderId;
                    f.orderId = this.orderId;
                });
                await this.$store.dispatch('files/saveItem', files);
                await this.loadFiles();
            },
            async onDeleteFile(file) {
              await this.$store.dispatch('files/deleteItem', file)
              await this.loadFiles();  
            }
        },
    }
</script>

<style >
</style>