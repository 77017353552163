<template>
    <component-window
            :title="`Праймер`"
            @close="onBtnCancelClick"
            >
        <div style="display: flex;flex-direction: column;height: 100%">
            <el-form :rules="rules" :model="editPrimer" ref="editForm"  label-width="100px" style="flex: 0 0 auto;">
                <el-form-item label="Название" prop="name">
                    <el-input v-model="editPrimer.name"></el-input>
                </el-form-item>
                <el-form-item label="Праймер" prop="sequence">
                  <primer-editor ref="primerEditor" v-model="preparedPrimer" @input="onPrimerChange" need-modifier="no" can-be-empty="true"></primer-editor>
                </el-form-item>
                <el-form-item v-if="editPrimer.storeType === 'fromUser'" label="Концентрация" prop="concentration">
                    <el-input v-model="editPrimer.concentration"></el-input>
                </el-form-item>
                <el-form-item label="Материал">
                    <el-dropdown trigger="click" @command="editPrimer.actionAfterEnd=$event">
                        <el-button type="primary">
                            {{$getEnumValue('ActionAfterEndEnum',editPrimer.actionAfterEnd)}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in $getEnum('ActionAfterEndEnum').filter( i => i.value !== editPrimer.actionAfterEnd )"   :command="item.value" :key="item.value">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>


                <el-form-item label="C синтезом">
                    <el-switch
                       v-model = "editPrimer.storeType"
                       active-value="fromOrder"
                       inactive-value="fromUser" ></el-switch>
                </el-form-item>

            </el-form>
        </div>
        <template slot="footer">
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
            <my-el-button  @click="onBtnSaveClick" type="success">Применить</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import primerEditor from "@/components/common/PrimerEditor";

    export default {
        name: 'SeqPrimerEditor',
        components: { primerEditor},
        props: [ 'allPrimers', 'primer', "onClose" ],
        data() {
                let validateUniquePrimerName = async(rule, value, callback) => {
                    this.allPrimers.some(p =>  p.id !== this.editPrimer.id && !p.forDelete && p.name.toLowerCase().replace(/ /g,'') === value.toLowerCase().replace(/ /g,''))
                        ? callback(new Error('Название уже есть.'))
                        : callback();
                };

                let validateConcentration = async(rule, value, callback) => {
                  (value?.trim() && value.search(/[0-9]/i) === -1)
                      ? callback(new Error('В поле концентрация что-то не то. Цифр нет. А должны быть.'))
                      : callback();
                }


                let validatePrimer = async(rule, value, callback) => {
                  if (!this.preparedPrimer.sequence) {
                    callback();
                    return;
                  }
                  let errors = this.$primerHelper.getErrorMessages(this.preparedPrimer, 'no');
                  if (errors.length > 0) {
                    callback(new Error(errors[ 0 ].message));
                  } else {
                    callback();
                  }
                };

                return {
                  editPrimer: { id: null, orderId: null, name: '', sequence: '', storeType: 'fromUser' },
                  preparedPrimer: { name: '-', scale: 0.04 },
                  lockManager: null,
                  windowSize: { minHeight: 330, minWidth: 300, height: 330, width: '65%' },
                  rules: {
                    name: [this.$validateRuleRequired, { validator: validateUniquePrimerName }],
                    sequence: [{ validator: validatePrimer }],
                    concentration: [{validator: validateConcentration}]
                  }
                };
        },

        async mounted() {
            if (this.primer) {
                this.editPrimer = this.primer;
                this.preparedPrimer = this.$primerHelper.parse(this.primer.sequence);
                this.preparedPrimer.name = '-';
                this.preparedPrimer.scale = 0.04;
            }
        },

        methods: {
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit('close');
            },

            async onBtnSaveClick() {
                try {
                    await this.$refs.editForm.validate()
                } catch (ex) {
                    return;
                }
                this.onClose(this.editPrimer);
                this.$emit('close');
            },

            onPrimerChange(primer) {
                this.editPrimer.sequence = primer.sequence;
                this.$refs.editForm.validate('sequence');
            },
        },
    }
</script>

<style>
    .popper {
        width: auto!important;
    }


</style>
