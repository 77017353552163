<template functional>
    <div style="display: flex">
        <div style="min-width:15px;white-space: nowrap">
            <el-popover v-if="props.order.comment || (props.metaorder && (props.metaorder.managerComment || props.metaorder.userComment ))"
                        placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div v-if="props.order.comment" style="display: flex;flex-direction: column">
                        <div style="padding-left: 10px;font-size:16px; font-weight: bold; color: #409EFF ">Комментарий к подзаказу</div>
                        <pre style="padding-left: 10px;margin:0;border:0;outline: 0; white-space: pre-wrap;">{{props.order.comment}}</pre>
                    </div>
                    <template v-if="props.metaorder">
                        <div v-if="props.metaorder.managerComment" style="display: flex;flex-direction: column">
                            <div style="padding-left: 10px;font-size:16px; font-weight: bold; color: #409EFF ">Комментарий ко всему заказу</div>
                            <pre style="padding-left: 10px;margin:0;border:0;outline: 0; white-space: pre-wrap;">{{props.metaorder.managerComment}}</pre>
                        </div>
                        <div v-if="props.metaorder.userComment" style="display: flex;flex-direction: column">
                            <div style="padding-left: 10px;font-size:16px; font-weight: bold; color: #409EFF ">Комментарий заказчика</div>
                            <pre style="padding-left: 10px;margin:0;border:0;outline: 0; white-space: pre-wrap;" >{{props.metaorder.userComment}}</pre>
                        </div>
                    </template>
                </div>
                <i class="el-icon-info" slot="reference" style="cursor: pointer;font-size: 12px;color:#409EFF;margin-right: 3px"></i>
            </el-popover>
            <el-popover v-if="props.order.isDeffered" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div style="font-size:16px; font-weight: bold; color: #409EFF">Отложен {{ props.order.defferedUntil ? ('до ' + (props.order.defferedUntil)) : '' }}</div>
                    {{props.order.defferedReason}}
                </div>
                <i class="el-icon-alarm-clock"
                   slot="reference"
                   style="cursor: pointer;font-size: 12px;margin-right: 3px"
                   :style="{color: (props.order.defferedUntil && new Date(props.order.defferedUntil) < new Date()) ? 'red' : '#409EFF'}"
                ></i>
            </el-popover>
            <el-popover v-if="props.order.isUrgent" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div style="font-size:16px; font-weight: bold; color: #409EFF">Срочный</div>
                    {{props.order.urgentReason}}
                </div>
                <i class="el-icon-warning" slot="reference" style="cursor: pointer;font-size: 12px;color:#E6A23C;margin-right: 3px"></i>
            </el-popover>
        </div>
        <div :class="props.disabled ? 'dis-my-link':'my-link'"
             @click="!props.disabled && $options.showOrder(props, listeners, parent)"
             @mousedown.stop.prevent
             @click.middle.prevent.stop="$options.showOrder(props, listeners, parent, true)">{{props.order.number}}
        </div>
    </div>
</template>

<script>
    import orderEditor from '../order/OrderEditor';
    import moment from 'moment-business-days';
    
    export default {
        name: 'OrderNumber',
        props: ['order', 'metaorder', 'disabled', 'noShowWindow', 'setUser'],
        
        moment() {
            return moment;
        },
        async showOrder(props, listeners, parent, isNewWindow) {
            if (props.noShowWindow) {
                listeners[ 'click' ]({ metaorderId: props.order.metaorderId, orderId: props.order.id });
                return;
            }
            if (isNewWindow) {
                window.open(`/order/${props.order.metaorderId}/${props.order.id}`, '_blank');
            } else {
                listeners[ 'begin-edit' ] && listeners[ 'begin-edit' ](props.order.metaorderId);
                
                let result = await parent.$showWindowAsync(orderEditor,
                                                           {
                                                               id: props.order.metaorderId,
                                                               user: props.setUser ? props.metaorder?.user : null,
                                                               client: props.setClient ? props.metaorder?.client : null,
                                                               subOrderId: props.order.id
                                                           });
                listeners[ 'begin-edit' ] && listeners[ 'end-edit' ](result);
                
            }
        },
    }
</script>

<style>
</style>