import Vue from 'vue';
import Router from 'vue-router';
import MailTemplates from '@/components/mailsAndTemplates/MailTemplates';
import SequenceNoteList from '@/components/sequence/SequenceNoteList';
import RecalcBiRecords from '@/components/admins/RecalcBiRecords';
import TotalReport from '@/components/reports/TotalReport';
const MoveContainers  = () => import('@/components/deliveries/MoveContainers');

const MainLayout = () => import('@/components/pages/MainLayout');
const Signin = () => import('@/components/pages/Signin');
const Managers = () => import('@/components/admins/Managers');
const Roles = () => import('@/components/admins/Roles');
const Affiliations = () => import('@/components/usersClientsAffiliation/Affiliations');
const Clients = () => import('@/components/usersClientsAffiliation/Clients');
const Users = () => import('@/components/usersClientsAffiliation/Users');
const Orders = () => import('@/components/order/Orders');
const Templates = () => import('@/components/mailsAndTemplates/Templates');
const Deliverys = () => import('@/components/deliveries/Deliverys');
const PrimerStores = () => import('@/components/sequence/PrimerStores');
const Settings = () => import('@/components/admins/Settings');
const SequenceQueue = () => import('@/components/sequence/SequenсeQueue');
const Metaorders = () => import('@/components/order/Metaorders');
const ForeignMetaorders = () => import('@/components/order/ForeignMetaorders');
const SequencePlates = () => import('@/components/sequence/SequenсePlates');
const Prices = () => import('@/components/admins/Prices');
const Countries = () => import('@/components/admins/Countries');
const Modifiers = () => import('@/components/admins/Modifiers');
const SequencingSettings = () => import('@/components/admins/SequencingSettings');
const viewPage = () => import('@/components/pages/ViewPage');
const DeliveryServices = () => import('@/components/deliveries/DeliveryServices');
const Invoices = () => import('@/components/invoices/Invoices');
const SequenceChecks = () => import('@/components/sequence/SequenceChecks');
const PrimerReports = () => import('@/components/reports/PrimerReports');
const SequenceReports = () => import('@/components/reports/SequenceReports');
const ProductReports = () => import('@/components/reports/ProductReports');
const AllServicesReports = () => import('@/components/reports/AllServicesReports');


const ProductByMonthReport = () => import('@/components/reports/ProductByMonthReport');
const Mails = () => import('@/components/mailsAndTemplates/Mails');
const SiteSearchHistory = () => import('@/components/reports/SiteSearchHistory');
const Bookmarks = () => import('@/components/pages/Bookmarks');
const InvoicesInfo = () => import('@/components/reports/InvoicesInfo');
const OneCRecords = () => import('@/components/admins/OneCRecords');
const StartPage = () => import('@/components/pages/StartPage');
const Payments = () => import('@/components/admins/Payments');
const ErpUtils = () => import('@/components/admins/ErpUtils');

Vue.use(Router);

// noinspection JSCheckFunctionSignatures
const router = new Router({
                              routes: [
                                  { path: '/orders/usr-:userId(\\d+)/:editId?', component: Orders, props: true },
                                  { path: '/orders/cln-:clientId(\\d+)/:editId?', component: Orders, props: true },
        
                                  {
                                      path: '/',
                                      name: 'MainLayout',
                                      component: MainLayout,
                                      children: [
                                          { path: 'managers/:editId?', component: Managers, props: true, meta: { title: 'Сотрудники' } },
                                          { path: 'roles/:id?', component: Roles, props: true, meta: { title: 'Роли' } },
                
                                          { path: 'affiliations/:editId?', component: Affiliations, props: true, meta: { title: 'Организации' } },
                                          { path: 'clients/:editId?', component: Clients, props: true, meta: { title: 'Рабочие группы' } },
                                          { path: 'users/:editId?', component: Users, props: true, meta: { title: 'заказчики' } },
                                          { path: 'orders/:editId?', component: Orders, props: true, meta: { title: 'Производственные заказы' } },
                                          { path: 'metaorders/:editId?', component: Metaorders, props: true, meta: { title: 'Заказы c сайта' } },
                                          { path: 'foreignMetaorders/:editId?', component: ForeignMetaorders, props: true, meta: { title: 'Зарубежные заказы' } },

                                          { path: 'templates/:editId?', component: Templates, props: true, meta: { title: 'Почтовые шаблоны' } },
                                          { path: 'deliverys/:mails(mails)?/:editId(\\d+)?', component: Deliverys, props: true, meta: { title: 'Доставка' } },

                                          { path: 'primerStores/:editId?', component: PrimerStores, props: true, meta: { title: 'Праймеры для секвенирования' } },
                                          { path: 'settings/:editId?', component: Settings, props: true, meta: { title: 'Настройки' } },
                                          { path: 'sequenceQueue/', component: SequenceQueue, props: true, meta: { title: 'Очередь сиквенсов' } },
                                          { path: 'plates/:editId?', component: SequencePlates, props: true, meta: { title: 'Секвенирование - плашки' } },
                                          { path: 'prices/', component: Prices, props: true, meta: { title: 'Цены и каталожные номера' } },
                                          { path: 'countries/:editId?', component: Countries, props: true, meta: { title: 'Страны' } },
                                          { path: 'synthesisSettings/', component: Modifiers, props: true, meta: { title: 'Модификаторы' } },
                                          { path: 'sequencingSettings/', component: SequencingSettings, props: true, meta: { title: 'Настройки секвенирования' } },
                                          { path: 'deliveryServices/:editId?', component: DeliveryServices, props: true, meta: { title: 'Службы доставки' } },
                                          { path: 'invoices/:editId?', component: Invoices, props: true, meta: { title: 'Счета' } },
                                          { path: 'sequenceChecks/:editId?', component: SequenceChecks, props: true, meta: { title: 'Проверки' } },
                                          { path: 'mails/:editId?', component: Mails, props: true, meta: { title: 'Письма' } },
                                          { path: 'siteSearchHistory', component: SiteSearchHistory, props: true, meta: { title: 'История поиска на сайте' } },
                                          { path: 'bookmarks/:bookmarkId', component: Bookmarks, props: true },
                                          { path: 'primerReports/', component: PrimerReports, props: true, meta: { title: 'Отчеты по праймерам' } },
                                          { path: 'sequenceReports/', component: SequenceReports, props: true, meta: { title: 'Отчеты по сиквенсам' } },
                                          { path: 'productReports/', component: ProductReports, props: true, meta: { title: 'Отчеты по продуктам' } },
                                          { path: 'allServicesReports/', component: AllServicesReports, props: true, meta: { title: 'Отчеты по всем сервисам' } },
                                          
                                          { path: 'productByMonthReport/', component: ProductByMonthReport, props: true, meta: { title: 'Продажи продуктов по месяцам' } },
                                          { path: 'invoicesInfo/', component: InvoicesInfo, props: true, meta: { title: '' } },
                                          { path: 'oneCRecords/', component: OneCRecords, props: true, meta: { title: 'Загрузка из 1С' } },
                                          { path: 'mailTemplates/', component: MailTemplates, props: true, meta: { title: 'Шаблоны для писем' } },
                                          { path: 'payments/', component: Payments, props: true, meta: { title: 'Платежи' } },
                                          { path: 'erp/', component: ErpUtils, props: true, meta: { title: 'Утилиты для ERP' } },
                                          { path: 'recalcBiRecords/', component: RecalcBiRecords, props: true, meta: { title: 'Пересчет статистики' } },
                                          { path: 'totalReport/', component: TotalReport, props: true, meta: { title: 'Отчеты, итого' } },
                                          { path: 'moveContainers/:editId?', component: MoveContainers, props: true, meta: { title: 'Перемещения' } },
                                          { path: '/', component: StartPage, props: true, meta: { title: 'Стартовая страница' } },
                                      ]
                                  },
                                  { path: '/signin', name: 'Signin', component: Signin },
                                  { path: '/sequenceNoteList', component: SequenceNoteList },
                                  { path: '/:component/:id/:subOrderId?', component: viewPage, props: true },
                              ],
                              mode: 'history'
                          });
export default router;

