import confirmTemplate from './confirmDialogTemplate';
import alertTemplate from './alertDialogTemplate';
import alertWithLogTemplate from './alertWithLogTemplate';
import confirmWithLogTemplate from './confirmWithLogTemplate';
import DateSelectorWindow from "@/components/common/dialogs/DateSelectorWindow";

export function confirm(message, okText = null, title = null, isReverseButtons = false ){
    return new Promise((resolve) => {
        window.mainVue.$modal.show(confirmTemplate, 
                         {title, message, resolve, okText, isReverseButtons},            
                         { width: 300, height: 'auto', clickToClose:false, resizable: true})
    });
}

export function alert(message, okText = null, title = null ) {
    return new Promise((resolve) => {
        window.mainVue.$modal.show(alertTemplate,
                                   { title: title, message: message, resolve: resolve, okText: okText },
                                   { width: '30%', height: 'auto', maxHeight: 700, clickToClose: false, resizable: true })
    });
}
export function alertWithLog(message, log, title = null){
        return new Promise((resolve) => {
            window.mainVue.$modal.show(alertWithLogTemplate, 
                                {title, message, log, resolve},
                                { width:'400px', height: '450px', adaptive: true, clickToClose: false, resizable: true, draggable:'.window-header' });
        });
}
export function confirmWithLog(message, log, title, okText, cancelText){
    return new Promise((resolve) => {
        window.mainVue.$modal.show(confirmWithLogTemplate,
                                   {title, message, log, okText, cancelText, resolve},
                                   { width:'400px', height: '450px', adaptive: true, clickToClose: false, resizable: true, draggable:'.window-header' });
    });
}

export function promptDate(title, date){
    return new Promise((resolve) => {
        window.mainVue.$modal.show(DateSelectorWindow,
                                   {title, date, onClose: resolve},
                                   { width:'240px', height: '130px', adaptive: true, clickToClose: false, resizable: false, draggable:'.window-header' });
    });
}


