<template functional>
  <el-popover
      v-if="(props.disabled && props.disPopover) || (!props.disabled && props.popover)"
      placement="top-end"
      trigger="hover">
    <div v-html="props.disabled ? props.disPopover: props.popover"></div>
    <div slot="reference" style="display: inline-block;padding-right: 5px;padding-left: 5px">
      <div v-if="props.link"
           :class="!props.disabled ? 'my-link' : 'dis-my-link'"
           @click="!props.disabled && listeners['click']()"
      >
        <slot/>
      </div>
      <el-button
          v-else
          style="padding: 0"
          ref="btn"
          v-bind="data.attrs"
          v-on="listeners"
          :type="$options.calcType(props, $options)"
          :icon="$options.calcIcon(props, $options)"
          :disabled="props.disabled"
          :loading="$options.isDataLoadingNow(parent.$store, props, data)">
        <slot/>
      </el-button>
    </div>
  </el-popover>
  <div v-else style="display: inline-block;padding-right: 5px;padding-left: 5px">
    <div v-if="props.link"
         :class="!props.disabled ? 'my-link' : 'dis-my-link'"
         @click="!props.disabled && listeners['click']()"
    >
      <slot/>
    </div>
    <el-button
        v-else
        ref="btn"
        v-bind="data.attrs"
        v-on="listeners"
        :type="$options.calcType(props, $options)"
        :icon="$options.calcIcon(props, $options)"
        :disabled="props.disabled"
        :loading="$options.isDataLoadingNow(parent.$store, props, data)">
      <slot/>
    </el-button>
  </div>


</template>

<script>
export default {
  name: "myElButton",
  props: {
    ignoreLoading: { default: false },
    loading: { default: false },
    icon: { default: '' },
    popover: { default: '' },
    disPopover: { default: '' },
    type: { default: '' },
    'disabled': Boolean,
    'link': Boolean,
    'edit': Boolean,
    'delete': Boolean,
    'cancel': Boolean,
    'check': Boolean,
    'plus': Boolean,
    'minus': Boolean,
    'arrow': Boolean,
    'arrowL': Boolean
  },

  types: [
    { key: 'delete', icon: 'el-icon-delete', type: 'danger' },
    { key: 'edit', icon: 'el-icon-edit', type: 'success' },
    { key: 'check', icon: 'el-icon-check', type: 'success' },
    { key: 'cancel', icon: 'el-icon-error', type: 'warning' },
    { key: 'minus', icon: 'el-icon-minus', type: 'warning' },
    { key: 'plus', icon: 'el-icon-plus', type: 'success' },
    { key: 'arrow', icon: 'el-icon-d-arrow-right', type: 'warning' },
    { key: 'arrowL', icon: 'el-icon-d-arrow-left', type: 'warning' },
  ],
  calcType(props, options) {
    if (props.type) {
      return props.type;
    }
    let fndType = options.types.find(t => props[ t.key ] === true)
    return fndType?.type;
  },

  calcIcon(props, options) {
    if (props.icon) {
      return props.icon;
    }
    let fndType = options.types.find(t => props[ t.key ] === true)
    return fndType?.icon;
  },


  isDataLoadingNow(store, props) {
    return !props.ignoreLoading && (store.state.shared.loading > 0 || props.loading);
  },
}
</script>

<style>
.el-button--mini {
  padding: 7px 10px!important;
}
</style>