import { store } from '@/store';
import axios from 'axios';
//import JSZip from "jszip";


export default {
    install(Vue) {

        axios.interceptors.request.use(async config => {
            config.headers[ 'Authorization' ] = `Bearer ${Vue.prototype.$currentUser.getJwtToken()}`
            return config
        }, (error) => {
            return Promise.reject(error)
        })

        function post(url, data, withIndicator = true, contentType = null, responseType = null) {
            let options = {
                headers: {
                    'Content-Type': contentType || 'application/json',
                    'wndUid': window.mainVue?.$windowUid
                },
                responseType
            };
            if (withIndicator) {
                store.commit('setLoading', true);
            }
            return axios.post(url, data, options).then(
                response => {
                    withIndicator && store.commit('setLoading', false);
                    if (response.data.isError) {
                        if (response.data.code === 403 && window.location.href.indexOf('/signin') < 0) {
                            window.location.href = '/signin';
                            return;
                        }
                        return Promise.reject({
                                                  url: url,
                                                  message: response.data.message,
                                                  code: response.data.code,
                                                  trace: response.data.trace
                                              });
                    }
                    return response.data;
                },
                error => {
                    withIndicator && window.mainVue.$store.commit('setLoading', false);
                    throw {
                        url: url,
                        message: error.response ? error.response.statusText : "Не удалось получить ответ сервера",
                        trace: ""
                    }
                }
            );
        }

        async function postWithDownload(url, data, fileName, showSelectPathDialog = false) {
            let resultData = await post(url, data, true, null, 'arraybuffer');
            await downloadFromDataLink(resultData, fileName, false, showSelectPathDialog);
        }

        async function downloadFromDataLink(data, fileName, sourceInBase64 = false, showSelectPathDialog = false) {
            let blobData = sourceInBase64
                ? base64ToBlob(data)
                : new Blob([data]);
            await downloadFromBlob(blobData, fileName, showSelectPathDialog);
        }

        async function downloadFromBlob(blobData, fileName, selectPath = false) {
            if(selectPath && window.showSaveFilePicker ) {
                let options = {
                    suggestedName:fileName,
                    types: [{
                        description: 'Файлы какие-то',
                        accept: {
                            'application/octet-stream': ['.' + fileName.split('.')[fileName.split('.').length - 1]],
                        },
                    }],
                }
                const handle = await window.showSaveFilePicker(options);
                const writable = await handle.createWritable();
                await writable.write(blobData);
                await writable.close();
                return;
            }

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blobData);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }

        function base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for ( let offset = 0; offset < byteCharacters.length; offset += sliceSize ) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for ( let i = 0; i < slice.length; i++ ) {
                    byteNumbers[ i ] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        }

        function get(url) {
            return axios.get(url);
        }

        async function postSimple(url, data) {
            let auth = 'Bearer ' + Vue.prototype.$currentUser.getJwtToken();
            fetch(url, {
                keepalive: true,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': auth,
                },
                body: JSON.stringify(data),
            });
        }

        Vue.prototype.$myHttp = {
            post,
            postWithDownload,
            postSimple,
            get,
            downloadFromDataLink,
            downloadFromBlob
        }
    }
}
