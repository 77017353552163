<template>
  <div style="display: flex;flex-direction: column;height: 100%">
    <el-form :model="editOrder" ref="form" :rules="rules">
      <el-form-item label="Валюта">
        <div style="display: flex">
          <el-form-item style="margin-bottom: 0">
            <b>{{ $getEnumValue('CurrencyEnum', editOrder.currency) }}</b>
          </el-form-item>

          <el-form-item label="Способ оплаты" label-width="130px" style="margin-bottom: 0">
            <my-el-select style="width: 160px"
                          v-model="editOrder.payType"
                          value-key="name"
                          :value="editOrder.payType"
                          :disabled="payTypeIsDisabled"
            >
              <el-option
                  v-for="pt in $getEnum('PayTypeEnum')"
                  :key="pt.value"
                  :label="pt.name"
                  :value="pt.value">
              </el-option>
            </my-el-select>
          </el-form-item>
          <el-form-item label="Процент предоплаты" prop="prepaymentPercent" label-width="170px" label-position="right" style="margin-bottom: 0">
            <el-input v-model="editOrder.prepaymentPercent" style="width: 55px"></el-input>
          </el-form-item>
          <el-form-item label="Сумма" label-width="70px" label-position="right" style="margin-bottom: 0">
            <b>{{ $roundFmt(editOrder.ordersSum + editOrder.deliverySum) }}</b>
          </el-form-item>
          <el-form-item label="Оплачено" label-width="110px" label-position="right" style="margin-bottom: 0">
            <b>{{ $roundFmt(editOrder.paySum) }}</b>
          </el-form-item>
          <el-form-item v-if="!!editOrder.payCommission" label="Комиссия" label-width="110px" label-position="right" style="margin-bottom: 0">
            <b>{{ $roundFmt(editOrder.payCommission) }}</b>
          </el-form-item>
          <el-form-item v-if="editOrder.payType === 'fromInvoice'" label="Предоплата" label-width="130px" label-position="right" style="margin-bottom: 0">
            <b>{{ $roundFmt((editOrder.ordersSum + editOrder.deliverySum) / 100 * editOrder.prepaymentPercent) }}</b>
          </el-form-item>
          <el-form-item v-if="editOrder.payState !== 'notPaid'" label="Состояние оплаты" label-width="130px" label-position="right" style="margin-bottom: 0">
            <b style="white-space: nowrap">{{ $getEnumValue('PayStateEnum', editOrder.payState) }}</b>
          </el-form-item>

          <div v-if="editOrder.client" @click="showClient" class="my-link" style="width: 100%;text-align: right;white-space: nowrap;">Баланс рабочей группы</div>
        </div>
      </el-form-item>
      <el-form-item v-if="oldInvoices" label="Поле инвойс из заказов старой базы">
        <b>{{ oldInvoices }}</b>
      </el-form-item>
      <el-form-item v-if="oldPayDate" label="Дата оплаты из заказов старой базы">
        <b>
          <my-date :date="oldPayDate"></my-date>
        </b>
      </el-form-item>

    </el-form>
    <div v-show="editOrder.payType === 'fromAccount'">
      <el-form label-position="right" label-width="170px">
        <el-form-item label="Рабочая группа"><b>{{ editOrder.client ? editOrder.client.name : '' }}</b></el-form-item>
        <el-form-item label="Баланс рабочей групппы"><b> {{ editOrder.client ? $roundFmt(editOrder.client.currentBalance) : '' }}</b></el-form-item>
        <el-form-item label="Допустимая задолжность"><b>{{ editOrder.client ? $roundFmt(editOrder.client.maxAccountDebt) : '' }}</b></el-form-item>
        <template v-if="editOrder.paidFromAccount">
          <el-form-item label="Дата оплаты">
            <div style="width: 100px;font-weight: bold">
              <my-date :date="editOrder.paidFromAccountDate"></my-date>
            </div>
          </el-form-item>
          <el-form-item label="Оператор" ><b>
            {{ editOrder.paidFromAccountManager }}</b>
          </el-form-item>
        </template>
        <el-form-item label="" label-width="165px">
          <my-el-button
              v-if="!editOrder.paidFromAccount"
              :disabled="!!accountPayDisableText"
              :dis-popover="accountPayDisableText"
              style="padding-left: 0"
              @click="$emit('account-pay', true)"
              type="success">Оплатить с личного счета
          </my-el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="editOrder.payType === 'fromSber'">
      <el-form label-position="right" label-width="60px">
        <el-form-item label="Сумма">
          <div style="display: flex;justify-content: space-between">
            <div style="display: flex">
              <b>{{ editOrder.ordersSum + editOrder.deliverySum }}</b>
              <el-form-item v-if="editOrder.sberId" label="Id Сбербанка" label-width="150px">
                <a :href="$settings.sber.formUrl + editOrder.sberId" target="_blank">{{ editOrder.sberId }}</a>
              </el-form-item>
              <el-form-item v-if="editOrder.sberId" label="Код операции" label-width="150px">
                {{ sberInfo.actionCode }} {{ buildSberCodeDescription(sberInfo) }}
                <i class="el-icon-refresh my-link" style="margin-left: 5px" @click="loadSberInfo(editOrder.sberId)"></i>
              </el-form-item>
            </div>
            <div>
              <my-el-button
                  v-if="!editOrder.payState || editOrder.payState === 'notPaid'"
                  style="padding-left: 0"
                  @click="showSberMailWithPay(false)"
                  type="success">
                {{ !editOrder.sberId ? 'Создать платеж и отправить письмо' : 'Создать новый платеж и письмо' }}
              </my-el-button>
              <my-el-button
                  v-if="(editOrder.payState === 'notPaid' || !editOrder.payState) && editOrder.sberId"
                  style="padding-left: 0"
                  @click="showSberMailWithPay(true)"
                  type="success">Повторить письмо
              </my-el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <invoice-table
        ref="invoiceTable"
        v-show="editOrder.payType === 'fromInvoice' || editOrder.payType === 'fromSber'"
        :query="invoiceQuery"
        :metaorder="editOrder"
        :client="editOrder.client"
        :to="editOrder.billingAffiliation"
        :is-sber="editOrder.payType === 'fromSber'"
        show-from="order"
        @save="onInvoiceTableSaveOrder"
        @pay-invoice="onInvoiceTablePay"
        @reload-order="reloadOrder"
        :can-delete-invoice="editOrder.payType === 'fromSber' || !this.sberInfo"
        :delivery="findDeliveryForInvoice"
    ></invoice-table>
  </div>
</template>

<script>
import { alert } from '@/components/common/dialogs/dialogUtils';
import baseOrderTab from './BaseOrderTab.js';
import invoiceTable from '@/components/invoices/InvoiceTable';
import clientEditor from "@/components/usersClientsAffiliation/ClientEditor";

export default {
  name: 'PayTab',
  props: ['editOrder', 'deliveries', 'sum'],
  mixins: [baseOrderTab],
  components: { invoiceTable },
  data() {
    return {
      sberInvoice: null,
      sberInfo: {},
      showSberIsOn: false,
      newInvoiceNumber: '',
      rules: {
        prepaymentPercent: [this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, false)]
      }
    };
  },

  watch: {
    'editOrder.sberId': async function(value) {
      this.loadSberInfo(value);
    }
  },

  methods: {

    async showClient() {
      await this.$showWindowAsync(clientEditor, { id: this.editOrder.client.id, tab: 'balance' });
    },

    buildSberCodeDescription(info) {
      if (!info) return;
      if (info.actionCodeDescription) {
        return `(${info.actionCodeDescription})`;
      }
      switch ( info.actionCode ) {
        case 0:
          return '(Успешный платеж)';
        case -100:
          return '(Еще не пробовали заплатить)';
        default:
          return '';
      }
    },

    async showSberMailWithPay(onlyMail = false) {

      if (this.$refs.invoiceTable.invoices.length === 0) {
        await alert('Для платежа нет счета. Нужно сначала счет создать.');
        return;
      }
      let sberId =  onlyMail ? this.editOrder.sberId : '@pay-id@';
      await this.sendNewOrderMail(this.editOrder, sberId, onlyMail ? null : this.doSberPay)
    },

    async doSberPay(mail) {
      await this.saveOrder();

      let invoiceNumber = this.$refs.invoiceTable.invoices.length > 0
          ? this.$refs.invoiceTable.invoices[ 0 ].number
          : '';
      let data = {
        metaorderId: this.editOrder.id,
        invoiceNumber,
        fromId: this.invoiceFrom?.id,
        clientId: this.editOrder.client?.id,
        toId: this.toId?.id
      };

      let result = await this.$store.dispatch('metaorders/doSberPay', data);

      mail.body = mail.body.replace('@pay-id@', result.sberId);
      this.turnWatch(false);
      this.editOrder.sberId = result.sberId;
      this.editOrder.sberTryCount = result.sberTryCount;
      this.editOrder.rowVersion = result.rowVersion;
      this.turnWatch(true);
    },

    async loadSberInfo(sberId) {
      try {
        this.sberInfo = sberId
            ? await this.$store.dispatch('metaorders/getSberInfo', JSON.stringify(this.editOrder.sberId))
            : {};
      } catch (ex) {
        this.sberInfo = {};
      }
    },

    async onInvoiceTableSaveOrder(callback) {
      await this.saveOrder();
      await callback();
    },

    onInvoiceTablePay() {
      this.reloadOrder();
    },

    async doAccountPay() {
      await this.saveOrder();
      try {
        await this.$store.dispatch('metaorders/payFromAccount', this.editOrder.id);
        this.reloadOrder();
      } catch (ex) {
        alert("Ошибка при списании денег в заказ: " + ex.message);
      }
    },
  },

  computed: {
    payTypeIsDisabled() {
      if (this.editOrder.payType === 'fromAccount') {
        return false;
      }

      return this.editOrder.isStock ||
             this.editOrder.currency !== 'rub' ||
             (this.editOrder.payState && this.editOrder.payState !== 'notPaid') ||
             this.editOrder.invoiceRls?.some(rl => !rl.forDelete);

    },

    findDeliveryForInvoice() {
      let dlvs = this.deliveries.filter(d => d.type === 'simple' && (d.state === 'inCompile' || d.state === 'readyToSend'));
      return dlvs.length > 0 ? dlvs[ 0 ] : null;
    },

    oldInvoices() {
      return this.editOrder.orders
      .map(ord => !ord.oldJson ? '' : JSON.parse(ord.oldJson).invoice)
      .filter(inv => !!inv)
      .join(', ');
    },

    oldPayDate() {
      return this.editOrder.orders
      .map(ord => !ord.oldJson ? '' : JSON.parse(ord.oldJson).payDate)
      .filter(date => !!date)
      .join(', ');
    },

    accountPayDisableText() {
      let client = this.editOrder.client;
      if (!client) {
        return "У заказа рабочая группа не выбрана."
      }
      if (client.currentBalance - (this.editOrder.ordersSum + this.editOrder.deliverySum) < -client.maxAccountDebt) {
        return "На счету рабочей группы недостаточно средств."
      }
      return "";
    },

    invoiceQuery() {
      return [
        { fieldName: '@metaorderRls.metaorderId', op: 'eq', values: [this.editOrder.id] },
        { isOr: false, fieldName: 'isSber', op: 'eq', values: [this.editOrder.payType === 'fromSber'] }
      ];
    },

    acctPayLogSum() {
      let filtered = this.editOrder.payLog.filter(pl => pl.type === 'toOrderFromAccount');
      return -filtered.reduce((acc, pl) => acc + pl.sum, 0) || 0;
    },

    acctPayLogDate() {
      return this.acctPayLogFirst?.createdAt;
    },

    acctPayLogManager() {
      return this.acctPayLogFirst?.managerName;
    },

    acctPayLogFirst() {
      let filtered = this.editOrder.payLog.filter(pl => pl.type === 'toOrderFromAccount');
      return filtered.length > 0
          ? filtered[ 0 ]
          : null;
    },
  }
}
</script>

<style scoped>

</style>
