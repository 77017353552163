<script>
import _ from 'lodash';

export default {
  name: 'changeMonitor',
  props: ['name', 'items'],
  render: () => null,
  mounted() {
    this.$wsHub.$on('changes', this.onChange)
  },
  async beforeDestroy() {
    this.$wsHub.$off('changes', this.onChange)
  },

  methods: {
    async onChange(data) {
      let dataForMe = this.filterDataForMe(data);
      if (dataForMe.length === 0) {
        return;
      }
      let result = await this.loadItems(dataForMe);
      this.$emit('change', result);
    },

    async loadItems(data) {

      let promArray = data.map(async({ type, actions }) => {
        let idsForRequest = [...new Set([...(actions.find(a => a.action === 'insert')?.ids || []),
                                          ...(actions.find(a => a.action === 'update')?.ids || [])])];
        let items = await this.doRequest(type, idsForRequest);
        let result = this.sortLoadedItemsToResult(type, items, idsForRequest);

        let forDelete = actions.find(a => a.action === 'delete')?.ids;
        if (forDelete?.length) {
          result.delete.push(...forDelete);
        }
        return [type, result];
      });
      return Object.fromEntries(await Promise.all(promArray));
    },

    filterDataForMe(data) {
      return Object.entries(data)
      .filter( ([type]) => this.items[ type ])
      .map(([type, actions]) => ({
        type,
        actions: Object.entries(actions)
        .map(([action, ids]) => ({
          action,
          ids: ((a) => {
            switch ( a ) {
              case 'insert':
                return this.items[ type ]?.actions.includes(a) ? ids : [];
              case 'delete': {
                if (!this.items[ type ]?.actions.includes(a)) {
                  return []
                }
                return this.items[ type ].noFilterDeleted
                    ? ids
                    : ids.filter(id => this.items[ type ].ids.includes(id))
              }
              case 'update': {
                if (!this.items[ type ]?.actions.includes(a)) {
                  return [];
                }
                return this.items[ type ]?.insertExist     //Пропускает элементы которых сейчас нет в списке
                    ? ids
                    : ids.filter(id => this.items[ type ].ids.includes(id))
              }
            }
          })(action)
        })).filter(action => action.ids.length > 0)
      })).filter(el => el.actions.length > 0);
    },

    sortLoadedItemsToResult(type, items, idsForRequest) {
      let monitoringItem = this.items[ type ];
      return {
        insert: items.filter(i => monitoringItem.ids.indexOf(i.id) < 0),
        update: items.filter(i => monitoringItem.ids.indexOf(i.id) >= 0),
        delete: idsForRequest.filter(id => items.every(i => i.id !== id))
      }
    },

    async doRequest(type, idsForRequest) {
      let settings = this.items[ type ];

      if (!idsForRequest?.length || settings.withoutData) {
        return [];
      }
      let response;
      if (typeof(settings.dispatch) === 'function') {
        response = await settings.dispatch();
        return Array.isArray(response) ? response : [response];
      }
      let query = [{ isOr: false, fieldName: 'id', op: 'in', values: idsForRequest }];
      if (settings.query && settings.query.length > 0) {
        query.push({ isOr: false, fieldName: '', op: 'part', values: settings.query });
      }
      let dispatchName = settings.dispatch || `${this.buildDispatchName(type)}/loadItems`;
      response = await this.$store.dispatch(dispatchName, { query, withIndicator: false });
      return Array.isArray(response) ? response : [response];
    },

    buildDispatchName(name) {
      switch ( name[ name.length - 1 ] ) {
        case 's':
          return name + 'es';
        case 'y':
          return name.slice(0, -1) + 'ies';
        default:
          return name + 's';
      }
    },


    prepareList(data, list, listType, addUpdateFields) {
      let idsForAnimation = [];
      if (data[ listType ]) {
        ({ list, idsForAnimation } = this.prepareTable(data[ listType ], list));
      }

      if (addUpdateFields) {
        idsForAnimation.push(...this.prepareCommonFieldsUpdate(data, list, addUpdateFields))
      }

      if (idsForAnimation.length > 0) {
        this.$nextTick(() => {
          let elements = idsForAnimation
          .map(id => this.$parent.$refs[ id.ref ]?.closest(id.target))
          .filter(el => el);
          this.animateElements(elements);
        });
      }
      return list;
    },

    prepareTable(changes, list) {
      let idsForAnimation = [];
      if (changes.insert.length > 0) {
        list.push(...changes.insert);
        idsForAnimation.push(...changes.insert.map(o => ({ ref: `anchorColumn_${o.id}`, target: 'tr' })));
      }
      if (changes.delete.length > 0) {
        list = list.filter(o => !changes.delete.includes(o.id));
      }
      if (changes.update.length > 0) {
        changes.update.forEach(o => {
          let idx = list.findIndex(el => el.id === o.id);
          if (idx >= 0)
            list.splice(idx, 1, o);
        });
        idsForAnimation.push(...changes.update.map(o => ({ ref: `anchorColumn_${o.id}`, target: 'tr' })));
      }
      return { list, idsForAnimation };
    },

    prepareCommonFieldsUpdate(data, list, fields) {
      let idsForAnimation = [];
      fields.forEach(field => {
        if (data[ field.name ]?.update?.length) {
          data[ field.name ].update.forEach(newObj =>
                                                list.filter(obj => _.get(obj, field.path + 'Id') === newObj.id)
                                                .forEach(obj => {
                                                  _.set(obj, field.path, newObj);
                                                  idsForAnimation.push({ ref: `${field.refName || field.name}_${obj.id}`, target: 'td' });
                                                })
          );
        }
      });
      return idsForAnimation;
    },

    animateElements(elements) {
      elements.forEach((el) => {
                         let saveColor = window.getComputedStyle(el, null).getPropertyValue('background-color');
                         el.animate(
                             [{
                               backgroundColor: "#b8e8a1"
                             },
                               { backgroundColor: saveColor }
                             ], 15000
                         );
                       }
      )
    }
  }
}
</script>

<style scoped>

</style>
