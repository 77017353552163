import vue from 'vue';

export default function buildClientStore(store){
    store.actions.getPaylogForClient = async (context, params) => await  vue.prototype.$myHttp.post('/api/clients/getPaylogForClient', params);
    store.actions.addPaylogCorrection = async (context, params) => await vue.prototype.$myHttp.post('/api/clients/addPaylogCorrection', params);
    store.actions.getDeliveryServiceAccount = async (context, params) => await  vue.prototype.$myHttp.post('/api/clients/getDeliveryServiceAccount', params);
    store.actions.loadItemsXls = async (context, params) => await  vue.prototype.$myHttp.post('/api/clients/findClientsXlsx', params);
    store.actions.moneyReportSum = async (context, params) => await  vue.prototype.$myHttp.post('/api/clients/moneyReportSum', params);
    store.actions.getClientInvoiceBalance = async (context, params) => await  vue.prototype.$myHttp.post('/api/clients/getClientInvoiceBalance', params);
    return store;
}
