<template>
    <component-window
            :title="`Редактирование образца`"
            :lock-manager="lockManager"
            @close="onBtnCancelClick" >

        <div style="display: flex;flex-direction: column;height: 100%">
            <el-form :rules="rules" :model="fragment"  ref="editForm" label-width="100px" style="flex: 0 0 auto;">
                <el-form-item label="Название" prop="name">
                    <el-input v-model="fragment.name"></el-input>
                </el-form-item>
                <el-form-item label="На пробирке" prop="tubeTitle">
                    <el-input v-model="fragment.tubeTitle"></el-input>
                </el-form-item>

                <el-form-item v-if="fragment.primersRls.length === 0">
                    <el-radio-group  v-model="fragment.action" size="mini" @change="fragment.action = $event">
                        <el-radio-button label="sequence" >Сиквенс</el-radio-button>
                        <el-radio-button label="fragmentAnalyze" >Фрагментный анализ</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item v-if="fragment.action === 'sequence'" label="Тип" prop="type">
                    <el-select
                            v-model="fragment.type"
                            value-key="name"
                            :checkbox="false"
                            @change="onFragmentTypeChange"
                            :value="fragment.type ? fragment.type.name: ''"
                            clearable>
                        <template>
                        <el-option
                                v-for="type in fragmentTypes"
                                :key="type.id"
                                :label="type.name"
                                :value="type"/>
                        </template>
                    </el-select>
                </el-form-item>
              <el-form-item v-if="fragment.action === 'sequence'" label="ПЦР" prop="type">
                <el-switch v-model = "fragment.withPcr"></el-switch>
              </el-form-item>

              <el-form-item label="Дополнительно" v-if="fragment.action === 'sequence'">
                    <el-dropdown trigger="click" @command="fragment.option=$event">
                        <el-button type="primary">
                            {{$getEnumValue('FragmentOptionsEnum',fragment.option)}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in $getEnum('FragmentOptionsEnum').filter( i => i.value !== fragment.option)"   :command="item.value" :key="item.value">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>
                <el-form-item label="Материал">
                    <el-dropdown trigger="click" @command="fragment.actionAfterEnd=$event">
                        <el-button type="primary">
                            {{$getEnumValue('ActionAfterEndEnum',fragment.actionAfterEnd)}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="item in $getEnum('ActionAfterEndEnum').filter( i => i.value !== fragment.actionAfterEnd )"   :command="item.value" :key="item.value">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-form-item>


                <el-form-item label="Описание">
                    <el-input v-model="fragment.description"  type="textarea" :rows="7"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <template slot="footer">
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
            <my-el-button :disabled="false" @click="onBtnSaveClick" type="success">Применить</my-el-button>
        </template>
    </component-window>
</template>

<script>

    export default {
        name: 'FragmentEditor',
        props: [ 'allFragments', 'fragment', 'onClose' ],
        data() {
            let validateName = async(rule, value, callback) => {
                this.allFragments.some(f =>  f.id !== this.fragment.id && !f.forDelete && f.name.toLowerCase().replace(/ /g,'') === value.toLowerCase().replace(/ /g,''))
                    ? callback(new Error('Название уже есть.'))
                    : callback();
            };


            return {
                acFragmentTypeValue: '',
                fragmentTypes: [],
                lockManager: null,
                windowSize: {minHeight: 540, minWidth: 400, height: 540, width: '500'},
                rules: {
                    name: [ this.$validateRuleRequired, { validator: validateName } ],
                    type: [ this.$validateRuleRequired ],
                }
            };
        },

        async mounted() {
            this.fragmentTypes = await  this.$store.dispatch('sequenceTypes/loadItems', {});
            this.acFragmentTypeValue = this.fragment.type ? this.fragment.type.name : '';
        },

        methods: {
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit('close');
            },

            async onBtnSaveClick() {
                try {
                    await this.$refs.editForm.validate()
                } catch (ex) {
                    return;
                }

                this.onClose(this.fragment);
                this.$emit('close');
            },

            async onAutocompleteFragmentTypeSearch(queryString, callback) {
                const result = this.fragmentTypes.filter(f => f.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
                callback(result);
            },

            onFragmentTypeChange() {
                this.fragment.typeId = this.fragment.type ? this.fragment.type.id : null;
            },
        },

        computed: {}
    }
</script>

<style>


</style>
