import _ from "lodash";
import addressEditor from '@/components/usersClientsAffiliation/AddressEditor';
import { store } from '@/store';
import moment from "moment";

let checkDurationInMs = 1 * 1000 * 60;
export default {
    async install (Vue) {
        Vue.prototype.$windowUid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,(c,r)=>('x'==c?(r=Math.random()*16|0):(r&0x3|0x8)).toString(16));
        Vue.prototype.$moment = moment;
        Vue.prototype.$loadCommonData = async function() {
            await Promise.all([
                                  store.dispatch('loadSettings'),
                                  store.dispatch('loadOwnerCompanies'),
                                  store.dispatch('prices/getSequencePrice'),
                                  store.dispatch('prices/getPrimerPrices'),
                                  store.dispatch('prices/getModifiersPrices'),
                                  store.dispatch('loadEnums'),
                                  store.dispatch('zonds/loadAndSetItems', { pageSize: 0 }),
                                  store.dispatch('loadOurAffiliations')
                              ]);
            let checkNewVersion = async () => {
                let response;
                try {
                    response = await Vue.prototype.$myHttp.get(`/version.app?timestamp=${new Date().getTime()}`);
                } catch {
                    setTimeout(checkNewVersion, checkDurationInMs);
                    return;
                }
                if (response.data && response.data !== process.env.VUE_APP_VERSION ) {
                    let ntf = this.$notify({
                                               title: "Обновление приложения",
                                               type: 'warning',
                                               dangerouslyUseHTMLString: true,
                                               position: 'bottom-right',
                                               message: `<b style="cursor: pointer">Приложение обновлено. Нажатие на это сообщение приведет к перезагрузке страницы.<br/>v: ${response.data}</b>`,
                                               duration: 0,
                                               onClick: async () => {
                                                   document.location.reload();
                                                   ntf.close();
                                               },
                                               onClose: () => {setTimeout(checkNewVersion, checkDurationInMs)}
                                           });
                } else {
                    setTimeout(checkNewVersion, checkDurationInMs);
                }
            }
            setTimeout(checkNewVersion, checkDurationInMs);
            store.state.shared.appIsInit = true;
        };
        
        Vue.prototype.$g = function(varPath, scope = null, defaultValue = null) {
            return _.get(scope || this, varPath, defaultValue);
        };
    
        Vue.prototype.$addressToString = function(address, split = false) {
            return !split
                     ? addressEditor.methods.addressToString(address)
                     : addressEditor.methods.addressToSplitString(address);
        };
    
        let handler = {
            get: (target, name) => {
                if (store.state.shared.settings && !store.state.shared.settings.setDataPath) {
                    Vue.set(store.state.shared.settings, 'dataPath', localStorage[ 'dataPath' ] || 'z:');
    
                    store.state.shared.settings.setDataPath =
                        newPath => {
                            store.state.shared.settings.dataPath = newPath;
                            localStorage.setItem('dataPath', newPath)
                        }
                }
                return  store.state.shared.settings ? store.state.shared.settings[name] : '';
            }
        };
    
        Vue.prototype.$settings = new Proxy(store, handler);
    
        Vue.prototype.$validateRuleRequired = {
            required: true,
            validator: (rule, value, callback) => {
                value = value?.toString().trim() || "";
                if (value.length === 0) {
                    return callback(new Error('Поле должно быть заполнено'));
                }
                callback();
            }
        };
        
        Vue.prototype.$validateRuleNumber = function(canZero, canNegative, canDecimal = true, canEmpty = false, withoutMessage = false){
            let pattern = '^';
            pattern += canNegative ? '(-|\\+)?' : '\\+?';
            pattern += canDecimal ? '[0-9]*\\.?[0-9]+' : '[0-9]+';
            pattern += '$';
            let rx = new RegExp(pattern);
            return {
                validator: (rule, value, callback) => {
                    value = value === 0 ? 0 : (value||'').toString().trim();
                    if (!value && canEmpty) {
                        callback();
                        return;
                    }
                    
                    if (!value && value * 1 !== 0 && !canEmpty) {
                        return callback(new Error(withoutMessage ? ' ' : 'Поле должно быть заполнено'));
                    }
                    if ( !rx.test(value) || (!canZero && value * 1 === 0)) {
                        callback(withoutMessage ? ' ' : 'Недопустимое значение');
                    } else {
                        callback();
                    }
                }
            }
        };

        Vue.prototype.$format = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 });
        
        Vue.prototype.$roundFmt = function(src) {
              return (Math.round((src * 100) || 0 ) / 100).toLocaleString().replace(',','.');
        }
        
        Vue.prototype.$calcOrderProductSum = function(row, discount = 0) {
            if (row.handPrice || row.handPrice === 0 ) {
                return row.handPrice * row.countAll;
            }
            discount = discount || 0;
            let fullPrice = row.price - Math.round(row.price / 100 * discount * 100) / 100
            let partPrice =  Math.round(row.price / 2 * 100) / 100;
            partPrice = partPrice - Math.round(partPrice / 100 * discount * 100) / 100;
        
            return partPrice * row.countDiscount + fullPrice * (row.countAll - row.countDiscount);
        }
        
        Vue.prototype.$findOurAffiliationByInvoiceNumber = function(invoiceNumber) {
          invoiceNumber = invoiceNumber.toLowerCase() ;
          return store.state.shared.ourAffiliations
           .find(aff => {
                return aff.invoicePrefixes &&
                aff.invoicePrefixes.split(/[ ;,]/).filter(pfx => !!pfx.trim()).some(pfx => invoiceNumber.startsWith(pfx.toLowerCase()))}
           );
        }
    }
}
