<template>
  <div style="display:flex;height: 100%;width: 100%">
    <el-table :data="templates"
              style="font-size: 16px"
              height="100%"
              :border="true"
              :default-sort="{prop: 'createdAt', order: 'descending'}"
              v-ls-saver:mailTbl
    >
      <el-table-column label="Название" width="300">
        <my-link slot-scope="scope" @click="onBtnEditClick(scope.row.id)" :name="scope.row.name"></my-link>
      </el-table-column>
      <el-table-column label="Назначение">
        <template slot-scope="scope">{{ getPurpose(scope.row.types) }}</template>
      </el-table-column>

      <el-table-column label="Действия" width="65">
        <template slot-scope="scope">
          <my-el-button delete @click="onBtnDeleteClick(scope.row)"/>
        </template>
        <template v-slot:header>
          <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">
            <my-el-button plus @click="onBtnEditClick(0)"></my-el-button>
          </div>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>
<script>
import tableSaver from '@/utils/tableSaver';
import trumbowygWrapper from '@/components/common/trumbowygWrapper';
import { confirm } from "@/components/common/dialogs/dialogUtils";


let editShortTemplateWindow = {
  name: 'editShortTemplateWindow',
  template: `
    <component-window
        title="Добавление/изменение шаблона"
        ref="compWindow1"
        :maximize="false"
        @close="onBtnCancelClick">
    <div style="display:flex;flex-direction: column; width:100%;height:100% ">
      <el-form label-position="top" ref="form" :model="result" :rules="{name: [ $validateRuleRequired ], types: [ $validateRuleRequired ], template:[ $validateRuleRequired ]}">
        <el-form-item label="Название" prop="name">
          <el-input v-model="result.name"></el-input>
        </el-form-item>
        <el-form-item label="Использовать для" prop="types" label-position="top">
          <el-checkbox-group v-model="proxyTypes">
            <el-checkbox label="pdt">Продукты</el-checkbox>
            <el-checkbox label="prm">Праймеры</el-checkbox>
            <el-checkbox label="seq">Сиквенс</el-checkbox>
            <el-checkbox label="srv">Сервисы</el-checkbox>
            <el-checkbox label="delivery">Доставка</el-checkbox>
            <el-checkbox label="com">Зарубежные</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div style="display:flex;flex-grow: 1;flex-shrink: 1">
        <trumbowyg-wrapper v-model="result.template"></trumbowyg-wrapper>
      </div>
    </div>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSave" type="success">Сохранить</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['onClose', 'id'],
  components: { trumbowygWrapper },

  data() {
    return {
      proxy: [],
      result: { id: 0, types: '', template: '', name: '' },
      windowSize: { minHeight: 400, minWidth: 700, height: '400', width: '700' }
    }
  },
  async mounted() {
    if (this.id) {
      this.result = await this.$store.dispatch('mailShortTemplates/loadItem', this.id)
    }
  },

  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },
    async onBtnSave() {
      try {
        await this.$refs.form.validate()
      } catch (ex) {
        return;
      }
      try {
        this.result = await this.$store.dispatch('mailShortTemplates/saveItem', this.result)
      } catch (e) {
        await alert('Ошибка сохранения: ' + e);
        return;
      }

      await this.onClose(this.result);
      this.$emit('close');
    }
  },

  computed: {
    proxyTypes: {
      get() {
        return this.result.types.split(',').filter(s => s);
      },
      set(newValue) {
        this.result.types = newValue.join(',');
      }
    }
  }
};

export default {
  name: "MailTable",
  props: {
    query: { default: null },
    mailId: { default: null }
  },
  mixins: [tableSaver],
  data() {
    return {
      templates: [],
    }
  },

  async mounted() {
    this.templates = this.$getEnum('mailShortTemplates');
  },

  methods: {
    async onBtnEditClick(id) {
      let template = await this.$showWindowAsync(editShortTemplateWindow, { id });
      if (template) {
        await this.$store.dispatch('loadEnumValues', 'mailShortTemplates')
        this.templates = this.$getEnum('mailShortTemplates');
      }
    },
    async onBtnDeleteClick(row) {
      if (!await confirm('Удалить шаблон?')) {
        return;
      }
      await this.$store.dispatch('mailShortTemplates/deleteItem', row);
      await this.$store.dispatch('loadEnumValues', 'mailShortTemplates')
      this.templates = this.$getEnum('mailShortTemplates');
    },
    getPurpose(src) {
      if (!src.trim()) return '';
      
      let titles = new Map([
                             ['pdt', 'Продукты'],
                             ['prm', 'Праймеры'],
                             ['seq', 'Сиквенс'],
                             ['srv', 'Сервисы'],
                             ['delivery', 'Доставка'],
                             ['com', 'Зарубежные']
                           ])
      return src.split(',').map(s => titles.get(s)).join(',  ');
    }
  },
}
</script>