<template>
    <div style="display: flex" :style="activeRow ? 'width: 100%;padding-left: 10px': ''">
        <el-input v-if="activeRow" v-model="fieldValue"  readonly >
            <i slot="prefix" class="el-input__icon el-icon-d-arrow-left" style="color:red" @click="applyChanges"></i>
            <i slot="suffix" class="el-input__icon el-icon-circle-close" style="color:red" @click="clearOldValue"></i>
        </el-input>
        <el-popover v-else-if="preparedRows && preparedRows.length>0" placement="top-start" trigger="hover">
            <div v-for="row in preparedRows" :key="row.id" >{{row.value}}</div>
            <i  class="el-icon-info" slot="reference" style="margin-left:10px; cursor: pointer;font-size: 12px;color:#409EFF"></i>
        </el-popover>
    </div>
</template>

<script>
    import _ from 'lodash';
    export default {
        name: 'changeField',
        props: ['fieldName', 'changes'],
        computed: {
            activeRow() {
                return this.changes 
                    ? _.maxBy(this.changes.filter( ch => ch.fieldName.toLowerCase() === this.fieldName.toLowerCase() && !ch.processed && !ch.forDelete), ch => ch.id)
                    : null;
            },
            fieldValue() {
               return this.activeRow ? this.activeRow.value: ''; 
            },
            preparedRows() {
                return  this.changes 
                          ? _.orderBy(this.changes.filter( ch => ch.fieldName.toLowerCase() === this.fieldName.toLowerCase() && ch.processed && !ch.forDelete), ['id'], ['des'] )
                          : [];
            }
        },
        methods: {
            clearOldValue() {
                this.activeRow && (this.activeRow.processed = true);
            },
            applyChanges() {
                this.$emit('apply-change', {fieldName: this.fieldName, value: this.activeRow.value});
                this.activeRow.forDelete = true;
            }
        }
    }
    
    
</script>

<style scoped>

</style>