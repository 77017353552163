<template>
  <component-window
      :title="title"
      :lock-manager="null"
      @close="onBtnCancelClick">


    <el-tabs style="display:flex;flex-direction: column;width: 100%; height:99%">
      <el-tab-pane label="Элементы" style="justify-content: stretch;display:flex;height: 100%">
        <el-container style="height: 100%">
      <el-aside style="width: auto;height: 100%">
        <el-container style="height: 100%;">
          <el-header style="height: auto">
            <plate-viewer :edit-plate="editPlate" :plate-map="plateMap" view-only @cell-click="onCellClick"></plate-viewer>
            <div class="cell" v-if="editPlate && editPlate.noArchiveUntil" style="display: flex;flex-wrap: nowrap;justify-content: flex-end;margin-top: 10px">
              <div style="padding-right: 5px">Файлы будут убраты в архив</div> <my-date :date="editPlate.noArchiveUntil" />
            </div>
            <div  class="cell" v-if="editPlate && editPlate.isArchive"  style="display: flex;flex-wrap: nowrap;justify-content: flex-end;margin-top: 10px">
              <my-el-button type="success" @click="restoreArchiveFiles">Достать файлы плашки из архива</my-el-button>
            </div>
          </el-header>
          <el-main style="display: flex; flex-direction: column;width:420px;padding: 0;margin-top: 20px">
            <file-table
                :files="editPlate ? editPlate.images : []"
                is-images
                :file-type="['plate']"
                @add-files="savePlate"
                @delete-file="savePlate"
            />
          </el-main>

        </el-container>
      </el-aside>
      <el-main style="padding: 0">
        <el-table :data="editPlate ? editPlate.elements: []"
                  style="font-size: 16px;width:99%"
                  height="100%"
                  :border="true"
                  ref="elTable"
                  highlight-current-row
                  @current-change="onSelectRow"
                  :default-sort="{prop: 'sequence.name', order: 'descending'}"
                  :row-class-name="rowClassName"
                  v-ls-saver:plateElementsTbl
        >
          <el-table-column label="Заказ" :resizable="true" width="150px" sortable property="orderInfo.number">
            <template slot-scope="scope">
              <my-order-number
                  :order-info="scope.row.orderInfo"
              />
            </template>
          </el-table-column>
          <el-table-column label="Образец" min-width="120" property="sequence.name" sortable></el-table-column>
          <el-table-column label="Тип" width="150" property="sequence.type.name" sortable></el-table-column>
          <el-table-column label="Праймер" min-width="120" property="primer.name" sortable></el-table-column>
          <el-table-column label="A:1" width="70" property="platePoint" class-name="position" sortable></el-table-column>
        </el-table>
      </el-main>
    </el-container>
      </el-tab-pane>
      <el-tab-pane label="История" style="justify-content: stretch;display:flex;height: 100%">
        <el-table v-if="editPlate"
                  :data="editPlate.stateLog"
                  :default-sort="{prop: 'id', order: 'descending'}"
                  style="width: 100%;font-size: 16px"
                  height="100%"
                  :border="true"
                  v-ls-saver:history
        >
          <el-table-column label="Дата" width="100" :resizable="true">
            <template slot-scope="scope">
              <my-date :date="scope.row.createdAt"></my-date>
            </template>
          </el-table-column>
          <el-table-column label="Было" width="200px" :resizable="true">
            <template slot-scope="scope">
              <span>{{ $getEnumValue('PlateStateEnum', scope.row.oldState) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Стало" width="200px" :resizable="true">
            <template slot-scope="scope">
              <span>{{ $getEnumValue('PlateStateEnum', scope.row.newState) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Оператор" :resizable="true" prop="managerName" width="200px"/>
          <el-table-column label="Комментарий" :resizable="true" prop="comment"/>
        </el-table>
      </el-tab-pane>
    </el-tabs>



    <template slot="footer">
      <div style="display: flex;justify-content: stretch;flex-grow: 1">
        <div style="display: flex;justify-content: flex-start;flex-grow: 1;align-items: center">
          <my-el-button v-if="editPlate && editPlate.state === 'done'" @click="onBtnReturnInProgress" type="warning">Вернуть в производство</my-el-button>
          <el-switch v-if="editPlate"
                     v-model="editPlate.ignoreDeadline"
                     @change="onChangeIgnoreDeadline"
                     style="margin-left: 25px; margin-right: 5px"
                     active-text="Не оповещать о состоянии плашки"
          ></el-switch>
        </div>
        <div style="display: flex">
          <my-el-button @click="onBtnCancelClick" type="success">Закрыть</my-el-button>
        </div>
      </div>
    </template>
  </component-window>
</template>

<script>
import _ from 'lodash';
import PlateViewer from "./PlateViewer";
import { alertWithLog, alert, confirm, promptDate } from '@/components/common/dialogs/dialogUtils';
import tableSaver from '@/utils/tableSaver';
import fileTable from '@/components/common/fileTable';

import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import Vue from 'vue';

Vue.use(Viewer);


let plateSize = {
  width: 12,
  height: 8,
};

export default {
  name: 'SequencePlateEditor',
  components: { PlateViewer, fileTable },
  mixins: [tableSaver],
  props: ['id', 'number', 'selectedId', 'onClose'],
  data() {
    return {
      editPlate: null,
      plateChars: 'ABCDEFGH',
      plateMap: Array(plateSize.height).fill(null).map(() => Array(plateSize.width).fill(null)),
      windowSize: { minHeight: 400, minWidth: 400, height: '80%', width: '1100' },
      images: [],
      imageExist: false,
    }
  },

  async mounted() {
    await this.loadPlate();
  },

  methods: {
    async onChangeIgnoreDeadline() {
      await this.savePlate();
    },

    async restoreArchiveFiles() {
      let result = await promptDate('Не убирать в архив до', new Date());
      if (result.cancel) {
        return;
      }
      result = await this.$store.dispatch('sequencePlates/restoreArchiveFiles', {plateId: this.id, date: result.date} );
      this.prepareLoadResult(result);
    },

    onBtnCancelClick() {
      this.onClose(null);
      this.$emit("close");
    },
    async loadPlate() {
      let result = await this.$store.dispatch('sequencePlates/loadItem', this.id);
      this.prepareLoadResult(result);
    },

    prepareLoadResult(result) {
      result.elements.forEach(r => r.selected = false);
      this.editPlate = result;

      if (this.selectedId) {
        let el = _.find(this.editPlate.elements, e => e.id === this.selectedId);
        el.selected = true;
        this.setCurrentRow(el);
      }

      this.plateMap.forEach(row => {
        for ( let i = 0; i < plateSize.width; i++ ) {
          row.splice(i, 1, null);
        }
      });

      this.editPlate.elements.forEach(record => {
        let [row, col] = this.pointToRowCol(record.platePoint);
        this.plateMap[ row ].splice(col, 1, record);
      });
    },

    async savePlate() {
      try {
        let data = _.cloneDeep(this.editPlate);
        data.elements = [];
        data = await this.$store.dispatch('sequencePlates/saveItem', data);
        this.editPlate.images = data.images;
      } catch (ex) {
        await alert('Ошибка сохранения плашки.');
      }
    },

    pointToRowCol(point) {
      return [this.plateChars.indexOf(point[ 0 ]),
        1 * point.substring(1) - 1];
    },

    onSelectRow(val) {
      if (val) {
        this.editPlate.elements.forEach(e => e.selected = false);
        val.selected = true;
      }
    },

    onCellClick(elementId) {
      let el = _.find(this.editPlate.elements, e => e.id === elementId);
      el.selected = !el.selected;
      this.setCurrentRow(el.selected ? el : null);
    },

    setCurrentRow(el) {
      this.$refs.elTable.setCurrentRow(el);
      if (!el) {
        return;
      }
      this.$nextTick(() => {
        let row = this.$refs.elTable.$el.querySelector('.current-row');
        this.scrollIfNeed(row);
      });
    },

    scrollIfNeed(element_to_show) {
      let scrolling_parent = this.$refs.elTable.$el.querySelector('.el-table__body-wrapper');
      let top = parseInt(scrolling_parent.getBoundingClientRect().top);
      let bot = parseInt(scrolling_parent.getBoundingClientRect().bottom);

      let now_top = parseInt(element_to_show.getBoundingClientRect().top);
      let now_bot = parseInt(element_to_show.getBoundingClientRect().bottom);

      let scroll_by = 0;
      if (now_top < top)
        scroll_by = -(top - now_top);
      else if (now_bot > bot)
        scroll_by = now_bot - bot;
      if (scroll_by !== 0) {
        scrolling_parent.scrollTop += scroll_by;
      }
    },

    async onBtnReturnInProgress() {
      if (!await confirm('Плашка будет возвращена в работу. Некоторые заказы изменят состояние. Продолжить?')) {
        return;
      }

      try {
        await this.$store.dispatch('sequencePlates/returnPlateToInProgress', this.id);
      } catch (ex) {
        await alertWithLog("Не удалось изменить состояние плашки.", ex.message);
      }
      this.editPlate.state = 'inProgress';
      this.editPlate.doneAt = null;
      this.onClose(this.editPlate);
      this.$emit("close", this.editPlate);
    },

    rowClassName({ row }) {
      return row.state === 'error'
          ? 'error-row'
          : '';
    },

  },

  computed: {
    title() {
      return !this.editPlate
          ? ''
          : `Плашка ${this.editPlate.number} - ${this.$getEnumValue('PlateStateEnum', this.editPlate.state)}`;
    }
  }
}
</script>
<style>
.error-row {
  background-color: #e6b9754f !important; /*#ffdad0!important; ; */
}
</style>
