
import { seqOrderSummary } from "@/utils/orders";

export let seqData = [
    {
        key: 'sequence',
        name: 'Секвенирование'
    },
    {
        key: 'fragmentAnalyze',
        name: 'Фрагментный анализ',
    },
    {
        key: 'dnaExtract',
        name: 'Выделение геномной ДНК'
    },
    {
        key: 'pcr',
        name: 'ПЦР'
    }
];

export async function createInvoiceElements(metaorder, invoiceId, vue) {
    let workers = {
        sst: buildPrimerData,
        mst: buildPrimerData,
        pdt: buildProductData,
        seq: buildSeqData,
        srv: buildServData,
        ngs: buildNgsData
    };
    let elements = metaorder.orders
    .reduce((acc, o) => acc.concat(workers[ o.type ](o, vue, metaorder.currency)), [])
    .map(row =>({
      ...row,
      invoiceId
    }));
    if (metaorder.deliverySum) {
        let dlvRls = await vue.$store.dispatch('deliveries/findDeliveriesPartsForMetaorder', metaorder.orders.map(o => o.id));
        elements.push(...dlvRls.map( rl => ({
            order: metaorder.orders.find(o => o.id === rl.orderId),
            orderId: rl.orderId,
            ...createDeliveryRow(rl.price, rl.id)
        }) ));
    }
    
    return elements;
}

function buildPrimerData(order, vue, currency) {
    let result = buildPrimerCharRows(order, currency, vue);
    result.push(...calcIUAndOptions(order, vue, currency));
    if (order.type === 'mst') {
        result.push(...calcModifiers(order, vue, currency));
    }
    result.forEach(row => row.sum = row.discountPrice * row.count);
    return result.map(row => ({...row, order, orderId: order.id}))

}

const scales = new Map([
    [0.04, '40 нмоль'],
    [0.2, '200 нмоль'],
    [1, ' 1 мкмоль']]
)

function declOfNum(n) {
    const text_forms = ['нуклеотид','нуклеотида', 'нуклеотидов'];
    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

function buildPrimerCharRows(order, currency, vue) {
    const prices = vue.$store.state.prices.primerPrices;

    let catPrefix = prices[0.04]['char'].cat;
    return order.elements
        .map(row => ({
            scale: row.scale,
            length: row.sequence.replace(/i|u/ig, '').length
        }))
        .reduce((acc, row) => {
            let fnd = acc.find(r => r.scale === row.scale && r.length === row.length)
            if (!fnd) {
                fnd = {scale: row.scale, length: row.length, count: 0};
                acc.push(fnd);
            }
            fnd.count++;
            return acc;
        }, [])
        .map(row => {
            let price = prices[row.scale]['char'].prices[currency] * row.length;
            return createInvoiceRow(
                `prm:char:${row.scale}:${row.length}`,
                `${catPrefix}${row.scale * 1000}-${row.length}`,
                `Олигонуклеотид синтетический, ${row.length} ${declOfNum(row.length)}, ${scales.get(row.scale)}`,
                '',
                price,
                price - Math.round((price / 100 * order.discountPercent) * 100) / 100,
                row.count)
        });
}

export let syntData = [
    { key: 'inosine', name: 'Инозин', selectFn: str => [...str.toLowerCase()].filter(char => char === 'i').length },
    { key: 'deoxyuridine', name: 'Дезоксиуридин', selectFn: str => [...str.toLowerCase()].filter(char => char === 'u').length },
    { key: 'cleaning', name: 'Очистка', field: 'optionsCleaning' },
    { key: 'keen', name: 'Кинирование', field: 'optionsKeen' },
    { key: 'residementation', name: 'Переосаждение', field: 'optionsResidiment' }
];

function calcIUAndOptions(order, vue, currency) {
    return order.elements
        .reduce((acc, row) => {
            for (const option of syntData) {
                let resultRow = null;
                if (option.selectFn) {
                    let optionCount = option.selectFn(row.sequence);
                    if (optionCount > 0) {
                        resultRow = {option, scale: row.scale, count: optionCount};
                    }
                } else {
                    if (row[option.field]) {
                        resultRow = {option, scale: row.scale, count: 1};
                    }
                }
                if (!resultRow) {
                    continue;
                }
                let fndRow = acc.find(row => row.option.key === resultRow.option.key && row.scale === resultRow.scale);
                if (fndRow) {
                    fndRow.count += resultRow.count;
                } else {
                    acc.push(resultRow)
                }
            }
            return acc;
        }, [])
        .map(row => createSyntRow(row.option.key, row.scale, row.count, vue, currency, order.discountPercent ));
}

function calcModifiers(order, vue, currency) {
    let result = order.elements
        .reduce((acc, pr) => {
                return pr.primerModifierRls.reduce((acc, rl) => {
                    let position = getModifierPosition(rl.position, pr.sequence.length + pr.primerModifierRls.length);
                    let key = `modifier:${rl.modifierFk}:${position.name}:${pr.scale}`
                    let fndRow = acc.find(r => r.sourceKey === key);
                    if (!fndRow) {
                        fndRow = createModifierRow(rl.modifier, position, pr.scale, 0, vue, currency);
                        acc.push(fndRow);
                    }
                    fndRow.count ++;
                    return acc;
                }, acc)
            }, []);
    return result;
}

// eslint-disable-next-line no-unused-vars
function getModifierPosition(modPosition, primerLength) {
    return modPosition === 0
        ? 'left'
        : (modPosition === primerLength - 1
            ? 'right'
            : 'inside');
}

export function buildProductData(source, vue, forStockInvoice = false) {
    let elements = forStockInvoice
          ? source
          : source.elements;
    let order = forStockInvoice ? null : source;
    return elements.map(row =>({
                                  order: order || row.order,
                                  orderId: (order || row.order).id,
                                  countAll: forStockInvoice ? row.countAll : undefined,        // Эти три поля нужны только при создании стокового инвойса
                                  countStock: forStockInvoice ? row.countStock : undefined,    // в базу они не попадают
                                  saveCountAll: forStockInvoice ? row.countAll : undefined,    //
                                  orderProductId: row.id,
                                  ...createProductRow(row.product,
                                                   forStockInvoice ? 0 : row.countAll,
                                                   selectPrice(order  || row.order, row, forStockInvoice),
                                                   selectDiscountPrice(order || row.order, row, forStockInvoice),
                                                   selectDiscountPrice(order || row.order, row, forStockInvoice) * row.countAll
                                  )}));
}

export function buildNgsData(order) {
    return order.elements.map(row =>({
        order: order || row.order,
        orderId: (order || row.order).id,
        orderNgsCatalogId: row.id,
        sourceKey : `ngs:${row.cat}`,
        cat : row.ngsCatalog.cat,
        name : row.ngsCatalog.name,
        enName: '',
        count : row.count,
        price : row.handPrice === null || row.handPrice === undefined ? row.price : row.handPrice,
        discountPrice : row.handPrice === null || row.handPrice === undefined ? row.price : row.handPrice,
        sum: row.count * (row.handPrice === null || row.handPrice === undefined ? row.price : row.handPrice)
    }));
}

let calcDsc = function(val, order) {
    return val - Math.round(val / 100 * order.discountPercent* 100) / 100
};

let calcFpPrice = function(row, price, discountPrice) {
    let sum =  price * (row.countAll - row.countDiscount) + discountPrice * row.countDiscount;
    return Math.round( sum / row.countAll *100) / 100;   // т.к. в инвойсе нужна цена за еденицу
};

function selectPrice(order, row, forStock){
    let price = (forStock && !row.price)
        ? priceInCurrrency(row.product, order.metaorder.currency)
        : row.price;
    return calcFpPrice(row, price, price/2);
}

function selectDiscountPrice(order, row, forStock) {
    return row.handPrice || row.handPrice === 0
        ? row.handPrice
        : calcDsc(selectPrice(order, row, forStock), order);
}

function priceInCurrrency(product, currency) {
    switch (currency) {
        case 'usd' : return product.priceUsd;
        case 'eur' : return product.priceEuro;
        default: return product.price;
    }
}

export function buildServData(order) {
    return [{
        order,
        orderId: order.id,
        countAll: undefined,      // Эти два поля нужны только при создании стокового инвойса
        countStock: undefined,    // в базу они не попадают
        ...createInvoiceRow('serv', 'SRV', `Сервисный заказ ${order.number}`, '', order.sum, order.sum, 1)
     }];
}

function buildSeqData(order, vue, currency) {
    return seqOrderSummary(order)
        .filter(el => el.sum )
        .map(el => createSequenceRow(el.key,
                                     el.count,
                                     vue,
                                     el.price,
                                     order.useHandPrice && order.handPrice === 0 ? 0 : el.discountPrice,
                                     currency))
        .map(row => ({...row, order, orderId: order.id}));
}

export function createModifierRow(modifier, positionName, scale, count, vue, currency) {
    let priceKey = `${modifier.id}:${positionName}:${scale}`
    let priceObj = vue.$store.state.prices.modifierPrices[priceKey];
    let positions = new Map([
        ['left','- 5`'],
        ['right','- 3`'],
        ['inside','inside']
    ]);

    return createInvoiceRow(
        `modifier:${priceKey}`,
        priceObj?.cat || '',
        `Модификатор ${positions.get(positionName)} ${modifier.name}, ${scales.get(scale)}`,
        '',
        priceObj?.prices[currency] || 0,
        priceObj?.prices[currency] || 0,
        count
    );
}

export function createSyntRow(key, scale, count, vue,  currency, discountPercent) {
    let priceObj = vue.$store.state.prices.primerPrices[scale.toString().replace(',', '.')][key];
    let price = priceObj.prices[currency];
    return createInvoiceRow(
        `prm:${key}:${scale}`,
         priceObj.cat,
        `${syntData.find(s => s.key === key).name} ${scales.get(scale)}`,
        '',
         price,
        price - Math.round((price / 100 * discountPercent) * 100) / 100,
        count
    );
}

export function createSequenceRow(key, count, vue, price = null, discountPrice = null, currency) {
    let name = vue.$getEnumValue('SeqPriceElementsEnum', key);
    let prmPrice = price === null ? vue.$store.state.prices.sequencePrices[ key ].prices[currency] : price;
    return createInvoiceRow(
        `seq:${key}`,
        vue.$store.state.prices.sequencePrices[ key ].cat,
        name,
        '',
        prmPrice,
        discountPrice === null ? prmPrice : discountPrice,
        count
    );
}

export function createProductRow(product, count, price, discountPrice, sum) {
    return createInvoiceRow(
        `product:${product.cat}`,
        product.cat,
        product.name,
        product.enName,
        price,
        discountPrice,
        count,
        sum
    );
}

export function createDeliveryRow(price, deliveryRlId = null) {
    return createInvoiceRow(
        `delivery${deliveryRlId ? `:${deliveryRlId}` : ''}` ,
        'deliveryCatNumber',
        'Оплата доставки',
        'payment for delivery services',
         price,
         price,
         1,
         price
    );
}

export function createInvoiceRow(sourceKey, cat, name, enName, price, discountPrice, count, sum = null) {
    return {
        sourceKey,
        cat,
        name,
        enName,
        count,
        price,
        discountPrice,
        sum: sum === null ? count * discountPrice : sum
    };
}






