<template>
    <component-window
            :title="title"
            :lock-manager="null"
            @close="onBtnCancelClick"    >
        <div class="height-input" style="display: flex;flex-direction: column; height:100%; width:100%;">
            <div style="margin-bottom: 3px" v-html="placeholder"></div>
            <lined-textarea 
                    v-model="text"></lined-textarea>
        </div>
        <template slot="footer">
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
            <my-el-button :disabled="!text" @click="onBtnSaveClick" type="success">Ok</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import LinedTextarea from "./linedTextArea";
    export default {
        name: 'TextEditor',
        components: { LinedTextarea },
        props: [ 'title', 'placeholder', 'onClose' ],
        data() {
            return { 
                text: '',
                field: 'test',
                windowSize: {minHeight: 400, minWidth: 400, height: '80%', width: '900'}
            }
        },
        mounted() {
            
        },

        methods: {
            setContent(content) {
              this.text = content;  
            },
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit("close");
            },
            async onBtnSaveClick() {
                if ( await this.onClose(this.text, this.setContent) ) {
                    this.$emit("close", this.text);
                }
            },
        }
    }
</script>
<style>
    .height-input .el-textarea__inner {
        height:100%
    }
</style>