<template>
    <component-window
            title="Новый пароль"
            @close="onBtnCancelClick"
    >
        <el-form status-icon :rules="rules" label-width="120px" :model="password" ref="form">
            <el-form-item label="Пароль" prop="password" >
                <el-input v-model="password.password"  ></el-input>
            </el-form-item>
            <el-form-item label="Еще раз" prop="password1"  >
                <el-input v-model="password.password1"  ></el-input>
            </el-form-item>
            <el-form-item label="Послать письмо"   >
              <el-switch v-model="password.needMail"></el-switch>
            </el-form-item>

        </el-form>
            <template slot="footer">
                    <my-el-button @click="onBtnCancelClick" type="warning" align="right">Отменить</my-el-button>
                    <my-el-button @click="onBtnSaveClick" type="success">Сохранить</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import _ from "lodash";

    export default {
        name: 'ChangePassword',
        props: [ 'onClose' ],
        data() {
            let validatePassword = async(rule, value, callback) => {
                if (this.password.password === this.password.password1) {
                    callback();
                    return;
                }
                let otherFieldName = rule.field === 'password' ? 'password1' : 'password';
                let otherField = _.find(this.$refs.form.fields, f => f.prop === otherFieldName);
                otherField.error = 'Пароли не совпадают';
                callback(new Error('Пароли не совпадают'))
            };
    
            return {
                windowSize: { minHeight: 230, minWidth: 200, height: '230px', width: '300px' },
                error: '',
                password: {
                    password: '',
                    password1: '',
                    needMail: false,
                },
                rules: {
                    password: [ this.$validateRuleRequired, { validator: validatePassword } ],
                    password1: [ this.$validateRuleRequired, { validator: validatePassword } ]
                }
            };
        },
        methods: {
            onBtnCancelClick() {
                this.onClose && this.onClose(null);
                this.$emit('close', null);
            },
            async onBtnSaveClick() {
                try {
                    await this.$refs.form.validate()
                } catch (ex) {
                    return;
                }
                let result = {password: this.password.password, needMail: this.password.needMail};
                this.onClose(result);
                this.$emit('close', result);
            }
        },
    }
</script>

<style>
</style>