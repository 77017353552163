<template functional>
  <el-popover trigger="hover">
    Сихронизируется с 1С <span v-if="!props.showBasesInLabel">{{$options.showBases(props)}}</span>
     <el-tag size="mini" class="one-c-label" slot="reference">1C <span v-if="props.showBasesInLabel">{{$options.showBases(props)}}</span></el-tag>
  </el-popover>
</template>

<script>
    export default {
       name: "oneCLabel",
       props: {
         evrogen: {default: false},
         nomotech: {default: false},
         showBasesInLabel: {type: Boolean, default: false}
      },
       showBases(props){
         let arr =[];
         if (props?.evrogen) arr.push('Евроген');
         if (props?.nomotech) arr.push('Номотек');
         return arr.length > 0
           ? `(${arr.join(', ')})`
           : '';  
       }
    }
</script>

<style scoped>
.one-c-label {
  height: 16px;
  padding: 0 2px 0 2px;
  line-height: 14px;
  font-size: 11px;
  border-radius: 46px;
}
</style>