<template>
    <component-window
            :title="`Выбор лота для ${catInfo.cat}`"
            @close="onBtnCancelClick"
    >
        <div style="display: flex; flex-direction: column; height:100%">
            <div style="padding-bottom: 10px">
                Кат: <b>{{catInfo.cat}}</b>&nbsp;&nbsp;
                Требуется: <b>{{count - selectedCount}}</b>&nbsp;&nbsp;
                Лот: <b>{{ selectedLot }}</b>

            </div>

            <el-table
                    :data="lotsView"
                    style="width: 100%;font-size: 16px"
                    width="100%"
                    height="100%"
                    :row-class-name="tableRowClassName"
                    :border="true"
            >
                <el-table-column label="Lot" width="150">
                    <template slot-scope="scope">
                        <template>
                            {{scope.row.lotNumber}}
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Доступно" width="100">
                    <template slot-scope="scope" v-if="!scope.row.isAdditional">
                        {{ getFreeCount(scope.row)}}
                    </template>
                </el-table-column>
                <el-table-column label="Место на складе">
                    <template slot-scope="scope">
                        <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
                            <div>{{scope.row.name}}</div>
                            <div>
                            <el-tag style="margin-left: 20px" v-if="scope.row.isAdditional">Доп. место хранения</el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="220">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isAdditional" style="display: flex; flex-wrap: nowrap">
                            <el-input-number
                                    :value="getCount(scope.row)"
                                    @input="setCount(scope.row, $event)"
                                    :min="0"
                                    :max="getMaxCount(scope.row)"
                                    :disabled="rowIsDisabled(scope.row)"
                                    style="width: 100px"
                            ></el-input-number>
                            <my-el-button :disabled="rowIsDisabled(scope.row) || getMaxCount(scope.row) === getCount(scope.row) " arrow popover="Взять сколько можно"
                                          @click="onBtnGetAll(scope.row)" type="success"></my-el-button>
                            <my-el-button :disabled="rowIsDisabled(scope.row) || getCount(scope.row) === 0" arrow-l popover="Вернуть обратно"
                                          @click="setCount(scope.row, 0)" type="warning"/>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template slot="footer">
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
            <my-el-button :disabled="count > selectedCount" @click="onBtnSelectClick()" type="success">Выбрать</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import _ from 'lodash';
    
    export default {
        name: 'productLotSelector',
        props: ['catInfo', 'count', 'onClose'],
        data() {
            return {
                windowSize: { minHeight: 100, minWidth: 400, height: '400', width: '850' },
                selectedRows: []
            };
        },
        computed: {
            lotsView() {
                let result =
                    this.catInfo.lots.reduce((acc, lot) => {
                        acc.push(...lot.storePlaces.map(sp => {
                            sp.lotNumber = lot.lotNumber;
                            return sp
                        }));
                        return acc;
                    }, []);
                return _.orderBy(result, ["lotNumber", "name"], ["asc", "asc"])
            },
            
            selectedLot() {
                return this.selectedRows.length === 0 ? 'не выбран' : this.selectedRows[ 0 ].lotNumber;
            },
            selectedStore() {
                return this.selectedRows.length === 0 ? null : this.selectedRows[ 0 ].storeId;
            },
            
            selectedCount() {
                return this.selectedRows.reduce((a, r) => a + r.count, 0);
            }
        },
        methods: {
            fndSelRow(row) {
                return _.find(this.selectedRows, r => r.id === row.id && !row.isAdditional);
            },
            getCount(row) {
                let fndRow = this.fndSelRow(row);
                return fndRow ? fndRow.count : 0;
            },
            setCount(row, value) {
                let fndRow = this.fndSelRow(row);
                if (value * 1 === 0) {
                    if (fndRow) {
                        let idx = _.findIndex(this.selectedRows, r => r.id === fndRow.id);
                        this.selectedRows.splice(idx, 1);
                        if (!this.selectedRows.some(lot => !lot.isAdditional && lot.lotNumber === fndRow.lotNumber )) {
                          this.selectedRows = this.selectedRows.filter(lot => lot.lotNumber !== fndRow.lotNumber);
                        }
                    }
                    return;
                }
                if (fndRow) {
                    fndRow.count = value * 1;
                } else {
                    this.selectedRows.push({
                                               id: row.id,
                                               lotNumber: row.lotNumber,
                                               count: value * 1,
                                               storeId: row.storeId,
                                               storeName: row.name,
                                               isAdditional: false
                                           });
                    
                    let addLots = this.lotsView.filter(lv => lv.lotNumber === row.lotNumber && lv.isAdditional);
                    //Для некоторых товаров существуют пустые лоты которые несут информацию только о месте хранения
                    //если такие лоты есть то их добавляем один комплект addЛотов на все записи нормального лота 
                    if (addLots.length > 0 && !this.selectedRows.some(lot => lot.lotNumber === row.lotNumber && lot.isAdditional )  ) {
                        this.selectedRows.push(...addLots.map(lot => (
                            {
                                id: lot.id,
                                lotNumber: lot.lotNumber,
                                count: 0,
                                storeId: lot.storeId,
                                storeName: lot.name,
                                isAdditional: true
                            }
                        )));
                    }
                }
            },
            
            getFreeCount(row) {
                let fndRow = this.fndSelRow(row);
                return row.count - (fndRow ? fndRow.count : 0);
            },
            
            getMaxCount(row) {
                let fndRow = this.fndSelRow(row);
                let maxByAll = this.count - this.selectedCount;
                let maxByFree = this.getFreeCount(row);
                
                return (maxByAll < maxByFree ? maxByAll : maxByFree) + (fndRow ? fndRow.count : 0);
            },
            
            onBtnGetAll(row) {
                this.setCount(row, this.getMaxCount(row));
            },
            
            rowIsDisabled() {
                return false; // this.selectedStore && (row.storeId !== this.selectedStore /*|| row.lotNumber !== this.selectedLot */)
            },
            
            tableRowClassName({ row }) {
                if (this.rowIsDisabled(row)) {
                    return 'product-lot-disabled-row';
                }
                return this.fndSelRow(row) ? 'product-lot-selected-row' : '';
            },
            
            async onBtnSelectClick() {
                if (await this.onClose(this.selectedRows)) {
                    this.$emit('close');
                }
            },
            
            onBtnCancelClick() {
                this.$emit('close');
                this.onClose && this.onClose(null);
            },
        }
    }
</script>

<style>
    .product-lot-selected-row {
        background-color: #ecf5ff !important;
    }

    .product-lot-disabled-row {
        background-color: #F5F7FA !important;
        color: #c0c0c0;
    }
</style>