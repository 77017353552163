import vue from 'vue';

export default function buildSequenceQueueStore(store){
    store.actions.getFilesMap = async (context, params) => await vue.prototype.$myHttp.post('/api/sequenceQueues/getFilesMap', params);
    store.actions.doPackFiles = async (context, params) => await vue.prototype.$myHttp.post('/api/sequenceQueues/doPackFiles', params);
    store.actions.returnPlateToNewState = async (context, params) => await vue.prototype.$myHttp.post('/api/sequenceQueues/returnPlateToNewState', params);
    store.actions.plateDone = async (context, params) => await vue.prototype.$myHttp.post('/api/sequenceQueues/plateDone', params);
    store.actions.saveItems = async (context, params) => await vue.prototype.$myHttp.post('/api/sequenceQueues/SaveSequenceQueues', params);
    store.actions.deleteOrCancelItems = async (context, params) => await vue.prototype.$myHttp.post('/api/sequenceQueues/DeleteOrCancelItems', params);
    return store;
}