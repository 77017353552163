<template>
  <component-window
      :title="id ? 'Редактирование рабочей группы' : 'Добавление рабочей группы'"
      lock-name="client"
      :watch-object="editClient"
      :isWatch="lockerIsWatch"
      :show-refresh-button="true"
      @click-refresh-button="loadClient(editClient.id)"
      @close="closeWindow"
      ref="compWindow"
      :maximize="!isTabView"
      :close="canClose"
  >
    <el-tabs v-model="activeTab" style="display:flex; flex-direction:column;height:99%" type="border-card">
      <el-tab-pane label="Поля" name="fields" style="overflow-y: auto;height: 100%;padding-right: 10px">
        <el-form status-icon :rules="rules" label-width="100px" :model="editClient" ref="editForm"
                 label-position="right">
          <el-form-item label="Владелец" prop="ownerCompanyId" tab="fields">
            <div style="display: flex;justify-content: space-between">
              <div style="display: flex;flex-wrap: nowrap;align-items: center ">
                <my-el-select
                    v-if="!id"
                    v-model="editClient.ownerCompanyId"
                    style="width:100px"
                    :value="editClient.ownerCompanyId"
                >
                  <el-option v-for="co in ownerCompanies"  :key="co.id" :label="co.name" :value="co.id"/>
                </my-el-select>
                <el-tag v-else-if="editClient && editClient.ownerCompany" effect="dark" size="medium" type="warning"><b>{{editClient.ownerCompany.name}}</b></el-tag>

                <el-form-item label="Страна" prop="country" tab="fields" style="margin-bottom: 0">
                  <my-el-select
                      v-model="editClient.country"
                      value-key="id"
                      :value="editClient.country"
                      @change="editClient.countryId = editClient.country.id"
                  >
                    <el-option
                        v-for="country in $getEnum('countries')"
                        :key="country.name"
                        :label="country.name"
                        :value="country">
                    </el-option>
                  </my-el-select>
                </el-form-item>
                <el-switch active-text="Физ. лицо" v-model="editClient.isPerson" style="margin-left: 20px"/>
              </div>
              <div style="display: flex;flex-wrap: nowrap;align-items: center">
                <el-switch
                    active-text="Проверен"
                    v-model="editClient.isCheck" style="margin-right: 25px;"></el-switch>
                <template v-if="editClient.id">ID: {{ editClient.id }}</template>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="Название" prop="name" tab="fields">
            <el-input v-model="editClient.name"/>
          </el-form-item>
          <el-form-item label="Краткое название" prop="shortName" tab="fields">
            <el-input v-model="editClient.shortName"/>
          </el-form-item>
          <el-form-item label="Организация" prop="affiliation" tab="fields">
            <my-selector
                type="affiliation"
                v-model="editClient.affiliation"
                placeholder=""
                buttons
                @edit="onEditAffiliation(editClient.affiliation.id)"
                @add="onEditAffiliation(0)"
                @change="editClient.affiliationId = editClient.affiliation ? editClient.affiliation.id : null"
            />
          </el-form-item>
          <el-form-item label="Важный комментарий">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editClient.importantComment">
            </el-input>
          </el-form-item>
          <el-form-item label="Комментарий">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editClient.comment">
            </el-input>
          </el-form-item>
          <el-form-item label="Скидка %" prop="simplePrimerDiscount">
            <div style="display: flex;justify-content: space-between">
              <div style="display: flex;flex-wrap: nowrap">
                SST
                <my-price-input
                    class="left-number-input"
                    style="width:80px;margin-right: 20px;margin-left: 10px"
                    v-model="editClient.simplePrimerDiscount">
                </my-price-input>
                MST
                <my-price-input
                    class="left-number-input"
                    style="width:80px;margin-right: 20px;margin-left: 10px"
                    v-model="editClient.modifiedPrimerDiscount">
                </my-price-input>
                Секвенирование
                <my-price-input
                    class="left-number-input"
                    style="width:80px;margin-right: 20px;margin-left: 10px"
                    v-model="editClient.sequenceDiscount">
                </my-price-input>

              </div>
              <div>
                Стоимость доставки
                <my-price-input
                    class="left-number-input"
                    style="width:90px;margin-left: 10px"
                    v-model="editClient.defaultDeliveryPrice">
                </my-price-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="currency" tab="fields" label="Валюта">
            <div style="display: flex; ;justify-content: space-between">
              <div style="display: flex;flex-wrap: nowrap">
                <my-el-select style="width: 100px"
                              v-model="editClient.currency"
                              value-key="name"
                              :value="editClient.currency"
                >
                  <el-option
                      v-for="currency in $getEnum('CurrencyEnum')"
                      :key="currency.value"
                      :label="currency.name"
                      :value="currency.value">
                  </el-option>
                </my-el-select>
                <el-form-item prop="defaultPrepaymentPercent" tab="fields" label="Процент предоплаты"
                              label-width="170px">
                  <el-input v-model="editClient.defaultPrepaymentPercent" style="width:70px"/>
                </el-form-item>

                <el-form-item prop="defaultTermsOfPaymentDays" tab="fields" label="Условия платежа, дней"
                              label-width="180px">
                  <el-input v-model="editClient.defaultTermsOfPaymentDays" style="width:70px"/>
                </el-form-item>
              </div>
              <div>
                Учитывать стоимость доставки
                <el-switch v-model="editClient.defaultCalcDeliveryPrice"></el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="defaultPayType" tab="fields" label="Оплата">
            <div style="display: flex">
              <my-el-select style="width: 160px"
                            v-model="editClient.defaultPayType"
                            value-key="name"
                            :value="editClient.defaultPayType"
              >
                <el-option
                    v-for="pt in $getEnum('PayTypeEnum')"
                    :key="pt.value"
                    :label="pt.name"
                    :value="pt.value">
                </el-option>
              </my-el-select>
              <el-form-item prop="maxAccountDebt" tab="fields" label="Макс. задолжность по внутреннему счету"
                            label-width="300px">
                <el-input v-model="editClient.maxAccountDebt" style="width:80px"/>
              </el-form-item>


              <el-form-item prop="maxInvoiceDebt" tab="fields" label="Макс. задолжность по безналу" label-width="250px">
                <el-input v-model="editClient.maxInvoiceDebt" style="width:80px"/>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="E-mail для сиквенсов">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editClient.mailsForSequenceResult">
            </el-input>
          </el-form-item>

        </el-form>
      </el-tab-pane>
      <el-tab-pane :disabled="!editClient.ownerCompanyId" :label="`Заказчики (${usersCount})`" name="usersTab" style="height: 100%;">
        <client-user-table
            :data="editClient.clientUserRls"
            :lockUserId="addUser ? addUser.id : null"
            :clientId="editClient.id"
            :owner-company-id="editClient.ownerCompanyId"
            @begin-edit="editUserNow = true"
            @end-edit="editUserNow = false"
        ></client-user-table>
      </el-tab-pane>
      <el-tab-pane :label="`Адреса (${editClient.addresses ? editClient.addresses.length : '0'})`" name="addresses"
                   style="height:100%">
        <el-table
            :data="addresses"
            style="width: 100%;font-size: 16px;flex-grow: 1"
            height="100%"
            :border="true">
          <el-table-column label="Адрес" :resizable="true">
            <div slot-scope="scope" style="display: flex;flex-direction: column">
              <div>
                <change-popup
                    :changes="changes"
                    :update-base="false"
                    field="Addresses"
                    :id="scope.row.id"/>
                <div class="my-link" @click="onBtnShowAddress(scope.row.id)">{{ addrToString(scope.row) }}</div>
              </div>
              <div style="display: flex" v-html="buildAddressDefaults(scope.row)"></div>
            </div>
          </el-table-column>
          <el-table-column label="" width="55">
            <template slot-scope="scope">
              <my-el-button delete @click="onBtnDeleteAddressClick(scope.row)"/>
            </template>
            <template v-slot:header>
              <div style="display: flex;align-items: center;padding: 0">
                <my-el-button plus @click="onBtnShowAddress(0)"></my-el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="`Договоры доставки`" name="deliveryServiceAccounts" style="height:100%">
        <el-table
            :data="deliveryServiceAccounts"
            style="width: 100%;font-size: 16px;flex-grow: 1"
            height="100%"
            :border="true">
          <el-table-column label="Номер договора" width="200">
            <template slot-scope="scope">
              <my-link :name="scope.row.number" @click="onBtnShowDeliveryAccount(scope.row)"></my-link>
            </template>
            <template v-slot:header>
              <div style="display: flex;align-items: center;padding: 0;justify-content: space-between">
                Номер договора
                <my-el-button plus @click="onBtnShowDeliveryAccount(null)"></my-el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Почтовая служба" width="200">
            <template slot-scope="scope">
              {{ scope.row.deliveryService.name }}
            </template>
          </el-table-column>
          <el-table-column label="Комментарий">
            <template slot-scope="scope">
              {{ scope.row.comment }}
            </template>
          </el-table-column>
          <el-table-column label="" width="55">
            <template slot-scope="scope">
              <my-el-button delete @click="onBtnDeleteDeliveryAccount(scope.row)"/>
            </template>
          </el-table-column>

        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="`Заказы (${orderCount})`" name="orders" style="height:100%;">
        <div style="display: flex;flex-direction: column;height: 100%">
          <metaorder-table
              v-if="activeTab === 'orders'"
              :with-move="true"
              :client="editClient"
              @load="onOrdersLoad"
              :query="orderQuery"
          >
            <my-el-find-input
                style="width: 220px;padding-bottom: 2px;margin-right: 20px"
                v-model="fndString"
                placeholder="№, Заказчик, Рабочая группа"
                with-clear
                @find="fndString=$event;onFindOrders()"
            />
            <my-el-select
                v-model="fndOrderTypes"
                multiple
                collapse-tags
                @change="onFindOrders"
                style="width:150px;margin-right: 20px;padding-bottom: 3px"
                placeholder="Тип заказа"
                :value="fndOrderTypes"
                clearable>
              <el-option label="MST" value="Mst"/>
              <el-option label="SST" value="Sst"/>
              <el-option label="Продукты" value="Pdt"/>
              <el-option label="Сиквенс" value="Seq"/>
              <el-option label="Сервисы" value="Srv"/>
            </my-el-select>
          </metaorder-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
          :label="`Плательщики (${editClient.billingAffiliationsRl ? editClient.billingAffiliationsRl.length : '0'})`"
          name="affiliations" style="height:100%">
        <client-affiliation-table :data="editClient.billingAffiliationsRl" :lock-record-id="lockBillingRecord"
                                  :clientId="editClient.id"></client-affiliation-table>
      </el-tab-pane>
      <el-tab-pane label="Счета" name="pays" style="height:100%">
        <invoice-table :query="invoiceQuery" @save="doSaveAndLoad" @pay-invoice="onPayInvoice" :client="editClient"
                       show-from="client" :to="editClient.affiliation"></invoice-table>
      </el-tab-pane>
      <el-tab-pane name="balance" style="height:100%">
        <el-container style="height: 100%;">
          <el-header style="height: auto;">
            <el-form>
              <el-form-item style="margin-bottom: 0">
                <div style="display: flex;flex-direction: row; justify-content: space-between">
                  <div style="display: flex">
                    <div style="display: flex;flex-direction: column; width:300px">
                      <div style="display: flex;justify-content: space-between; align-items: center">
                        <span>Балсанс, вн.счет:</span>
                        <i v-if="balanceUpdateNow" class="el-icon-loading"></i>
                        <span v-else style="margin-right: 20px">{{
                            $roundFmt(invoiceBalanceSum.currentBalance)
                          }} {{ $getEnumValue('CurrencyEnum', editClient.currency) }}</span>
                      </div>
                      <div v-if="invoiceBalanceSum"
                           style="display: flex;justify-content: space-between;  align-items: center;"><span>Баланс, безнал/карта:</span>
                        <i v-if="balanceUpdateNow" class="el-icon-loading"></i>
                        <span v-else style="margin-right: 20px">{{
                            $roundFmt(invoiceBalanceSum.orderInvoiceOnly)
                          }} {{ $getEnumValue('CurrencyEnum', editClient.currency) }}</span>
                      </div>
                    </div>
                    <my-el-button type="success" @click="showCorrectionWindow">Корректировка</my-el-button>
                    <my-el-button type="success" @click="sendMoneyToOtherClient">Передача другой рабочей группе
                    </my-el-button>
                    <my-el-button type="primary" @click="doExport('range')">Экспорт</my-el-button>
                  </div>
                  <div>

                  </div>
                </div>
              </el-form-item>
              <el-form-item v-if="invoiceBalanceSum">
                <div style="display: flex;flex-wrap: nowrap">
                  <div style="display: flex;">
                    <div
                        style="display: flex;justify-content: space-between; align-items: center; width:300px; font-size: 16px; font-weight: bold">
                      <span>Итого:</span>
                      <i v-if="balanceUpdateNow" class="el-icon-loading"></i>
                      <b v-else style="margin-right: 20px">{{ $roundFmt(invoiceBalanceSum.all) }}
                        {{ $getEnumValue('CurrencyEnum', editClient.currency) }}</b>
                    </div>
                  </div>
                  <span v-if="editClient.currentBalance + invoiceBalanceSum.orderInvoiceOnly !== invoiceBalanceSum.all"
                        style="color: red">
                      Данные не сходятся!
                    </span>
                </div>
              </el-form-item>
            </el-form>
          </el-header>
          <el-main style="height: 100%;padding: 0">
            <div style="display: flex;flex-wrap: nowrap;height: 100%">
              <pay-log-table :client-id="editClient.id" :need-reload="editClient.rowVersion"
                             @reject-correction="rejectPayCorrection"></pay-log-table>
              <pay-log-table :client-id="editClient.id" is-right="true" :need-reload="editClient.rowVersion"
                             @reject-correction="rejectPayCorrection"></pay-log-table>
            </div>
          </el-main>
        </el-container>
        <div slot="label">Баланс
          <my-client-debt-warning :client="editClient"></my-client-debt-warning>
        </div>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer" slot-scope="{lockerState}">
      <div style="display: flex;justify-content: space-between;width:100%">
        <div style="display: flex">
          <my-el-button delete
                        v-if="!!id"
                        :disabled="!!errorsForDeleteClientButton(lockerState)"
                        :dis-popover="errorsForDeleteClientButton(lockerState)"
                        @click="onBtnDeleteClick"
                        style="margin-right: 15px"></my-el-button>
          <change-button :changes="changes"/>
        </div>
        <div style="display:flex">
          <my-el-button v-if="canClose" @click="closeWindow(null)" type="warning" align="right">Отменить</my-el-button>
          <my-el-button :disabled="(!!id) && !lockerState.canEdit" @click="onBtnSaveClick(false)" type="success">
            Сохранить
          </my-el-button>
          <my-el-button
              v-if="canClose"
              :disabled="(!!id) && !lockerState.canEdit" @click="onBtnSaveClick(true)" type="success">Сохранить и
            закрыть
          </my-el-button>
        </div>
      </div>
    </template>
    <change-monitor name="invoiceMonitor" :items="monitoringItems" @change="onChanges"
                    ref="changeMonitor"></change-monitor>
  </component-window>
</template>

<script>
import {alert, confirm} from '@/components/common/dialogs/dialogUtils';
import _ from 'lodash';
import moment from 'moment-business-days';
import affiliationEditor from "@/components/usersClientsAffiliation/AffiliationEditor";
import addressEditor from '@/components/usersClientsAffiliation/AddressEditor';
import metaorderTable from "@/components/order/MetaorderTable";
import changeButton from '@/components/usersClientsAffiliation/ChangeButton';
import changePopup from '@/components/usersClientsAffiliation/ChangePopup';
import tableSaver from '@/utils/tableSaver';
import clientAffiliationTable from './ClientAffiliationTable';
import clientUserTable from './ClientUserTable'
import invoiceTable from '@/components/invoices/InvoiceTable';
import invoiceEditor from '../invoices/InvoiceEditor';
import payLogTable from '@/components/usersClientsAffiliation/PayLogTable';
import DeliveryServiceSelector from "@/components/deliveries/DeliveryServiceSelector";
import MyClientDebtWarning from "@/components/common/myClientDebtWarning";
import ChangeMonitor from "@/components/common/changeMonitor";

let editPayLogWindow = {
  name: 'editPayLogWindow',
  template: `
    <component-window
        :title="title"
        :maximize="false"
        @close="onBtnCancelClick">
    <div style="display: flex; flex-direction: column;height: 100%">
      <el-form :rules="rules" :model="result" ref="form" label-position="right" label-width="70px">
        <el-form-item v-if="action === 'move'" label="Кому" prop="client">
          <my-selector
              type="client"
              v-model="result.client"
              placeholder=""
              :buttons='false'
          />
        </el-form-item>
        <el-form-item label="Сумма" prop="sum"
                      :rules="[ { required: true }, $validateRuleNumber(false, action !== 'move', true) ]"
        >
          <my-price-input
              class="left-number-input"
              style="width:130px"
              v-model="result.sum">
          </my-price-input>
        </el-form-item>
      </el-form>
      <div style="height: 100%">
        <el-form status-icon :rules="rules" :model="result" ref="form1" label-position="left"
                 style="height:100%;display: flex;flex-direction: column">
          <el-form-item label="Комментарий" label-position="left" size="100%" prop="comment"
                        style="height:100%;display: flex;flex-direction: column">
            <el-input ref="textarea" type="textarea" autosize v-model="result.comment" style="height: 100%"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSet" type="success">Сохранить</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['onClose', 'action', 'title'],
  data() {
    return {
      result: {
        sum: 0,
        client: null,
        comment: '',
      },
      rules: {
        comment: [this.$validateRuleRequired],
        client: [this.$validateRuleRequired],
      },

      windowSize: {minHeight: 250, minWidth: 600, height: '250', width: '600'}
    }
  },
  mounted() {
    let el = this.$refs.textarea;
    el.$el.getElementsByClassName('el-textarea__inner')[0].setAttribute("style", "height:100%");
  },
  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },

    async onBtnSet() {
      let isError = false;
      try {
        await this.$refs.form.validate();
      } catch (ex) {
        isError = true;
      }
      try {
        await this.$refs.form1.validate();
      } catch (ex) {
        isError = true;
      }
      if (isError) {
        return;
      }

      this.onClose(this.result);
      this.$emit('close');
    }
  }
};

let editDeliveryAccountWindow = {
  name: 'editDeliveryAccountWindow',
  components: {DeliveryServiceSelector},
  template: `
    <component-window
        title=""
        :maximize="false"
        @close="onBtnCancelClick">
    <div style="display: flex; flex-direction: column;height: 100%">
      <el-form status-icon :model="deliveryAccount" ref="form" label-position="right" label-width="130px">
        <el-form-item label="Служба доставки" prop="deliveryService" :rules="[ { required: true }]">
          <delivery-service-selector v-model="deliveryAccount.deliveryService"></delivery-service-selector>
        </el-form-item>
        <el-form-item label="Номер" prop="number" :rules="[{ required: true }]">
          <el-input v-model="deliveryAccount.number"></el-input>
        </el-form-item>
      </el-form>
      <div style="height: 100%">
        <el-form status-icon ref="form1" label-position="left" style="height:100%;display: flex;flex-direction: column">
          <el-form-item label="Комментарий" label-position="left" size="100%" prop="comment"
                        style="height:100%;display: flex;flex-direction: column">
            <el-input ref="textarea" type="textarea" autosize v-model="deliveryAccount.comment"
                      style="height: 100%"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSet" type="success">Сохранить</my-el-button>
        </div>
      </div>
    </template>
    </component-window>
  `,
  props: ['onClose', 'deliveryAccount'],
  data() {
    return {
      windowSize: {minHeight: 250, minWidth: 600, height: '250', width: '600'}
    }
  },
  mounted() {
    let el = this.$refs.textarea;
    el.$el.getElementsByClassName('el-textarea__inner')[0].setAttribute("style", "height:100%");
  },
  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },

    async onBtnSet() {
      let isError = false;
      try {
        await this.$refs.form.validate();
      } catch (ex) {
        isError = true;
      }
      try {
        await this.$refs.form1.validate();
      } catch (ex) {
        isError = true;
      }
      if (isError) {
        return;
      }

      this.onClose(this.deliveryAccount);
      this.$emit('close');
    }
  }
};

let exportRangeWindow = {
  name: 'exportRangeWindow',
  template: `
    <component-window
        title="Фильтр экспорта"
        :maximize="false"
        @close="onBtnCancelClick">
    <div style="display: flex; flex-direction: column;height: 100%">
      <el-form status-icon :model="form" ref="form" label-width="110px">
        <el-form-item prop="range" label="Диапазон дат">
          <el-date-picker
              v-model="form.range"
              type="daterange"
              start-placeholder="С"
              end-placeholder="По"
              :editable="false"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="{firstDayOfWeek: 1}"
              style="max-width:250px;margin-right: 5px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="range" label="Записи">
          <my-el-select
              v-model="form.isChangeBalance"
              style="width:150px;padding-bottom: 3px"
              placeholder="Влияние на баланс"
              :value="form.isChangeBalance">
            <el-option label="Все" :value="null"/>
            <el-option label="Влияют на баланс" :value="true"/>
            <el-option label="Не влияют на баланс" :value="false"/>
          </my-el-select>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSelect" type="success">Экспорт</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['onClose'],
  data() {
    return {
      form: {range: null, isChangeBalance: null},
      windowSize: {minHeight: 140, minWidth: 400, height: '185', width: '400'}
    }
  },
  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },

    async onBtnSelect() {
      try {
        await this.$refs.form.validate();
      } catch (ex) {
        return;
      }
      this.onClose(this.form);
      this.$emit('close');
    }
  }
};

export default {
  name: 'ClintEditor',
  props: ['id', 'addUser', 'onClose', 'lockBillingRecord', 'tab', 'isTabView'],
  mixins: [tableSaver],
  components: {
    ChangeMonitor,
    // eslint-disable-next-line vue/no-unused-components
    MyClientDebtWarning,
    clientAffiliationTable,
    clientUserTable,
    metaorderTable,
    changeButton,
    changePopup,
    invoiceTable,
    payLogTable
  },
  data() {
    return {
      invoiceBalanceSum: {all: null, currentBalance: null, orderInvoiceOnly: null},
      invoiceBalanceAllSum: null,
      moneyReportIsLoading: false,
      moneyReportSum: null,
      editUserNow: false,
      lockerIsWatch: false,
      windowSize: {minHeight: 400, minWidth: 400, height: '90%', width: '50%'},
      activeTab: 'fields',
      changes: [],
      orderQuery: null,
      orderCount: '?',
      affiliationAutocompleteValue: '',
      objectInfo: null,
      editClient: {
        id: 0,
        objectTypeName: 'client',
        clientUserRls: [],
        affiliation: null,
        addresses: [],
        billingAffiliationsRl: [],
        deliveryServiceAccounts: [],
        defaultCalcDeliveryPrice: false,
        country: null
      },
      fndOrderTypes: [],
      fndString: '',
      editBillingAffiliation: {user: null, affiliation: null},
      balanceUpdateNow: false,

      rules: {
        affiliation: [this.$validateRuleRequired],
        name: [this.$validateRuleRequired],
        country: [this.$validateRuleRequired],
        currency: [this.$validateRuleRequired],
        defaultTermsOfPaymentDays: [this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, false)],
        defaultPrepaymentPercent: [this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, false)],
        maxInvoiceDebt: [this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, false)],
        maxAccountDebt: [this.$validateRuleRequired, this.$validateRuleNumber?.(true, true, false)],
        ownerCompanyId: [this.$validateRuleRequired]
      },
    };
  },
  watch: {
    'editClient.name': function (value) {
      this.editClient.showName = value;
    }
  },

  async mounted() {
    if (!this.id) {
      this.addUser &&
      this.editClient.clientUserRls.push({
        clientId: 0,
        id: -1,
        role: 'director',
        userId: this.addUser.id,
        user: this.addUser,
      });
    } else {
      if (!await this.loadClient(this.id)) {
        this.closeWindow();
        return;
      }
      if (this.tab) {
        this.activeTab = this.tab;
      }
      this.reloadInvoiceBalanceSum();
    }
    if (this.isTabView) {
      document.title = 'К: ' + this.editClient.name;
    }

    this.changes = await this.$store.dispatch('loadChanges', {type: 'client', id: this.id});
  },

  methods: {
    async reloadInvoiceBalanceSum() {

      this.balanceUpdateNow = true;
      try {
        this.invoiceBalanceSum = await this.$store.dispatch('clients/getClientInvoiceBalance', this.id);
      } finally {
        this.balanceUpdateNow = false;
      }
    },

    onChanges() {
      this.reloadInvoiceBalanceSum();
    },

    async downloadMoneyReport() {
      await this.$myHttp.postWithDownload('/api/clients/MoneyReportXls', {clientId: this.editClient.id}, 'clientBalance.xls');
    },

    async loadMoneyReport() {
      this.moneyReportIsLoading = true;
      try {
        this.moneyReportSum = await this.$store.dispatch('clients/moneyReportSum', {clientId: this.editClient.id});
      } catch (ex) {
        alert("Не удалось загрузить информацию :(");
      } finally {
        this.moneyReportIsLoading = false;
      }
    },

    buildAddressDefaults(address) {
      let names = {
        Prm: 'Праймеры',
        Pdt: 'Продукты',
        Seq: 'Сиквенсы'
      }
      return ['Prm', 'Pdt', 'Seq'].reduce((acc, sfx) => {
        if (address[`defaultDeliveryService${sfx}Id`]) {
          acc += `<div style="margin-left:20px">` +
              `<b>${names[sfx]}</b>:  ${this.$deliveryServiceById(address['defaultDeliveryService' + sfx + 'Id']).name}` +
              `${address['defaultDeliveryPrice' + sfx] ? (', ' + address['defaultDeliveryPrice' + sfx] + ' руб.') : ''}` +
              '</div>';
        }
        return acc;
      }, 'Доставка по умолчанию:');
    },


    closeWindow(returnValue) {
      if (this.isTabView) {
        window.close();
      } else {
        this.onClose && this.onClose(returnValue);
        this.$emit('close');
      }
    },

    onFindOrders() {
      let query = [{isOr: false, fieldName: 'ClientId', op: 'eq', values: [this.editClient.id]}];
      if (this.fndString.trim()) {
        this.fndString = this.fndString.trim();
        let rxNumber = /^(?<number>\d{6}-\d{3})(?<subnumber>-[A-Za-z]{3}(-\d{1,2})?)?$/gi;
        let matchNumber = rxNumber.exec(this.fndString);
        if (matchNumber) {
          if (matchNumber.groups.subnumber) {
            query.push({isOr: false, fieldName: '@orders.number', op: 'startsWith', values: [this.fndString]});
          } else {
            query.push({isOr: false, fieldName: 'number', op: 'eq', values: [this.fndString]});
          }
        } else {
          let subQuery = [
            {fieldName: 'number', op: 'like', values: [this.fndString]},
            {fieldName: '@orders.number', op: 'like', values: [this.fndString]},
            {fieldName: 'user.lname', op: 'startsWith', values: [this.fndString]},
            {fieldName: 'client.name', op: 'startsWith', values: [this.fndString]},
            {fieldName: 'client.affiliation.name', op: 'startsWith', values: [this.fndString]},
            {fieldName: 'billingAffiliation.name', op: 'startsWith', values: [this.fndString]}
          ];
          query.push({isOr: false, op: 'part', values: subQuery});
        }
      }
      if (this.fndOrderTypes.length > 0 && this.fndOrderTypes.length < 5) {
        query.push({IsOr: false, fieldName: '@orders.Type', op: 'in', values: this.fndOrderTypes});
      }
      this.orderQuery = query;
    },


    async onBtnDeleteDeliveryAccount(row) {
      if (await confirm("Удалить договор?")) {
        if (row.id > 0) {
          row.forDelete = true;
        } else {
          this.editClient.deliveryServiceAccounts = this.editClient.deliveryServiceAccounts.filter(d => d.id !== row.id);
        }
      }
    },

    async onBtnShowDeliveryAccount(da) {
      let deliveryAccount = da
          ? _.cloneDeep(da)
          : {
            id: this.editClient.deliveryServiceAccounts.filter(d => d.id < 0).length === 0
                ? -1
                : Math.min.apply(Math, this.editClient.deliveryServiceAccounts.map(d => d.id)) - 1,
            deliveryService: null,
            forDelete: false
          };
      deliveryAccount = await this.$showWindowAsync(editDeliveryAccountWindow, {deliveryAccount});
      if (!deliveryAccount) {
        return;
      }
      deliveryAccount.clientId = this.editClient.id;
      deliveryAccount.deliveryServiceId = deliveryAccount.deliveryService.id;
      this.editClient.deliveryServiceAccounts = this.editClient.deliveryServiceAccounts.filter(d => d.id !== deliveryAccount.id);
      this.editClient.deliveryServiceAccounts.push(deliveryAccount);
    },

    async doExport(command) {
      let result = null;
      if (command === 'range') {
        result = await this.$showWindowAsync(exportRangeWindow, {});
        if (!result) {
          return;
        }
      }

      let data = {
        clientId: this.editClient.id,
        isChangeBalance: result.isChangeBalance
      }
      if (result.range) {
        data.from = moment(result.range[0]).format('YYYY-MM-DDTHH:mm:ss');
        data.to = moment(result.range[1]).format('YYYY-MM-DDTHH:mm:ss');
      }

      await this.$myHttp.postWithDownload('/api/payLogs/export', data, this.editClient.name + '.xlsx')
    },

    async loadClient(id) {

      this.lockerIsWatch = false;
      try {
        this.editClient = await this.$store.dispatch('clients/loadItem', id);

        this.orderQuery = [{fieldName: 'ClientId', op: 'eq', values: [this.editClient.id]}];
        this.affiliationAutocompleteValue = this.editClient.affiliation ? this.editClient.affiliation.name : '';
      } catch (ex) {
        await alert(`Ошибка загрузки рабочей группы: ${ex.message}`)
        return false;
      } finally {
        this.lockerIsWatch = true;
      }
      this.invoiceBalanceSum = {currentBalance: this.editClient.currentBalance}
      return true;
    },

    onOrdersLoad(event) {
      this.orderCount = event.count;
    },

    async onBtnDeleteClick() {
      if (!await confirm('Удалить рабочую группу?', 'Удалить')) {
        return;
      }
      try {
        await this.$store.dispatch('clients/deleteItem', this.editClient)
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.closeWindow([this.editClient, true]);
    },

    async onBtnSaveClick(withClose) {
      try {
        await this.$refs.editForm.validate()
      } catch (ex) {
        let attrs = _.find(this.$refs.editForm.fields, f => f.validateState === 'error').$attrs;
        if (attrs && attrs.tab) {
          this.activeTab = attrs.tab;
        }
        if (!withClose) {
          await alert("Не удалось сохранить рабочую группу - на форме есть ошибки.");
        }
        return;
      }
      if (this.editUserNow) {
        this.activeTab = 'usersTab';
        await alert("Нужно закончить редактирование пользователя.")
        return;
      }
      if (!await this.saveClient()) {
        return;
      }

      if (withClose) {
        this.closeWindow(this.editClient);
      } else {
        await this.loadClient(this.editClient.id);
      }
    },

    async saveClient() {
      try {
        this.editClient.id = (await this.$store.dispatch('clients/saveItem', {
          client: this.editClient,
          changes: this.changes
        })).id;
      } catch (ex) {
        await alert(ex.message);
        return false;
      }
      this.$refs.compWindow.unlock();
      return true;
    },

    async onEditAffiliation(id) {
      let affiliation = await this.$showWindowAsync(affiliationEditor, {id});
      if (affiliation) {
        this.editClient.affiliation = affiliation;
        this.editClient.affiliationId = affiliation.id;
        this.affiliationAutocompleteValue = affiliation.name;
        if (this.editBillingAffiliation.affiliationId === affiliation.id) {
          this.editBillingAffiliation.affiliation = affiliation;
        }
        let fnd = _.find(this.editClient.billingAffiliationsRl, rl => rl.affiliationId === affiliation.id);
        if (fnd) {
          fnd.affiliation = affiliation;
        }
      }
    },

    onAutocompleteSelect(affiliation) {
      this.editClient.affiliation = affiliation;
      this.editClient.affiliationId = affiliation.id;
    },

    async onBtnShowAddress(id) {
      let address = await this.$showWindowAsync(addressEditor, {
        id,
        clientId: this.editClient.id,
        changes: this.changes
      });
      if (address) {
        this.lockerIsWatch = false;
        const idx = _.findIndex(this.editClient.addresses, addr => addr.id === address.id);
        if (idx < 0) {
          this.editClient.addresses.push(address);
        } else {
          this.editClient.addresses.splice(idx, 1, address);
        }
        this.changes = await this.$store.dispatch('loadChanges', {type: 'client', id: this.id});
        this.lockerIsWatch = true;
      }
    },

    async onBtnDeleteAddressClick(address) {
      if (!await confirm('Удалить адрес?')) {
        return;
      }
      address.forDelete = true;
    },

    addrToString(address) {
      return addressEditor.methods.addressToString(address);
    },

    errorsForDeleteClientButton(lockerState) {
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      if (this.editClient.clientUserRls.length > 0) {
        return 'Есть пользватели относящиеся к этой рабочей группе'
      }
      if (this.orderCount > 0) {
        return 'Есть заказы относящиеся к этой рабочей группе'
      }

      if (this.editClient.currentBalance) {
        return 'Баланс рабочей группы должен быть пуст.'
      }

      return '';
    },

    async rejectPayCorrection(payRecordId) {
      if (!await this.saveClient()) {
        return;
      }
      try {
        await this.loadClient(this.editClient.id);
      } catch (ex) {
        alert('Ошибка загрузки рабочей группы: ' + ex.message);
        return;
      }
      let response;
      try {
        response = await this.$store.dispatch('payLogs/rejectCorrection', payRecordId);
      } catch (ex) {
        alert('Ошибка записи: ' + ex.message);
        return;
      }
      this.lockerIsWatch = false;
      this.$nextTick(() => {
        this.editClient.rowVersion = response.rowVersion;
        this.editClient.currentBalance = response.balance;
        this.lockerIsWatch = true;
      });
    },

    async showCorrectionWindow() {
      let result = await this.$showWindowAsync(editPayLogWindow, {action: 'correct', title: 'Коррекция баланса'});
      if (!result) {
        return;
      }
      if (!await this.saveClient()) {
        return;
      }
      try {
        await this.loadClient(this.editClient.id);
      } catch (ex) {
        alert('Ошибка загрузки рабочей группы: ' + ex.message);
        return;
      }

      let record = {
        id: 0,
        sum: result.sum,
        createdAt: new Date(),
        clientId: this.editClient.id,
        comment: result.comment,
        type: 'correction'
      };

      let response;
      try {
        response = await this.$store.dispatch('payLogs/correction', record);
      } catch (ex) {
        alert('Ошибка записи: ' + ex.message);
        return;
      }
      this.lockerIsWatch = false;
      this.$nextTick(() => {
        this.editClient.rowVersion = response.rowVersion;
        this.editClient.currentBalance = response.balance;
        this.lockerIsWatch = true;
      });
      this.reloadInvoiceBalanceSum();
    },

    async sendMoneyToOtherClient() {
      let result = await this.$showWindowAsync(editPayLogWindow, {
        action: 'move',
        title: 'Передача денег другой рабочей группе'
      });
      if (!result) {
        return;
      }
      if (!await this.saveClient()) {
        return;
      }
      try {
        await this.loadClient(this.editClient.id);
      } catch (ex) {
        alert('Ошибка загрузки рабочей группы: ' + ex.message);
        return;
      }
      let records = [{
        id: 0,
        sum: -result.sum,
        createdAt: new Date(),
        clientId: this.editClient.id,
        relatedClientId: result.client.id,
        comment: result.comment,
        type: 'toClient'
      }, {
        id: 0,
        sum: result.sum,
        createdAt: new Date(),
        clientId: result.client.id,
        relatedClientId: this.editClient.id,
        comment: result.comment,
        type: 'fromClient'
      }];

      let response;
      try {
        response = await this.$store.dispatch('payLogs/clientToClient', records);
      } catch (ex) {
        await alert('Ошибка записи: ' + ex.message);
        return;
      }
      this.lockerIsWatch = false;
      this.$nextTick(() => {
        this.editClient.rowVersion = response.rowVersion;
        this.editClient.currentBalance = response.balance;
        this.lockerIsWatch = true;
      });
      this.reloadInvoiceBalanceSum();
    },


    async showInvoice(id) {
      await this.$showWindowAsync(invoiceEditor, {id});
    },

    async doSaveAndLoad(callback) {
      if (!await this.saveClient()) {
        return;
      }
      await this.loadClient(this.editClient.id);
      callback();
    },

    async onPayInvoice() {
      await this.loadClient(this.id);
    }
  },

  computed: {
    ownerCompanies() {
      return this.$store.state.shared.ownerCompanies;
    },
    monitoringItems() {
      return {
        changeClientFromPayment: {
          actions: ['update', 'insert', 'delete'],
          ids: [this.editClient.id],
          query: null,
          insertExist: false,
          withoutData: true
        }
      }
    },

    canClose() {
      return !(this.isTabView && !opener);
    },

    usersCount() {
      return this.editClient?.clientUserRls?.length || 0;
    },

    deliveryServiceAccounts() {
      return this.editClient?.deliveryServiceAccounts?.filter(d => !d.forDelete) || [];
    },

    addresses() {
      return this.editClient?.addresses?.filter(a => !a.forDelete) || [];
    },
    invoiceQuery() {
      return this.editClient
          ? [{fieldName: 'clientId', op: 'eq', values: [this.editClient.id]}]
          : [];
    },
  }
}
</script>

<style>
.el-autocomplete {
  width: 100%;
}

.el-tabs__content {
  height: 100%;
}

.el-form-item__content {
  height: 100%;
}

</style>
