<template>
    <div style="height: 100%">
    <router-view></router-view>
    <modals-container/>
    </div>
</template>

<script>
    import vue from 'vue';
    import router from './router';
    import vuex from "vuex";
    vue.use(vuex);
    export default {
    router,
    name: 'app',
}

</script>

<style>
html, body { height: 100%; }
body {margin: 0}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
