<template>
    <component-window
            :maximize="false"
            :title="field === 'isDeffered' ? 'Отложить заказ':'Срочный заказ' "
            @close="onBtnCancelClick"
    >
                    <el-form @submit.native.prevent="() => false" status-icon  label-width="70px" style="width:100%"  ref="editForm" :rules="rules" :model="fields">
                        <el-form-item v-if="field === 'isDeffered'" label="До" prop="date">
                            <el-date-picker 
                                    v-model="fields.date" 
                                    type="date"
                                    :picker-options="{firstDayOfWeek: 1}"
                                    placeholder="Выбрать">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="Причина" prop="reason">
                            <el-input v-model="fields.reason" @keyup.native.enter="onBtnSaveClick" ref="reasonText"/>
                        </el-form-item>
                    </el-form>
        <template slot="footer">
            <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
            <my-el-button @click="onBtnSaveClick" type="success">Сохранить</my-el-button>
        </template>
    </component-window>
</template>

<script>
    export default {
        name: 'DefferedReasonEditor',
        props: [ 'field', 'onClose' ],
      
        data() {
            return {
               windowSize: {minHeight:150, minWidth:300, height:'200',  width: '700'},
               fields: {
                   reason: '',
                   date: null,
               },
               rules: 
                   {reason:[ this.$validateRuleRequired],
                    date:[ this.$validateRuleRequired]
                   }   
            };
        },
        
        mounted() {
          this.$nextTick(() => {
              this.$refs.reasonText.$el.children[0].focus()});  
        },
    
        methods: {
            async onBtnSaveClick(e) {
                e.preventDefault();
                try {
                    await this.$refs.editForm.validate()
                } catch (ex) {
                    return false;
                }
                this.onClose && this.onClose(this.fields);
                this.$emit('close', this.fields);
                return false;
            },
        
            onBtnCancelClick() {
                this.onClose && this.onClose(null);
                this.$emit('close');
            },
        },
    }
</script>

<style >
</style>