<template>
    <div style="display: flex;flex-direction: column;height: 100%">
      <div  style="margin-top: 50px">Это служебная страница. Страшное место. Если вы не знаете зачем вы здесь - уходите скорей. Тыкание по кнопкам к страшным эффектам привести может.</div>
      <div style="margin-top: 50px"><el-select v-model="selectedType" placeholder="Каку статистику пересчитывать">
        <el-option
            v-for="t in types"
            :key = "t.id"
            :value="t"
            :label="t.name"
            ></el-option>
      </el-select>
        <my-el-button style="margin-left: 10px" :disabled="!selectedType" type="warning" @click="doRecalc">Считать</my-el-button>
    </div>
    </div>
</template>

<script>
 import {confirm, alert} from "@/components/common/dialogs/dialogUtils";

 export default {
      name: "Countries",
      props: ["editId"],
      data() {
        return {selectedType: null};
      },
      methods: {
        async doRecalc() {
          if (! await confirm(`Вы точно хотите пересчитать всю статистику для заказов типа '${this.selectedType.name}' ???`)) {
            return;
          }
          let startTime = new Date();
          try {

            let result = await this.$myHttp.post('/api/utils/RecalcOrderStatByType', this.selectedType.value)
            await alert(`Ну вроде как-то отработало: ${result}<br/>Старт: ${startTime}<br/>Стоп:  ${new Date()} `);
          } catch (ex) {
            await alert(`Ну вот, чо то наелось. ${ex.message}<br/>Старт:  ${startTime}<br/>Ошибка: ${new Date()} `);
          }
        }
      },
      computed: {
        types() {
          return this.$getEnum('OrderTypeEnum').filter(t => !['sst','mst','order'].includes(t.value));
        }
      }
    }
</script>

<style>
</style>
