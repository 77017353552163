<template functional>
  <el-popover
      v-if="props.client && (props.client.currentBalance < -props.client.maxAccountDebt * 0.9)"
      placement="top-start" trigger="hover">
    <div style="display: flex;flex-direction: column">
      <div style="display: flex;flex-direction: column">
        {{ props.client.currentBalance < -props.client.maxAccountDebt ? 'Долг по депозиту!' : 'Проверьте остаток на депозите!' }}
      </div>
    </div>
    <i class="el-icon-warning"
       slot="reference"
       style="cursor: pointer;font-size: 12px;color:red;margin-right: 2px"
       :style="{color: props.client.currentBalance < -props.client.maxAccountDebt ? 'red': '#E6A23C'}"
    ></i>
  </el-popover>
</template>

<script>
export default {
  name: "myClientDebtWarning",
  props: {
    client: { default: null }
  }
}
</script>
