<template>
    <el-input
            v-bind="$attrs"
            v-on="$listeners"
            v-model="value"
            @input.native="$emit('change',value)"
            @keyup.enter.native="$emit('find', value)"
    >
        <slot/>
        <i v-if="withClear && value.trim()" slot="suffix" @click="value=''; $emit('find','');$emit('change',value)" class="el-input__icon el-icon-close"></i>
        <i v-if="withFind" slot="suffix" @click="$emit('find', value)" class="el-input__icon el-icon-search" ></i>
        <i v-if="withPlus" slot="suffix" @click="$emit('find-plus', value)" class="el-input__icon el-icon-zoom-in"></i>
    </el-input>
</template>

<script>
    export default {
        name: "myElFindInput",
        props: {'withPlus' : Boolean, 'withClear': Boolean, 'withFind' : {type: Boolean, default: true}},
        data() {
            return {
                value: ''
            }
        },
    }
</script>

<style scoped>

</style>