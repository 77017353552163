import orderEditor from "@/components/order/OrderEditor";
import {store} from '@/store';

export default {
    install (Vue) {
        let showNotification = (data) => {
            if (data.type !== 'error' && !store.state.shared?.currentUser?.notifications.some(r => r === data.type)) {
                return;
            }
            let ntf = Vue.prototype.$notify({
                                       title: data.title,
                                       type: data.isError ? 'error' : 'success',
                                       dangerouslyUseHTMLString: true,
                                       position: 'bottom-right',
                                       message: `<b style="cursor: pointer">${data.notification}</b>`,
                                       duration: data.isError || data.noHide ? 0 : undefined,
                                       onClick: async() => {
                                           ntf.close();
                                           if (data.path) {
                                               await Vue.prototype.$router.push(data.path);
                                           } else if (data.obj) {
                                               await Vue.prototype.$showWindowAsync(orderEditor, {
                                                   id: data.obj.id,
                                                   subOrderId: data.obj.subOrderId
                                               });
                                           }
                                       }
                                   });
        };
        
        const hub = new Vue();
        hub.isConnected = false;
        Vue.prototype.$wsHub = hub;
    
        let wrk = new SharedWorker(new URL('../workers/hubWorker.js', import.meta.url));
    
        wrk.port.onmessage = function({data}) {
            switch ( data.fnName ) {
                case 'changeConnectionState' : {
                    hub.isConnected = data.data;
                    hub.$emit(data.fnName, data.data);
                    break;
                }
                case 'notification': {
                    showNotification(data.data);
                    break;
                }
                default: {
                    hub.$emit(data.fnName, data.data);
                }
            }
        };
        hub.syncJwtToken = () => wrk.port.postMessage({fnName: 'setJwtToken', data: Vue.prototype.$currentUser.getJwtToken()});
        wrk.port.start();
    
        hub.sendMessage = (fnName, data) => {
            if (!hub.isConnected) return;
            wrk.port.postMessage({fnName, data});
        };
        hub.syncJwtToken();
    }
}
