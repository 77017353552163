import vue from  'vue';

export default class LockManager {
    static get LOCK_BY_ME() {
        return 'LOCK_BY_ME';
    }
    
    static get LOCK_BY_OTHER() {
        return 'LOCK_BY_OTHER';
    }
    
    static get LOCK_FREE() {
        return 'LOCK_FREE';
    }
    
    static get LOCK_ERROR() {
        return 'LOCK_ERROR';
    }
    
    static get CHANGED() {
        return 'CHANGED';
    }
    
    constructor() {
        this.name = null;
        this.user = null;
        this.currentUser = null;
        this.id = null;
        this.state = LockManager.LOCK_FREE;
        this.changeCallback = null;
    }
    
    async init(name, id, user) {
        this.name = name;
        this.id = id;
        this.currentUser = user;
        this.user = await vue.prototype.$myHttp.post('/api/lockManager/getLockedUser', { key: this.name, id: this.id, userId: this.currentUser.id });
        
        this.state = this.user
            ? (this.currentUser.id === this.user.id ? LockManager.LOCK_BY_ME : LockManager.LOCK_BY_OTHER)
            : LockManager.LOCK_FREE;
    }
    
    async lock() {
        if (this.state === LockManager.LOCK_FREE) {
            this.user = await vue.prototype.$myHttp.post('/api/lockManager/lock', { key: this.name, id: this.id, userId: this.currentUser.id });
            this.state = this.user.id === this.currentUser.id ? LockManager.LOCK_BY_ME : LockManager.LOCK_BY_OTHER;
        }
    }
    
    async unlock(isUnloadPage) {
        if (this.state === LockManager.LOCK_BY_ME) {
            if (isUnloadPage) {
                await vue.prototype.$myHttp.postSimple('/api/lockManager/unLock', { key: this.name, id: this.id, userId: this.currentUser.id },);
            } else {
                await vue.prototype.$myHttp.post('/api/lockManager/unLock', { key: this.name, id: this.id, userId: this.currentUser.id });
            }
            this.state = LockManager.LOCK_FREE;
        }
    }
    
    async kill() {
        if (this.state === LockManager.LOCK_BY_OTHER) {
            await vue.prototype.$myHttp.post('/api/lockManager/kill', { key: this.name, id: this.id, userId: this.currentUser.id });
            this.state = LockManager.LOCK_FREE;
        }
    }
}