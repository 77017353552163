<template functional>
    <div>
        <template v-for="label in props.labels || []">
            <el-popover
                    :key="label.labelCrmText"
                    placement="top-start" trigger="hover">
                <div v-html="label.labelCrmTitle"></div>
                <el-tag slot="reference" size="mini"
                        :style="{color: 'white' ,backgroundColor: label.labelCrmColor,  border: 0, height: '18px', marginRight: '3px'}"
                        v-html="label.labelCrmText"></el-tag>
            </el-popover>
        </template>
    </div>
</template>
<script>
    export default {
        name: "Labels",
        props: ['labels']
    }
</script>

<style scoped>

</style>