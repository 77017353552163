<template functional>
    <div v-if="props.date" :style="data.staticStyle">
        <el-popover v-if="!props.hideTime && $options.moment(props.date).format('HH:mm:ss') !== '00:00:00'" trigger="hover" placement="top-end" width="100px">
            <div>{{$options.moment(props.date).format('HH:mm:ss')}}</div>
            <div slot="reference">{{$options.moment(props.date).format(props.format || 'DD.MM.YYYY')}}</div>
        </el-popover>
        <div v-else>{{$options.moment(props.date).format(props.format || 'DD.MM.YYYY')}}</div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "myDate",
        props: {
          date: { type: Date },
          format: { type: String },
          hideTime: { type: Boolean, default: false }
        },
        moment(val) {
            return moment(val);
        }
    }
</script>

<style>
    .el-popover {
        min-width: 0 !important;
    }

</style>
