<template>
  <component-window
      :title="id ? 'Редактирование заказчика' : 'Добавление заказчика'"
      lock-name="user"
      :watch-object="editUser"
      :isWatch="lockerIsWatch"
      :show-refresh-button="true"
      @click-refresh-button="loadUser(editUser.id)"
      @close="onBtnCancelClick"
      ref="compWindow"
      :maximize="!isTabView"
      :close="canClose"
  >
    <el-tabs v-model="activeTab" style="display:flex; flex-direction:column;height:99%" type="border-card">
      <el-tab-pane label="Поля" name="fields">
        <el-form status-icon :rules="rules" label-width="120px" :model="editUser" ref="editForm">
          <el-form-item label="Владелец" prop="ownerCompanyId">
            <div style="display: flex;flex-wrap: nowrap">
              <my-el-select
                  v-if="!id"
                  v-model="editUser.ownerCompanyId"
                  style="width:100px"
                  :value="editUser.ownerCompanyId"
               >
                <el-option v-for="co in ownerCompanies"  :key="co.id" :label="co.name" :value="co.id"/>
              </my-el-select>
              <el-tag v-else effect="dark" size="medium" type="warning"><b>{{editUser.ownerCompany.name}}</b></el-tag>

              <div style="display: flex;justify-content: space-between;align-items: center;flex-grow: 1">
                <el-switch style="margin-left: 15px" v-model="editUser.passwordConfirmed" active-text="e-mail подтвержден"/>
                <el-switch style="margin-left: 15px" v-model="editUser.isInner" active-text="Внутренний"/>
                <el-switch style="margin-left: 15px" v-model="editUser.isChecked" active-text="Проверен"/>
                <div style="width: 300px;text-align: right">
                  <el-tag v-if="editUser.loginState && editUser.loginState !== 'new'" type="warning"  style="margin-left: 15px">Состояние логина: {{ editUser.loginState }}
                  <span class="my-link" @click="editUser.loginState = 'new'">Переключить</span>
                  </el-tag>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="Логин" prop="login" tab="fields">
            <div style="display:flex;">
            <el-input v-model="editUser.login"/>
            <el-form-item label="Язык" style="margin-bottom: 0">
              <my-el-select style="width: 150px"
                            v-model="editUser.language"
                            value-key="name"
                            :value="editUser.language"
              >
                <el-option
                    v-for="currency in $getEnum('EnumLanguage')"
                    :key="currency.value"
                    :label="currency.name"
                    :value="currency.value">
                </el-option>
              </my-el-select>
            </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="Фамилия" prop="lName" tab="fields">
            <div style="display: flex">
              <el-input v-model="editUser.lName" style="width:100%"/>
              <change-field @apply-change="applyHistoryChanges" fieldName="lName" :changes="changes"></change-field>
            </div>
          </el-form-item>
          <el-form-item label="Имя" prop="fName">
            <div style="display: flex">
              <el-input v-model="editUser.fName" style="width: 100%"/>
              <change-field @apply-change="applyHistoryChanges" fieldName="fName" :changes="changes"></change-field>
            </div>
          </el-form-item>
          <el-form-item label="Отчество" prop="mName">
            <div style="display: flex">
              <el-input v-model="editUser.mName" style="width: 100%"/>
              <change-field @apply-change="applyHistoryChanges" fieldName="mName" :changes="changes"></change-field>
            </div>
          </el-form-item>
          <el-form-item label="Имя для письма" prop="nameForMail">
            <div style="display: flex">
              <el-input v-model="editUser.nameForMail" style="width: 100%"/>
            </div>
          </el-form-item>


          <el-form-item label="Важный комментарий">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editUser.importantComment">
            </el-input>
          </el-form-item>

          <el-form-item label="ID в RU базе" prop="ruBaseId">
            <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
              <div style="display: flex;flex-wrap: nowrap">
                <el-input v-model="editUser.ruBaseId" style="width: 100px;margin-right: 30px"/>
                ID в PD базе
                <el-input v-model="editUser.productBaseId" style="width: 100px; margin-left: 5px"/>
              </div>
              <div>
                Не ждать отправки результатов SEQ заказа
                <el-switch v-model="editUser.defaultNoWaitSequenceResult" style="margin-left: 5px"></el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="">
            <my-el-button type="success" @click="onChangePasswordClick">Сменить пароль</my-el-button>
            <my-el-button type="success"
                          :disabled="!editUser.login"
                          @click="onChangePasswordLinkClick">Отправить ссылку на смену пароля
            </my-el-button>
          </el-form-item>

        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="`Контакты (${phones.length + mails.length})`" name="contacts" style="height: 100%">
        <el-row type="flex" style="height: 100%">
          <el-col :span="12">
            <el-table :data="phones"
                      style="font-size: 16px;margin-right: 5px"
                      height="100%"
                      :border="true">
              <el-table-column label="Телефон" :resizable="true">
                <template slot-scope="scope">
                  <template v-if="editContactId !== scope.row.id">
                    <div style="display: flex;flex-wrap: nowrap">
                      <div style="width: 100%">
                        <my-link
                            :name="scope.row.contact"
                            :disabled="editContactId != null"
                            @click="contactForm.editContactValue =scope.row.contact; editContactId = scope.row.id"
                        ></my-link>
                      </div>
                      <change-field @apply-change="applyHistoryChanges" :fieldName="`contacts:(${scope.row.id})contact`" :changes="changes"></change-field>
                      <change-popup
                          :only-insert="true"
                          :changes="changes"
                          :update-base="false"
                          field="Contacts"
                          :id="scope.row.id"/>
                    </div>
                  </template>
                  <template v-else>
                    <el-form status-icon :rules="rules" label-width="0px" :model="contactForm" @validate="onContactValidate">
                      <el-form-item prop="editContactValue" style="margin:0"
                                    :rules="[ $validateRuleRequired]">
                        <el-input v-model="contactForm.editContactValue"></el-input>
                      </el-form-item>
                    </el-form>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="" :width="(editContactId || editContactId === 0) ? 110 : 55">
                <template slot-scope="scope">
                  <template v-if="editContactId !== scope.row.id">
                    <my-el-button key="btn2" delete :disabled="editContactId != null" @click="onBtnDeleteContact(scope.row)"/>
                  </template>
                  <template v-else>
                    <my-el-button key="btn3" cancel @click="onBtnCancelContactEdit(scope.row)"/>
                    <my-el-button key="btn4" check :disabled="!contactForm.isValid" @click="onBtnApplyContactEdit(scope.row)"/>
                  </template>
                </template>
                <template v-slot:header>
                  <div style="display: flex;justify-content: center">
                    <my-el-button plus
                                  :disabled="editContactId != null"
                                  @click="onBtnAddContact('phone')"></my-el-button>
                  </div>
                </template>

              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="12">
            <el-table :data="mails"
                      style="font-size: 16px;margin-left:5px"
                      height="100%"
                      :border="true">
              <el-table-column label="E-Mail" :resizable="true">
                <template slot-scope="scope">
                  <template v-if="editContactId !== scope.row.id">
                    <div style="display: flex;flex-wrap: nowrap">
                      <div style="display: flex;flex-wrap: nowrap; width: 100%">
                        <my-link
                            :name="scope.row.contact"
                            :disabled="editContactId != null"
                            @click="editContact(scope.row)"
                        ></my-link>
                        <div v-if="scope.row.forCopy" style="margin-left: 5px">(Cc)</div>
                      </div>
                      <change-field @apply-change="applyHistoryChanges" :fieldName="`contacts:(${scope.row.id})contact`" :changes="changes"></change-field>
                      <change-popup
                          :only-insert="true"
                          :changes="changes"
                          :update-base="false"
                          field="Contacts"
                          :id="scope.row.id"/>
                    </div>
                  </template>
                  <template v-else>
                    <el-form status-icon :rules="rules" label-width="0px" :model="contactForm" @validate="onContactValidate">
                      <el-form-item prop="editContactValue" style="margin:0"
                                    :rules="[ $validateRuleRequired, { type: 'email', message: 'E-mail введен с ошибкой'} ]">
                        <div style="display:flex; flex-wrap: nowrap;">
                          <el-input v-model="contactForm.editContactValue" style="margin-right: 15px"></el-input>
                          <el-checkbox style="margin-left: 3px" v-model="contactForm.forCopy" label="Cc"></el-checkbox>
                        </div>
                      </el-form-item>
                    </el-form>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="" :width="(editContactId || editContactId === 0) ? 110 : 55">
                <template slot-scope="scope">
                  <template v-if="editContactId !== scope.row.id">
                    <my-el-button delete key="btn6" :disabled="editContactId != null" @click="onBtnDeleteContact(scope.row)"/>
                  </template>
                  <template v-else>
                    <my-el-button cancel key="btn7" @click="onBtnCancelContactEdit(scope.row)"/>
                    <my-el-button check key="btn8" :disabled="!contactForm.isValid" @click="onBtnApplyContactEdit(scope.row)"/>
                  </template>
                </template>
                <template v-slot:header>
                  <div style="display: flex;justify-content: center">
                    <my-el-button plus
                                  :disabled="editContactId != null"
                                  @click="onBtnAddContact('mail')"></my-el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :disabled="!editUser.ownerCompanyId" :label="`Рабочие группы (${editUser.clientUserRls ? editUser.clientUserRls.length : '0'})`" name="clients" style="height: 100%;">
        <client-user-table
            :data="editUser.clientUserRls"
            :userId="editUser.id"
            :owner-company-id="editUser.ownerCompanyId"
            @begin-edit="editClientNow = true"
            @end-edit="editClientNow = false"
        ></client-user-table>
      </el-tab-pane>
      <el-tab-pane :label="`Адреса (${editUser.addressesRl.length})`" name="addresses" style="height: 100%;">
        <address-user-table :user="editUser"></address-user-table>
      </el-tab-pane>
      <el-tab-pane :label="`Заказы (${orderCount})`" name="orders" style="height:100%;">
        <metaorder-table
            :orderId="null"
            :user="editUser"
            @load="onOrdersLoad"
            :query="orderQuery"
        >
          <my-el-find-input
              style="width: 220px;padding-bottom: 2px;margin-right: 20px"
              v-model="fndString"
              placeholder="№, Заказчик, Рабочая группа"
              with-clear
              @find="fndString=$event;onFindOrders()"
          />
          <my-el-select
              v-model="fndOrderTypes"
              multiple
              collapse-tags
              @change="onFindOrders"
              style="width:150px;margin-right: 20px;padding-bottom: 3px"
              placeholder="Тип заказа"
              :value="fndOrderTypes"
              clearable>
            <el-option label="MST" value="Mst"/>
            <el-option label="SST" value="Sst"/>
            <el-option label="Продукты" value="Pdt"/>
            <el-option label="Сиквенс" value="Seq"/>
            <el-option label="Сервисы" value="Srv"/>
          </my-el-select>


        </metaorder-table>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer" slot-scope="{lockerState}">
      <div style="display: flex;justify-content: space-between;width:100%">
        <div style="display: flex">
          <my-el-button delete
                        v-if="!!id"
                        :disabled="!!errorsForDeleteUserButton(lockerState)"
                        :dis-popover="errorsForDeleteUserButton(lockerState)"
                        @click="onBtnDeleteClick"
                        style="margin-right: 15px"></my-el-button>
          <change-button :changes="changes"/>
        </div>
        <div style="display:flex">
          <my-el-button v-if="canClose" @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          <my-el-button :disabled=" !lockerState.canEdit" @click="onBtnSaveClick(false)" type="success">Сохранить</my-el-button>
          <my-el-button v-if="canClose" :disabled=" !lockerState.canEdit" @click="onBtnSaveClick(true)" type="success">Сохранить и закрыть</my-el-button>
        </div>
      </div>
    </template>
  </component-window>
</template>

<script>
import _ from 'lodash';
import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
import metaorderTable from "@/components/order/MetaorderTable";
import changeField from '@/components/usersClientsAffiliation/ChangeField';
import changeButton from '@/components/usersClientsAffiliation/ChangeButton';
import changePopup from '@/components/usersClientsAffiliation/ChangePopup';
import changePassword from '@/components/usersClientsAffiliation/ChangePassword';
import clientUserTable from './ClientUserTable';
import addressUserTable from './AddressUserTable';
import mailEditor from "@/components/mailsAndTemplates/MailEditor";

export default {
  name: 'UserEditor',
  props: ['id', 'onClose', 'client', 'isTabView'],
  components: { metaorderTable, changeField, changeButton, changePopup, clientUserTable, addressUserTable },
  data() {
    let validateLogin = async(rule, value, callback) => {
      if (this.editUser.loginState === 'new' && !value.trim()) {
        callback(new Error('Логин не может быть пустым'));
        return;
      }
      !value || await this.$store.dispatch('users/canAddLogin', { id: this.editUser.id, login: value, ownerId: this.editUser.ownerCompanyId })
          ? callback()
          : callback(new Error('Логин используется '))
    };

    return {
      editClientNow: false,
      lockerIsWatch: false,
      windowSize: { minHeight: 400, minWidth: 400, height: '80%', width: '900' },
      changes: [],

      activeTab: 'fields',
      orderCount: 0,
      orderQuery: null,
      editUser: { id: 0, loginState: 'new', login: '', ownerCompany:{},  contacts: [], addressesRl: [], clientUserRls: [] },
      editContactId: null,
      contactForm: {
        editContactValue: '',
        forCopy: false,
        isValid: null,
      },
      fndString: '',
      fndOrderTypes: [],
      rules: {
        login: [{ type: 'email', required: false, message: 'Логин должен быть почтовым адресом' }, { validator: validateLogin }],
        lName: [this.$validateRuleRequired],
        ownerCompanyId: [this.$validateRuleRequired],
      }
    };
  },

  async mounted() {
    if (this.id) {
      this.orderQuery = [{ fieldName: 'UserId', op: 'eq', values: [this.id || 0] }];
      if (!await this.loadUser(this.id)) {
        this.closeWindow();
        return;
      }
      await this.loadChanges(this.id);
    } else {
      if (this.client) {
        this.editUser.clientUserRls = [{
          id: -1,
          userId: 0,
          client: this.client,
          clientId: this.client.id,
          role: 'director'
        }]
      }
    }
    if (this.isTabView) {
      document.title = 'З: ' + this.editUser?.shortName;
    }
  },

  methods: {
    closeWindow() {
      if (this.isTabView) {
        window.close();
        return;
      }
      this.onClose && this.onClose(this.editUser);
      this.$emit('close', this.editUser);
    },
    onFindOrders() {
      let query = [{ fieldName: 'UserId', op: 'eq', values: [this.id || 0] }];
      if (this.fndString.trim()) {
        this.fndString = this.fndString.trim();
        let rxNumber = /^(?<number>\d{6}-\d{3})(?<subnumber>-[A-Za-z]{3}(-\d{1,2})?)?$/gi;
        let matchNumber = rxNumber.exec(this.fndString);
        if (matchNumber) {
          if (matchNumber.groups.subnumber) {
            query.push({ isOr: false, fieldName: '@orders.number', op: 'startsWith', values: [this.fndString] });
          } else {
            query.push({ isOr: false, fieldName: 'number', op: 'eq', values: [this.fndString] });
          }
        } else {
          let subQuery = [
            { fieldName: 'number', op: 'like', values: [this.fndString] },
            { fieldName: '@orders.number', op: 'like', values: [this.fndString] },
            { fieldName: 'user.lname', op: 'startsWith', values: [this.fndString] },
            { fieldName: 'client.name', op: 'startsWith', values: [this.fndString] },
            { fieldName: 'client.affiliation.name', op: 'startsWith', values: [this.fndString] },
            { fieldName: 'billingAffiliation.name', op: 'startsWith', values: [this.fndString] }
          ];
          query.push({ isOr: false, op: 'part', values: subQuery });
        }
      }
      if (this.fndOrderTypes.length > 0 && this.fndOrderTypes.length < 5) {
        query.push({ IsOr: false, fieldName: '@orders.Type', op: 'in', values: this.fndOrderTypes });
      }
      this.orderQuery = query;
    },


    editContact(row) {
      this.contactForm.editContactValue = row.contact;
      this.contactForm.forCopy = row.forCopy;
      this.editContactId = row.id;
    },

    async loadUser(id) {
      this.lockerIsWatch = false;
      try {
        this.editUser = await this.$store.dispatch('users/loadItem', id);
      } catch (ex) {
        await alert(`Ошибка получения заказчика: ${ex.message} `);
        return false;
      } finally {
        this.lockerIsWatch = true;
      }
      return true;
    },

    async loadChanges(id) {
      this.changes = await this.$store.dispatch('loadChanges', { type: 'user', id });
    },

    applyHistoryChanges({ fieldName, value }) {
      let matchId = fieldName.match(/\((\d+)\)/);
      if (matchId) {
        let contact = _.find(this.editUser.contacts, c => c.id == matchId[ 1 ]);
        contact.contact = value;
      }
      this.editUser[ fieldName ] = value;
    },

    onOrdersLoad(event) {
      this.orderCount = event.count;
    },

    async onBtnSaveClick(withClose) {
      try {
        await this.$refs.editForm.validate()
      } catch (ex) {
        let attrs = _.find(this.$refs.editForm.fields, f => f.validateState === 'error').$attrs;
        if (attrs && attrs.tab) {
          this.activeTab = attrs.tab;
        }
        if (!withClose) {
          await alert("Не удалось сохранить заказчика - на форме есть ошибки.");
        }
        return false;
      }

      if (this.editClientNow) {
        this.activeTab = 'clients';
        await alert("Нужно закончить редактирование рабочей группы.")
        return false;
      }

      //JSON ругается на закольцованые ссылки
      let data = _.cloneDeep(this.editUser);
      data.clientUserRls.forEach(clRl => clRl.client.addresses.forEach(a => a.client = null));
      data.addressesRl.forEach(rl => {
        rl.address = null;
        rl.user = null;
        rl.client = undefined;
        rl.clientId = undefined;
      });

      try {
        this.editUser = (await this.$store.dispatch('users/saveItem', { user: data, changes: this.changes }));
      } catch (ex) {
        await alert(ex.message);
        return false;
      }
      this.$refs.compWindow.unlock();
      if (withClose) {
        this.closeWindow();
      }
      return true;
    },

    async onBtnCancelClick() {
      if (this.$refs[ "compWindow" ].lockState.isEditNow
          && !await confirm("Есть несохраненные изменения. Закрыть без сохранения?")) {
        return;
      }
      this.closeWindow();
    },

    onBtnAddContact(type) {
      this.editUser.contacts.push({
                                    id: 0,
                                    type: type,
                                    contact: '',
                                    isMain: false,
                                    forCopy: false,
                                    userId: this.editUser.id,
                                    forDelete: false
                                  });
      this.editContactId = 0;
      this.contactForm.editContactValue = '';
    },

    onBtnCancelContactEdit(contact) {
      if (contact.id === 0) {
        const idx = this.editUser.contacts.indexOf(contact);
        this.editUser.contacts.splice(idx, 1);
      }
      this.contactForm.editContactValue = '';
      this.contactForm.forCopy = false;
      this.editContactId = null;
    },

    onBtnApplyContactEdit(contact) {
      if (contact.id === 0) {
        let newId = _.minBy(this.editUser.contacts, item => item.id);
        contact.id = newId.id > 0 ? -1 : (newId.id - 1);
      }
      contact.contact = this.contactForm.editContactValue;
      contact.forCopy = this.contactForm.forCopy;
      this.editContactId = null;
      this.contactForm.editContactValue = '';
      this.contactForm.forCopy = false;
    },

    async onBtnDeleteContact(contact) {
      if (!await confirm('Удалить контакт?')) {
        return;
      }
      contact.forDelete = true;
    },

    onContactValidate(fieldName, isValid) {
      this.contactForm.isValid = isValid;
    },

    contactChangeState(id) {
      let fndChange = _.find(this.changes, ch => !ch.forDelete && !ch.processed && ch.fieldName.indexOf(`Contacts:(${id})`) === 0);
      return fndChange ? fndChange.objectAction.toLowerCase() : '';
    },

    confirmNewContact(id) {
      let fndChange = _.find(this.changes, ch => ch.objectAction === 'insert' && ch.fieldName === `Contacts:(${id})`);
      fndChange.forDelete = true;
    },

    errorsForDeleteUserButton(lockerState) {
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      if (this.editUser.clientUserRls.length > 0) {
        return 'Есть рабочие группы относящиеся к этому заказчику'
      }
      if (this.orderCount > 0) {
        return 'Есть заказы относящиеся к этому заказчику'
      }
      return '';
    },

    async onBtnDeleteClick() {
      if (!await confirm('Удалить заказчика?', 'Удалить')) {
        return;
      }
      try {
        await this.$store.dispatch('users/deleteItem', this.editUser);
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.onClose && this.onClose([this.editUser, true]);
      this.$emit('close');
    },

    async onChangePasswordClick() {
      let result = await this.$showWindowAsync(changePassword, {});
      if (!result) {
        return;
      }
      this.editUser.newPassword = result.password;
      if (await this.onBtnSaveClick(false) && result.needMail) {
        let mail = this.$mailUtils.buildPasswordMail(this.editUser.login,
                                                     'https://allgene.kz/my/personal-data',
                                                     result.password,
                                                     'Учетные данные для входа allgene.kz',
                                                     this.editUser.language
        );
        await this.$showWindowAsync(mailEditor, {mail, mode: 'send', accountType: 'siteSend' });
      }
    },

    async onChangePasswordLinkClick() {
      try {
        if (await this.onBtnSaveClick(false)) {
          await this.$myHttp.post('/site/auth/restorePassword', JSON.stringify(this.editUser.login));
        } else {
          return;
        }
      } catch (ex) {
        await alert(`Не удалось отправить письмо: ${ex.message}`);
        return;
      }
      await this.loadUser(this.editUser.id);
      await alert('Письмо послато!');
    }
  },

  computed: {
    ownerCompanies() {
      return this.$store.state.shared.ownerCompanies;
    },
    canClose() {
      return !(this.isTabView && !opener);
    },
    phones() {
      return this.editUser.contacts ? this.editUser.contacts.filter(r => !r.forDelete && r.type === 'phone') : [];
    },
    mails() {
      return this.editUser.contacts ? this.editUser.contacts.filter(r => !r.forDelete && r.type === 'mail') : [];
    },
  }
}
</script>

<style>
.el-tabs__content {
  height: 100%;
}
</style>
