<template>
    <component-window
            v-if="editDelivery.id"
            title="Редактирование доставки"
            lock-name="delivery"
            :watch-object="editDelivery"
            :isWatch="lockerIsWatch"
            :show-refresh-button="true"
            @click-refresh-button="loadDelivery"
            @close="onBtnCancelClick">
        <delivery-tab
                :edit-order="null"
                :deliverys="deliverys"
                @cancel-delivery-click="onBtnCancelDelivery"
                @cancel-send-click="onBtnCancelSend"
                @save-order="saveDelivery"
                ref="deliveryTab"
        ></delivery-tab>

        <template slot="footer" slot-scope="{lockerState}">
            <my-el-button @click="onBtnCancelClick" type="warning" align="right">Отменить</my-el-button>
            <my-el-button :disabled="(!lockerState || !lockerState.canEdit)" @click="onBtnSave" type="success">Сохранить</my-el-button>
        </template>
    </component-window>
</template>

<script>
    import deliveryTab from '@/components/order/DeliveryTab';
    import { alert, confirm } from '@/components/common/dialogs/dialogUtils';


    export default {
        name: 'DeliveryEditor',
        props: [ 'id', 'onClose' ],
        components: {
            deliveryTab
        },
        data() {
            return {
                lockerIsWatch: false,
                windowSize: {minHeight:400, minWidth:400, height:'95%',  width: '70%'},
                editDelivery: {id:-1},
                deliverys: [],
                addressAutocompleteValue: '',
            };
        },

        async mounted() {
            await this.loadDelivery();
        },

        methods: {
            async loadDelivery() {
                this.lockerIsWatch = false;
                try {
                    this.editDelivery = await this.$store.dispatch('deliveries/loadItem', this.id);
                    if(this.deliverys.length > 0) {
                        this.deliverys.splice(0, 1)
                    }
                    this.deliverys.push(this.editDelivery);
                } catch (ex) {
                    console.log("Ошибка загрузки доставки", ex);
                    alert("Ошибка загрузки доставки");
                } finally {
                    this.lockerIsWatch = true;
                }
            },

            onBtnCancelClick() {
                this.onClose(false);
                this.$emit('close');
            },

            async saveDelivery(callback) {
                try {
                    await this.$store.dispatch('deliveries/saveItem', this.deliverys );
                } catch(ex) {
                    await alert(ex.message);
                    return;
                }
                await this.loadDelivery();
                callback();
            },

            async onBtnSave() {
                try {
                  await this.$store.dispatch('deliveries/saveItem', this.deliverys );
                } catch(ex) {
                  if (ex.code === 'reload') {
                    if (await confirm('Не удается сохранить доставку. Кто-то успел сохранить доставку (или заказ из доставки) быстрее. <br/><br/>Обновить доставку?')) {
                      await this.loadDelivery();
                    }
                  } else {
                      await alert(ex.message);
                  }
                  return;
                }
                this.onClose(true);
                this.$emit('close');
            },


            async onBtnCancelSend(delivery) {
              await this.doRollbackState(delivery, 'readyToSend');
            },

            async onBtnCancelDelivery(delivery) {
              let state = delivery.woDelivery ? 'readyToSend': 'send';
              await this.doRollbackState(delivery, state);
            },

            async doRollbackState(delivery, state) {

                if (! await confirm(`Вернуть доставку в состояние '${this.$getEnumValue('DeliveryStateEnum',state)}' `)) {
                    return;
                }
                try {
                    let newDelivery = await this.$store.dispatch('deliveries/setDeliveryState',
                                                                 { id: delivery.id,
                                                                   state,
                                                                   date: new Date(),
                                                                   comment: 'Возврат в предыдущее состояние'
                                                                 });
                    this.lockerIsWatch = false;
                    this.deliverys = [newDelivery];
                    this.lockerIsWatch = true;
                } catch (ex) {
                    await alert(ex.message);
                }
            },
        },
    }
</script>

<style scoped>
</style>
