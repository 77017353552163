<template>
  <el-form label-position="left" label-width="80px" @submit.native.prevent>
    <el-form-item v-if="showHandPrice" style="margin-bottom: 4px" label-width="0px">
      <div>
        Сумма руками
        <el-switch v-model="editOrder.useHandPrice" @change="onChangeHandSwitch" style="margin-left: 7px"></el-switch>
      </div>
      <my-price-input v-if="editOrder.useHandPrice" class="left-number-input" type="number" :min="0" :controls="false" v-model.number="editOrder.handPrice"
                      style="width: 100px;text-align: left"></my-price-input>

    </el-form-item>
    <template v-if="!editOrder.useHandPrice">
      <el-form-item label="Сумма" style="margin-bottom: 4px">
        <el-input
            readonly
            style="width:90px"
            :value="$roundFmt(editOrder.price)"></el-input>
      </el-form-item>
      <template v-if="editOrder.type !== 'ngs'">
        <el-form-item v-if="editOrder.discountPercent" label="Сумма со скидкой" style="margin-bottom: 4px">
          <el-input
              readonly
              style="width:90px"
              :value="$roundFmt(editOrder.discountPrice)"></el-input>
        </el-form-item>
        <el-form-item label="Скидка %:" style="margin-bottom: 0">
          <my-price-input :precision="2" class="left-number-input" type="number" :min="0" v-model.number="editOrder.discountPercent" style="width: 90px;text-align: left"></my-price-input>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="Сумма исполнителя $" style="margin-bottom: 4px">
          <my-price-input :precision="2" class="left-number-input" type="number" :min="0" v-model.number="editOrder.executorPrice" style="width: 90px;text-align: left"></my-price-input>
        </el-form-item>
      </template>
    </template>
    <el-form-item label="" style="margin-bottom: 4px;margin-top: 4px;display: flex; justify-content: flex-end;margin-right:-5px">
      <slot></slot>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "PriceBlock",
  props: ['editOrder'],
  data() {
    return { showHandPrice: false };
  },
  mounted() {
    this.showHandPrice = !['pdt', 'ngs'].includes(this.editOrder.type) || this.editOrder.useHandPrice;
  },


  methods: {
    onChangeHandSwitch(value) {
      this.editOrder.handPrice = value
          ? (this.editOrder.discountPercent ? this.editOrder.discountPrice : this.editOrder.price)
          : 0;
    }
  }
}
</script>

<style scoped>

</style>
