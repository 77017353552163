import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';

import router from './router';
import VModal from 'vue-js-modal';
import { store } from './store';
import MyElButton from './components/common/myElButton';

import moment from 'moment-business-days';


import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';
import myDate from './components/common/myDate';
import myOrderNumber from './components/common/myOrderNumber';

import ComponentWindow from './components/common/dialogs/componentWindow';
import myElFindInput from './components/common/myElFindInput';
import myElAutocomplete from '@/components/common/myElAutocomplete';
import myElSelect from '@/components/common/myElSelect';
import mySelector from '@/components/common/MySelector';


import wsHub from './plugins/wsHub';
import myWindows from './plugins/myWindows';
import myEnums from './plugins/myEnums';
import myCommonTrash from './plugins/myCommonTrash';
import MyLink from '@/components/common/myLink';
import myPriceInput from '@/utils/myPriceInput';


import './assets/main.module.css';
import './assets/css.css';
import primerHelper from '@/plugins/primerHelper';
import mailUtils from '@/plugins/mailUtils';
import myHttp from '@/plugins/myHttp';
import currentUser from "@/plugins/currentUser";
Vue.config.silent = true;
locale.use(lang);


Vue.use(ElementUI, { size: 'mini' });
Vue.use(VModal, { dynamic: true });



Vue.use(myHttp);


Vue.use(myEnums);
Vue.use(mailUtils);
Vue.use(myCommonTrash);
Vue.use(currentUser);
Vue.use(wsHub);
Vue.use(myWindows);
Vue.use(primerHelper);
Vue.use(mailUtils);

Vue.component('my-link', MyLink);
Vue.component('my-el-button', MyElButton);
Vue.component('my-date', myDate);
Vue.component('component-window', ComponentWindow);
Vue.component('my-order-number', myOrderNumber);
Vue.component('my-el-find-input', myElFindInput);
Vue.component('my-el-autocomplete', myElAutocomplete);
Vue.component('my-el-select', myElSelect);
Vue.component('my-selector', mySelector);
Vue.component('my-price-input', myPriceInput);

//Эта хрень нужна шоб намано работал axios - а то он сцука даты коверкает
Date.prototype.toISOString = function () {
    return moment(this).format("YYYY-MM-DD HH:mm:ss");
};

window.mainVue = new Vue({
                             router,
                             store,
                             data() {
                                 return {}
                             },
                             render: h => h(App),
                             async created() {
                                 new Vue({
                                    watch: {
                                        async '$currentUser.isAuthorized'(newValue) {
                                            if (newValue) {
                                                if (!store.state.shared.appIsInit) {
                                                    await this.$loadCommonData();
                                                }
                                                await this.$primerHelper.reloadModifiers();
                                            }
                                        }
                                    }
                                 });
                             },
                         }).$mount('#app');
