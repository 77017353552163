<template>
  <component-window
      :title="id ? 'Счет для сток заказа' : 'Создание счета для сток заказа'"
      lock-name="invoice"
      :watch-object="editInvoice"
      :isWatch="lockerIsWatch && !isPaid"
      :show-refresh-button="true"
      @click-refresh-button="loadInvoice(editInvoice.id)"
      @close="onBtnCancelClick"
  >
    <div style="display: flex;height: 100%;width:99%">
      <el-form :style="editInvoice.id ? 'width:70%' : 'width:100%'" :rules="rules" label-width="100px" :model="editInvoice" ref="editForm" label-position="right">
        <div style="display:flex;flex-direction: column;height: 100%;">
          <el-form-item label="Сумма" tab="fields" prop="sum" style="margin: 10px 0 10px 0">
            <div style="display: flex;justify-content: space-between">
              <div style="display: flex ">
                <div style="display:flex;flex-wrap:nowrap;width:130px;">
                  <template><b>{{ $roundFmt(invoiceSum) }}</b></template>
                  <div style="margin-left: 3px">
                    {{ $getEnumValue('CurrencyEnum', editInvoice.currency) }}
                  </div>
                </div>
              </div>
              <el-form-item label="Создан" v-if="editInvoice.id" style="margin-bottom: 0">
                <div style="font-weight: bold">
                  <my-date :date="editInvoice.createdAt"></my-date>
                </div>
              </el-form-item>
              <el-form-item v-else-if="editInvoice.stockPayDate" label="Оплачен" style="margin-bottom: 0">
                <div style="width: 120px;font-weight: bold">
                  <my-date :date="editInvoice.stockPayDate"></my-date>
                </div>
              </el-form-item>


            </div>
          </el-form-item>
          <el-form-item label="Номер счета" tab="fields" prop="number">
            <el-input v-model="editInvoice.number"></el-input>
          </el-form-item>
          <el-form-item label="Поставщик" prop="from">
            <my-selector
                type="affiliation"
                v-model="editInvoice.from"
                placeholder=""
                :selector="onAutocompleteFromSearch"
                @change="onChangeAcField('fromId', $event )"
            />
          </el-form-item>
          <el-form-item label="Клиент" prop="client">
            <my-selector
                :disabled="showFrom === 'client'"
                type="client"
                :buttons="true"
                v-model="editInvoice.client"
                :placeholder="false"
                @edit="onEditClient(editInvoice.client.id)"
                @add="onEditClient(0)"
                @change="onChangeAcField('clientId', $event )"
            />
          </el-form-item>
          <el-form-item label="Организация" prop="to">
            <div style="display: flex">
              <my-selector
                  type="affiliation"
                  v-model="editInvoice.to"
                  :buttons="true"
                  :placeholder="false"
                  @edit="onEditTo(editInvoice.to.id)"
                  @add="onEditTo(0)"
                  @change="onChangeAcField('toId', $event )"
              />
            </div>
          </el-form-item>
          <el-form-item label="Комментарий" tab="fields" prop="comment">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editInvoice.comment"/>
          </el-form-item>
          <div class="my-form-label" style="display:flex; justify-content:space-between;align-items: center;margin-bottom: 10px">Элементы
            <my-el-button
                v-if="editInvoice.id && !editInvoice.stockPayDate"
                type="success" @click="onClickPay"
            >Оплатить
            </my-el-button>
          </div>
          <Split style="height: 100%;" direction="horizontal">
            <SplitArea :size="editInvoice.id ? 100 : 70" :min-size="400" style="display:flex;flex-direction: column; padding:5px 5px 0 0; height:100%">
              <el-table
                  :data="filteredLeftTableData"
                  style="width:100%;flex: 1 1; font-size: 16px"
                  :border="true"
                  ref="dataTable"
                  height="100%"
                  width="100%"
                  :default-sort="{prop: 'sort', order: 'descending'}"
                  v-ls-saver:invoiceTbl
              >
                <el-table-column width="170" prop="sort" label="Заказ">
                  <template slot-scope="scope">
                    <div style="display: flex;align-items: center">
                      <div style="width: 15px">
                        <i v-if="!copyIsExist(scope.row) && scope.row.countAll > 1"
                           class="el-icon-plus"
                           @click="doCopyRow(scope.row)"
                        ></i>
                        <i v-if="scope.row.isCopy"
                           class="el-icon-close"
                           @click="doRemoveCopy(scope.row)"
                        ></i>
                      </div>
                      <my-order-number
                          :order="scope.row.order"
                          @begin-edit="$emit('begin-edit', $event)"
                          @end-edit="onOrderEditorClose"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="130" label="#CAT">
                  <template slot-scope="scope">
                    {{ scope.row.cat }}
                  </template>
                  <template v-slot:header>
                    <div style="display: flex;flex-wrap: nowrap;align-items: center">
                      <div style="white-space: nowrap">#CAT</div>
                      <el-input v-model="fastFindCat" style="margin-left: 5px" placeholder="Поиск"></el-input>
                    </div>
                  </template>

                </el-table-column>
                <el-table-column label="Название">
                  <template slot-scope="scope">
                    {{ scope.row.name }}
                  </template>
                </el-table-column>
                <el-table-column v-if="!editInvoice.id" width="70" label="Всего">
                  <template slot-scope="scope">
                    {{ scope.row.countAll }}
                  </template>
                </el-table-column>
                <el-table-column v-if="!editInvoice.id" width="70" label="Не продано">
                  <template slot-scope="scope">
                    {{ scope.row.countAll - scope.row.countStock - scope.row.count }}
                  </template>
                  <template v-slot:header>
                    <div style="word-break: normal">
                      Не продано
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="100" label="Кол-во">
                  <template slot-scope="scope">
                    <div v-if="editInvoice.id" v-html="scope.row.count"></div>

                    <el-form-item
                        v-else
                        label="" label-width="0"
                        style="margin-bottom: 0"
                        :prop="`elements.${scope.$index}.count`"
                        :rules="rules.sum"
                    >
                      <my-price-input
                          style="width:80px"
                          :precision="0"
                          v-model="scope.row.count"
                          @change="inputCount(scope.row, $event)"
                      >
                      </my-price-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="110" label="Цена">
                  <template slot-scope="scope">
                    <div v-if="editInvoice.id" v-html="$roundFmt(scope.row.price)"></div>
                    <my-price-input
                        v-else
                        style="width:90px"
                        v-model="scope.row.price"
                        @change="inputPrice(scope.row, $event)"
                    >
                    </my-price-input>
                  </template>
                </el-table-column>
                <el-table-column width="110">
                  <template slot-scope="scope">
                    <div v-if="editInvoice.id" v-html="$roundFmt(scope.row.discountPrice)"></div>
                    <my-price-input
                        v-else
                        style="width:90px"
                        v-model="scope.row.discountPrice">
                    </my-price-input>
                  </template>
                  <template v-slot:header>
                  <div style="word-break: normal">
                    Цена со скидкой
                  </div>
                  </template>
                </el-table-column>
                <el-table-column width="90" label="Сумма">
                  <template slot-scope="scope">
                    {{ $roundFmt(scope.row.discountPrice * scope.row.count) }}
                  </template>
                </el-table-column>
              </el-table>
            </SplitArea>
            <SplitArea :size="editInvoice.id ? 0 : 30" :min-size="editInvoice.id ? 0 : 200"
                       style="flex-direction: column; padding:5px 0 0 5px; height:100%"
                       :style="{display: editInvoice.id ? 'none':'flex'}"
            >
              <div v-if="editInvoice.id" style="width: 0px"></div>

              <el-table
                  v-else
                  :data="selectedTableData"
                  style="width:100%;flex: 1 1; font-size: 16px"
                  :border="true"
                  ref="dataTable"
                  height="100%"
                  width="100%"
                  :span-method="spanRightTable"
                  :default-sort="{prop: 'sort', order: 'descending'}"
                  v-ls-saver:invoiceTbl
              >
                <el-table-column width="30">
                  <i slot-scope="scope" class="el-icon-close" @click="scope.row.count = 0"></i>
                </el-table-column>
                <el-table-column label="#CAT">
                  <template slot-scope="scope">
                    {{ scope.row.cat }}
                  </template>
                  <template v-slot:header>
                  <div style="display: flex;flex-wrap: nowrap;align-items: center">
                    <div style="white-space: nowrap">#CAT</div>
                    <el-input v-model="fastFindCatRight" style="margin-left: 5px" placeholder="Поиск"></el-input>
                  </div>
                  </template>

                </el-table-column>
                <el-table-column width="100" label="Кол-во">
                  <template slot-scope="scope">
                    <div v-if="editInvoice.id" v-html="scope.row.count"></div>

                    <el-form-item
                        v-else
                        label="" label-width="0"
                        style="margin-bottom: 0"
                        :prop="`elements.${scope.$index}.count`"
                        :rules="rules.sum"
                    >
                      <my-price-input
                          style="width:80px"
                          :precision="0"
                          v-model="scope.row.count"
                          @change="inputCount(scope.row, $event)"
                      >
                      </my-price-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column width="110" label="Цена">
                  <template slot-scope="scope">
                    <div v-if="editInvoice.id" v-html="$roundFmt(scope.row.price)"></div>
                    <my-price-input
                        v-else
                        style="width:90px"
                        v-model="scope.row.price"
                        @change="inputPrice(scope.row, $event)"
                    >
                    </my-price-input>
                  </template>
                </el-table-column>
                <el-table-column width="110">
                  <template slot-scope="scope">
                    <div v-if="editInvoice.id" v-html="$roundFmt(scope.row.discountPrice)"></div>
                    <my-price-input
                        v-else
                        style="width:90px"
                        v-model="scope.row.discountPrice">
                    </my-price-input>
                  </template>
                  <template v-slot:header>
                  <div style="word-break: break-word">
                    Цена со скидкой
                  </div>
                  </template>
                </el-table-column>
                <el-table-column width="90" label="Сумма" align="right">
                  <template slot-scope="scope">
                    {{ $roundFmt(scope.row.sum) }}
                  </template>
                </el-table-column>
              </el-table>
            </SplitArea>
          </Split>
        </div>
      </el-form>
      <div v-if="editInvoice.id" style="width:30%;margin-left: 10px;display: flex;flex-direction: column;justify-content: stretch;height: 100%">
        <div style="display:flex;flex-direction:column;height: 50%">
          <el-table :data="editInvoice.childs"
                    style="font-size: 16px"
                    height="100%"
                    :border="true"
          >
            <el-table-column prop="metaorder.number" label="Подчиненный счет">
              <template slot-scope="scope">
                <my-link :comment="scope.row.comment"
                         :name="scope.row.number"
                         @click="showInvoiceWindow(scope.row.id)"
                ></my-link>
              </template>
            </el-table-column>
            <el-table-column width="90" label="Сумма">
              <template slot-scope="scope">
                {{ $roundFmt(scope.row.sum) }}
              </template>
            </el-table-column>
          </el-table>
          <div style="height:20px"></div>
        </div>
        <div style="display:flex;flex-direction:column;height: 50%;justify-content: flex-end;">
          <div style="display: flex;flex-direction: column; align-items: flex-end;margin:5px 0 5px 0">
            <my-el-button type="success" @click="onCreatePdf">Создать документ</my-el-button>
          </div>
          <div style="display:flex;flex-direction:column;height: 100%">
            <file-table :files="editInvoice.files" :file-type="['invoice', 'act']" with-types with-date></file-table>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer" slot-scope="{lockerState}">
      <div style="display: flex;justify-content: space-between;width:100%">
        <div style="display: flex">
          <my-el-button delete
                        v-if="!!id"
                        :disabled="!!errorsForDeleteInvoiceButton(lockerState)"
                        :dis-popover="errorsForDeleteInvoiceButton(lockerState)"
                        @click="onBtnDeleteClick"
                        style="margin-right: 15px"></my-el-button>
        </div>
        <div style="display:flex">
          <my-el-button @click="onBtnCancelClick" type="warning" align="right">Отменить</my-el-button>
          <my-el-button
              v-if="!editInvoice.id"
              :disabled="!!checkCanCreate"
              :dis-popover="checkCanCreate"
              @click="doCreateInvoice()"
              type="success">Создать
          </my-el-button>
        </div>
      </div>
    </template>

  </component-window>
</template>

<script>
import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
import _ from 'lodash';
import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import affiliationEditor from '@/components/usersClientsAffiliation/AffiliationEditor';
import tableSaver from '@/utils/tableSaver';
import * as invoiceHelper from '@/utils/invoiceHelper';
import FileTable from '@/components/common/fileTable';
import InvoicePrint from '@/components/invoices/InvoicePrint';
import invoiceEditor from '@/components/invoices/InvoiceEditor';
import confirmInvoicePay from '@/components/invoices/ConfirmInvoicePay';


export default {
  name: 'StockInvoiceEditor',
  components: { FileTable },
  props: ['id', 'showFrom', 'client', 'to', 'metaorder', 'onClose', 'showConfirmPayWindow', 'sum', 'isSber', 'number'],
  mixins: [tableSaver],
  data() {
    return {
      findOrder: null,
      lockerIsWatch: false,
      windowSize: { minHeight: 400, minWidth: 400, height: '90%', width: '50%' },
      activeTab: 'fields',
      editInvoice: {
        elements: [],
        id: 0,
        isPrepayment: false,
        createdAt: new Date(),
        number: '',
        metaorderRls: [],
        from: null,
        client: null,
        to: null,
        sum: 0,
        stockPayDate: null,
        payments: [],
        files: [],
        isSber: false,
      },
      fromAffiliations: [],
      fastFindCat: '',
      fastFindCatRight: '',

      rules: {
        from: [this.$validateRuleRequired],
        number: [this.$validateRuleRequired],
        client: [this.$validateRuleRequired],
        to: [this.$validateRuleRequired],
        sum: [this.$validateRuleNumber?.(true, false, false, false, true)],
        shortName: [this.$validateRuleRequired],
        country: [this.$validateRuleRequired],
        currency: [this.$validateRuleRequired],
      },
    };
  },

  async mounted() {
    this.fromAffiliations = await this.$store.dispatch('affiliations/loadItems', { query: [{ fieldName: 'ownerCompanyId', op: 'neq', values: [null] }] })
    if (this.id) {
      await this.loadInvoice(this.id);
    } else {
      this.editInvoice.client = this.client;
      this.editInvoice.clientId = this.client?.id;
      if (this.editInvoice.currency !== 'rub') {
        this.editInvoice.from = this.fromAffiliations[ 0 ];
        this.editInvoice.fromId = this.fromAffiliations[ 0 ].id;
      }
    }
    if (this.editInvoice.id) {
      //что б спрятать сплит в случае если счет есть и правая таблица не видна
      const elements = document.getElementsByClassName('gutter-horizontal');
      if (elements.length > 0) {
        elements[ 0 ].parentNode.removeChild(elements[ 0 ]);
      }
    }
  },

  watch: {
    'editInvoice.client': async function(newValue) {
      //Если это новый инвойс то грузим элементы из которых будем выбирать
      if (!this.id) {
        if (newValue) {
          let [elements, deliveries] = await Promise.all([
                                                           this.loadElementsForClient(newValue.id),
                                                           this.findDeliveriesForStockOrders(newValue.id)
                                                         ]);
          this.editInvoice.elements = [...elements, ...deliveries];
        } else {
          this.editInvoice.elements = [];
        }
      }
    }
  },

  methods: {

    inputCount(row) {
      this.$nextTick(() => {
        if (!row.isCopy) {
          row.count = (row.countAll - row.countStock - row.count < 0)
              ? row.countAll - row.countStock
              : row.count;
          return;
        }
        let originRow = this.editInvoice.elements.find(r => r.orderProductId === row.orderProductId && !r.isCopy);
        if (row.count > (originRow.saveCountAll - originRow.countStock - originRow.count)) {
          row.count = originRow.saveCountAll - originRow.countStock - originRow.count;
        }
        row.countAll = row.count;
        originRow.countAll = originRow.saveCountAll - row.count;
      });
    },

    inputPrice(row) {
      let percent = row.sourceKey.startsWith('delivery:') ? 0 : row.order.discountPercent;
      let dscPrice = row.price - row.price / 100 * percent;
      row.discountPrice = Math.round(dscPrice * 100) / 100;
    },

    doRemoveCopy(row) {
      let idx = this.editInvoice.elements.indexOf(row);
      let originalRow = this.editInvoice.elements.find(r => r.orderProductId === row.orderProductId && !r.isCopy);
      originalRow.countAll = row.saveCountAll;
      this.editInvoice.elements.splice(idx, 1);
    },

    copyIsExist(row) {
      return this.editInvoice.elements.some(r => r.orderProductId === row.orderProductId && r.isCopy);
    },

    doCopyRow(srcRow) {
      let newRow = _.cloneDeep(srcRow);
      newRow.isCopy = true;

      newRow.count = 0;
      newRow.countAll = 0;
      newRow.countStock = 0;
      this.editInvoice.elements.push(newRow);
    },

    async loadElementsForClient(clientId) {
      let query = [
        //{ isOr: false, fieldName: 'order.metaorder.id', op: 'gt', values: [16995] },
        { isOr: false, fieldName: 'order.type', op: 'in', values: ['Pdt'] },
        { isOr: false, fieldName: 'order.metaorder.isStock', op: 'eq', values: [true] },
        { isOr: false, fieldName: 'order.metaorder.clientId', op: 'eq', values: [clientId] },
        { isOr: false, fieldName: '(countAll - countStock) > 0', op: 'query', values: [] },
        { isOr: false, fieldName: 'lots.count() > 0 || product.IsProducedByContractor', op: 'query', values: [] },
        { isOr: false, fieldName: 'order.state', op: 'in', values: ['send', 'done'] }
      ];
      let elements = await this.$store.dispatch('orders/getPdtOrderElements', { query });
      return invoiceHelper.buildProductData(elements, null, true);
    },


    async loadOrdersForSelectItems(clientId) {
      let query = [
        { isOr: false, fieldName: 'metaorder.id', op: 'gt', values: [16995] },
        { isOr: false, fieldName: 'Type', op: 'in', values: ['Pdt'] },
        { isOr: false, fieldName: 'Metaorder.isStock', op: 'eq', values: [true] },
        { isOr: false, fieldName: 'Metaorder.clientId', op: 'eq', values: [clientId] },
        { isOr: false, fieldName: 'elements.any((countAll - countStock) > 0)', op: 'query', values: [] },
        { isOr: false, fieldName: 'elements.any( lots.count() > 0 || product.IsProducedByContractor )', op: 'query', values: [] },
        { isOr: false, fieldName: 'state', op: 'in', values: ['send', 'done'] },
      ];
      let orders = await this.$store.dispatch('orders/loadItems', { query });

      return orders.reduce((acc, ord) => [...acc, ...invoiceHelper.buildProductData(ord, null, true)], []);
    },

    async findDeliveriesForStockOrders(clientId) {
      let rls = await this.$store.dispatch('deliveries/findDeliveriesForStockOrders', clientId);
      return rls.map(rl => ({
        ...invoiceHelper.createDeliveryRow(rl.price, rl.id),
        countAll: 1,
        countStock: 0,
        order: rl.order,
        orderId: rl.order.id
      }));
    },

    async doCreateInvoice() {
      try {
        await this.$refs.editForm.validate()
      } catch {
        return;
      }
      this.editInvoice.elements.forEach(el => el.sum = el.discountPrice * el.count);
      this.editInvoice.sum = this.editInvoice.elements.reduce((acc, el) => acc + el.sum, 0);
      try {
        this.editInvoice = (await this.$store.dispatch('invoices/createStockInvoice', this.editInvoice));
      } catch (ex) {
        await alert(ex.message);
        return false;
      }
      this.onClose && this.onClose({ invoice: this.editInvoice, isPay: true });
      this.$emit('close', this.editInvoice);
    },

    async onClickPay() {
      let dateAndSum = await this.$showWindowAsync(confirmInvoicePay, { id: 0, invoice: this.editInvoice, lockSum: true });
      if (!dateAndSum) {
        return;
      }

      try {
        let invoice = await this.$store.dispatch('invoices/doPayStock',
                                                 { invoice: this.editInvoice, ...dateAndSum });
        this.onClose && this.onClose({ invoice: this.editInvoice, isPay: true });
        this.$emit('close', invoice);
      } catch (ex) {
        alert("Во время записи оплаты счета возникла ошибка: " + ex.message);
      }
    },

    async showInvoiceWindow(id) {
      await this.$showWindowAsync(invoiceEditor, { id });
    },

    async onCreatePdf() {
      let files = await this.$showWindowAsync(InvoicePrint, { invoice: this.editInvoice });
      if (files) {
        this.lockerIsWatch = false;
        this.editInvoice.files.push(...files);
        this.lockerIsWatch = true;
      }
    },

    async loadInvoice(id) {
      this.lockerIsWatch = false;
      this.editInvoice = await this.$store.dispatch('invoices/loadItem', id);
      this.lockerIsWatch = true;
    },

    onAutocompleteFromSearch(query, cb) {
      cb(this.fromAffiliations)
    },

    onChangeAcField(fieldName, obj) {
      this.editInvoice[ fieldName ] = obj?.id;
      if (fieldName === 'clientId') {
        this.editInvoice.currency = obj?.currency;
      }
    },

    async onEditClient(id) {
      let client = await this.$showWindowAsync(clientEditor, { id });
      if (client) {
        this.editInvoice.client = client;
        this.editInvoice.currency = client.currency;
      }
    },

    async onEditTo(id) {
      let to = await this.$showWindowAsync(affiliationEditor, { id });
      if (to) {
        this.editInvoice.to = to;
      }
    },

    async onBtnDeleteClick() {
      if (!await confirm('Удалить счет?', 'Удалить')) {
        return;
      }
      try {
        await this.$store.dispatch('invoices/deleteItem', this.editInvoice)
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.onClose && this.onClose({ isDelete: true });
      this.$emit('close');
    },

    onBtnCancelClick() {
      this.onClose && this.onClose(null);
      this.$emit('close', null);
    },

    errorsForDeleteInvoiceButton(lockerState) {
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      if (this.editInvoice.stockPayDate) {
        return 'Платежи по счету есть. Нельзя удалять с платежами счет.'
      }
      return '';
    },

    errorsForSaveInvoiceButton(lockerState) {
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      if (this.isPaid) {
        return 'Счет уже оплачен. Нельзя оплаченный изменять счет.'
      }
      return '';
    },

    spanRightTable({ row, columnIndex }) {
      if (columnIndex !== 5) {
        return;
      }
      return {
        rowspan: row.rowSpan,
        colspan: 1
      };
    }

  },

  computed: {
    filteredLeftTableData() {
      let fnd = this.fastFindCat.trim().toLowerCase();
      return fnd
          ? this.tableData.filter(row => row.cat.toLowerCase().startsWith(fnd))
          : this.tableData;
    },

    tableData() {
      return (!this.editInvoice.id
              ? this.editInvoice.elements.map(el => Object.assign(el, { sort: `${el.order.number}-${el.orderProductId}-${!el.isCopy}` }))
              : this.editInvoice.childs.reduce((acc, ch) => [...acc, ...ch.elements], [])
      )
    },
    selectedTableData() {
      let result = this.tableData
      .filter(row => row.count > 0);
      let fnd = this.fastFindCatRight.trim().toLowerCase();
      if (fnd) {
        result = result.filter(r => r.cat.toLowerCase().includes(fnd))
      }

      result = _.orderBy(result, ['cat']);
      let saveCat = '';
      result.forEach(row => {
        if (row.cat !== saveCat) {
          let filtered = result.filter(r => r.cat === row.cat);
          row.sum = filtered.reduce((acc, r) => acc + r.discountPrice * r.count, 0);
          row.rowSpan = filtered.length;
          saveCat = row.cat
        } else {
          row.sum = 0;
          row.rowSpan = 0;
        }
      });
      return result;
    },


    invoiceSum() {
      return this.tableData.reduce((acc, el) => acc + el.count * el.discountPrice, 0)
    },

    checkCanCreate() {
      let selected = this.editInvoice.elements.filter(el => el.count > 0);
      if (selected.length === 0) {
        return 'Не выбраны продукты для создания счета.';
      }
      if (_.uniq(selected.reduce((acc, el) => [...acc, el.order.metaorder?.currency], [])) > 1) {
        return 'В одном счете не могут сосуществовывать заказы с разной валютой.';
      }
      return '';
    },

//---------------------------------------------------------------------------            
    checkCanPay() {
      if (this.isPaid) {
        return 'Счет уже оплачен.';
      }
      if (this.editInvoice.isSber) {
        return 'Счет будет оплачен онлайн.';
      }
      if (!this.editInvoice.isPrepayment && !this.editInvoice.elements.some(el => !el.forDelete)) {
        return 'В счете нет элементов.';
      }
      if (this.editInvoice.isPrepayment && !this.editInvoice.sum) {
        return 'Не задана сумма.';
      }
      return "";
    },

    calcSumByElements() {
      return this.editInvoice.elements
      .filter(el => !el.forDelete)
      .reduce((acc, el) => acc + el.count * el.discountPrice, 0) || '?';
    },

    notDeletedInvoiceElements() {
      return this.editInvoice.elements.filter(el => !el.forDelete);
    },

    metaorderRls() {
      return this.editInvoice.metaorderRls.filter(rl => !rl.forDelete)
    },

    isPaid() {
      return (this.editInvoice.payments.length > 0 && this.leftToPaid <= 0);
    },

    leftToPaid() {
      return (this.isPrepayment ? this.editInvoice.sum : this.calcSumByElements)
          - this.editInvoice.payments.reduce((acc, p) => acc + p.sum, 0);
    }
  }
}
</script>
