<template functional>
    <div v-if="!!props.metaorder" :style="props.style" style="display: flex">
        <div style="min-width:15px;white-space: nowrap">
            <el-popover v-if="$options.showPopover(props)" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div v-if="props.metaorder.managerComment" style="display: flex;flex-direction: column">
                        <div style="padding-left: 10px;font-size:16px; font-weight: bold; color: #409EFF ">Комментарий менеджера</div>
                        <pre style="padding-left: 10px;margin:0;border:0;outline: 0; white-space: pre-wrap" v-html="props.metaorder.managerComment"></pre>
                    </div>
                    <div v-if="props.metaorder.userComment" style="display: flex;flex-direction: column">
                        <div style="padding-left: 10px;font-size:16px; font-weight: bold; color: #409EFF ">Комментарий заказчика</div>
                        <pre style="padding-left: 10px;margin:0;border:0;outline: 0; white-space: pre-wrap" v-html="props.metaorder.userComment"></pre>
                    </div>
                    <template v-if="props.metaorder.orders">
                    <div v-for="order in props.metaorder.orders.filter(o => o.comment)" style="display: flex;flex-direction: column" :key="order.id">
                        <div style="padding-left: 10px;font-size:16px; font-weight: bold; color: #409EFF ">Комментарий к подзаказу {{order.number}}</div>
                        <pre style="padding-left: 10px; margin:0;border:0;outline: 0; white-space: pre-wrap" v-html="order.comment"></pre>
                    </div>
                    </template>
                </div>
                <i  class="el-icon-info" slot="reference" style="cursor: pointer;font-size: 12px;color:#409EFF;margin-right: 3px"></i>
            </el-popover>
        </div>
        <div :class="props.disabled ? 'dis-my-link':'my-link'"
             @click="!props.disabled && $options.showOrder(props, listeners, parent)"
             @mousedown.stop.prevent
             @click.middle.prevent.stop="$options.showOrder(props, listeners, parent, true)" >{{props.metaorder.number}}</div>
    </div>
</template>

<script>
    import orderEditor from '../order/OrderEditor';
    export default {
        name: "myMetaorderNumber",
      // eslint-disable-next-line vue/no-reserved-props
        props: ['metaorder', 'disabled', 'hideIcon', 'style', 'setUser', 'setClient'],

        async showOrder(props, listeners, parent, isNewWindow) {
            if (isNewWindow) {
                window.open(`/order/${props.metaorder.id}`, '_blank');
            } else {
                listeners[ 'begin-edit' ] && listeners[ 'begin-edit' ](props.metaorder.id);

                await parent.$showWindowAsync(orderEditor,
                                              {
                                                  id: props.metaorder.id,
                                                  user: props.setUser ? props.metaorder.user : null,
                                                  client: props.setClient ? props.metaorder.client : null
                                              });

                listeners[ 'end-edit' ] && listeners[ 'end-edit' ](props.metaorder);
            }
        },
        showPopover(props) {
            return !props.hideIcon && (props.metaorder.userComment || props.metaorder.managerComment || props.metaorder.orders?.some(o => o.comment))
        }
    }
</script>

<style>
</style>
