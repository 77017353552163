import { store } from "@/store";
import router from '../router';

export default {
    async install(Vue) {
        Vue.use(router);
        let currentUser = {
            isAuthorized: false,
            goToSignPage() {
                if (document.location.pathname.indexOf('signin') < 0) {
                    Vue.prototype.$nextTick(() => router.push('/signin?return=' + document.location.pathname));
                }
            },
            async init() {
                if (!this.getJwtToken()) {
                    this.goToSignPage();
                    return;
                }
                try {
                    await this.loadUser()
                } catch (ex) {
                    this.goToSignPage();
                }
                this.isAuthorized = true;
                Vue.prototype.$wsHub.syncJwtToken();
            },
            
            async login(login, password) {
                try {
                  await Vue.prototype.$myHttp.post('/api/auth/login', { login, password });
                } catch (ex) {
                    return ex.message;
                }
                this.isAuthorized = true;
                Vue.prototype.$wsHub.syncJwtToken();
                await this.loadUser();
            },
            async loadUser() {
                await store.dispatch('loadCurrentUser');
            },
            async logout() {
                try {
                    await Vue.prototype.$myHttp.post('/api/auth/logout', {});
                    await store.commit('setCurrentUser', null);
                } catch (ex) {
                    console.log(ex);
                }
                this.goToSignPage();
            },
            
            getJwtToken() {
                const name = '.Crm.TempBasketData';
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) {
                    return parts.pop().split(';').shift();
                }
                return false;
            },
            
            get() {
                return store.state.shared.currentUser;
            }
        };
        Vue.prototype.$currentUser = Vue.observable(currentUser);
        await Vue.prototype.$currentUser.init();
    }
}
