import Vuex from 'vuex';
import shared from './shared';
import buildStore from './buildStore';
import buildAffiliationMerge from './buildAffiliationMerge';
import buildOrders from './buildOrders';
import buildMetaorders from './buildMetaorders';
import buildDeliveryStore from './buildDeliveryStore';
import buildUserStore from './buildUserStore';
import buildSequenceQueueStore from './buildSequenceQueueStore';
import buildSequencePlateStore from './buildSequencePlateStore';
import buildFileStore from './buildFileStore';
import buildProductLotStore from './buildProductLotStore';
import buildManagerStore from './buildManagerStore';
import buildPriceStore from './buildPriceStore';
import buildMailStore from './buildMailStore';
import buildProductStore from './buildProductStore';
import buildInvoiceStore from './buildInvoiceStore';
import buildClientStore from './buildClientStore';
import buildSequenceCheckStore from './buildSequenceCheckStore';
import buildPayLogStore from './buildPayLogStore';
import buildStateReportsStore from './buildStateReportsStore';
import buildMergeUsers from './buildMergeUsers';
import buildOneCRecordStore from './buildOneCRecordStore';
import buildSequenceNoteListStore from './buildSequenceNoteListStore';
import buildMoveContainerStore from '@/store/buildMoveContainerStore';


export const store = new Vuex.Store({
  modules: {
      shared: shared,
      managers: buildManagerStore(buildStore('manager')),
      roles: buildStore('role'),
      affiliations: buildAffiliationMerge(buildStore('Affiliation')),
      clients: buildClientStore(buildStore('Client')),
      users: buildUserStore(buildStore('User')),
      addresses: buildStore('Address'),
      metaorders: buildMetaorders(buildStore('Metaorder')),
      orders: buildOrders(buildStore('Order')),
      modifiers: buildStore('Modifier'),
      mailTemplates: buildStore('mailTemplate'),
      mails: buildMailStore(buildStore('mail')),
      products: buildProductStore(buildStore('product')),
      productGroups: buildStore('productGroup'),
      deliveryServices: buildStore('deliveryService'),
      deliveries: buildDeliveryStore(buildStore('delivery')),
      primerStores: buildStore('primerStore'),
      sequenceTypes: buildStore('sequenceType'),
      sequenceQueues: buildSequenceQueueStore(buildStore('sequenceQueue')),
      sequencePlates: buildSequencePlateStore(buildStore('sequencePlate')),
      settings: buildStore('setting'),
      files: buildFileStore(buildStore('file')),
      prices: buildPriceStore(buildStore('price')),
      productLots: buildProductLotStore(buildStore('productLot')),
      countries: buildStore('country'),
      zonds: buildStore('zond'),
      invoices:  buildInvoiceStore(buildStore('invoice')),
      sequenceChecks: buildSequenceCheckStore(buildStore('sequenceCheck')),
      payLogs: buildPayLogStore(buildStore('payLog')),
      stateReports: buildStateReportsStore(),
      mergeUsers: buildMergeUsers(),
      metaorderTags: buildStore('metaorderTag'),
      siteSearchHistories: buildStore('siteSearchHistory'),
      bookmarks: buildStore('bookmark'),
      mailShortTemplates: buildStore('mailShortTemplate'),
      oneCRecords:   buildOneCRecordStore(buildStore('oneCRecord')),
      payments: buildStore('payment'),
      sequenceNotes: buildSequenceNoteListStore(buildStore('sequenceNoteList')),
      ngsCatalog: buildStore('ngsCatalog'),
      ownerCompanies: buildStore('ownerCompany'),
      moveContainers: buildMoveContainerStore(buildStore('moveContainer'))
  }
});
